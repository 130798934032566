import React, {useContext, useState} from 'react';
import * as S from './styles';
import {Button, ModalTitle, SizedBox, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {ApplicationContext} from 'src/context';
import {Modal} from 'src/shared/components/Complex';
import {AddField} from '../AddField';
import {MenuRoute} from 'src/shared/components/System/MenuRoute';
import {useNavigate} from 'react-router-dom';
import {ROUTER_PATHS} from 'src/router/router.path';
import FeatherIcon from 'feather-icons-react';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

export const AddTopFunctionBar = () => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [isOpenDelete, showIsOpenDelete] = useState(false);
	const [isOpenAddField, showIsOpenAddField] = useState(false);

	const navigate = useNavigate();

	const handleDelete = () => {
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;

		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].filter(
					table => table.ref != tableRef,
				),
			},
		});
	};

	return (
		<div>
			<Wrapper margin="10px 0 10px 10px">
				<MenuRoute
					useMargin={false}
					pathRoute={[
						{
							name: 'Início',
							action: () => navigate(ROUTER_PATHS.HOME),
						},
						{
							name: 'Projetos',
							action: () => navigate(ROUTER_PATHS.PROJECTS),
						},
						{
							name: application.name ?? '',
							action: () => navigate(ROUTER_PATHS.PROJECT),
						},
						{
							name: 'Modelo de dados',
						},
					]}
				/>
			</Wrapper>
			<S.Content>
				<SizedBox width="10px" />
				<Wrapper justifyContent="space-between">
					<Button
						text="Adicionar campo"
						leftIcon2="plus-square"
						onClick={() => showIsOpenAddField(true)}
						fill="auto"
						background={applicationTheme.brand[600]}
						height="36px"
					/>
					<Button
						text="Deletar tabela"
						leftIcon2="trash-2"
						onClick={() => showIsOpenDelete(true)}
						fill="auto"
						background={applicationTheme.error[600]}
						height="36px"
					/>
				</Wrapper>
				<SizedBox width="10px" />

				<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
					<>
						<ModalTitle
							title="Deletar tabela?"
							icon={
								<IconWrapper>
									<FeatherIcon icon="alert-circle" size={20} />
								</IconWrapper>
							}
							centerTitle={true}
						/>
						<div
							style={{
								color: applicationTheme.gray[500],
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontSize: '14px',
								paddingBottom: '24px',
							}}>
							Tem certeza de que deseja excluir esta tabela?
							{/* <b>{application.database?.tables?.find(x => x.selected)?.name}</b> */}
							<br />
							Essa ação não pode ser desfeita.
						</div>

						<Wrapper margin="20px 0 0 0" justifyContent="end">
							<Button
								text="Cancelar"
								onClick={() => showIsOpenDelete(false)}
								fill="full"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
							<Button
								text="Deletar"
								onClick={handleDelete}
								fill="full"
								background={applicationTheme.error[600]}
							/>
						</Wrapper>
					</>
				</Modal>

				<AddField isOpen={isOpenAddField} setIsOpen={showIsOpenAddField} />
			</S.Content>
		</div>
	);
};

export default (url: string): string => {
	if (!url) return '';

	// Remove espaços em branco antes e depois da string
	url = url.trim();

	// Remove caracteres inválidos (permite letras, números, '.', '/', '-', '_', ':', e '?')
	url = url.replace(/[^\w\-.~:/?#[\]@!$&'()*+,;=%]/g, '');

	// Adiciona o "http://" ou "https://" se não tiver
	if (!/^https?:\/\//i.test(url)) {
		url = 'http://' + url;
	}

	// Garantir que tenha um domínio (e.g., pelo menos "example.com")
	const hasDomain = url.match(/https?:\/\/[\w\-]+(\.[\w\-]+)+/);
	if (!hasDomain) return ''; // Se não houver domínio, retorna vazio

	return url;
};

import React from 'react';
import {Input, Wrapper} from '../../UI';
import {applicationTheme} from 'src/shared/theme';
import {faSearch} from '@fortawesome/free-solid-svg-icons';

type Props = {
	value: string | undefined;
	onChange: (value: string) => void;
	placeholder?: string;
	icon?: string;
	width?: string;
	margin?: string;
	maxWidth?: string;
	label?: string;
	mask?:
		| 'Nenhuma'
		| 'Numero'
		| 'CPF'
		| 'CNPJ'
		| 'CPF/CNPJ'
		| 'CEP'
		| 'Telefone'
		| 'Hora'
		| 'Monetário'
		| 'Placa'
		| 'Sem espaço'
		| 'Senha'
		| 'Guid'
		| 'Url'
		| 'E-mail';
	type?: string;
	onPressEnter?: () => void;
	disabled?: boolean;
	messageError?: string;
	inputType?: 'light' | 'minimal';
};

export const SystemInput = ({
	value,
	onChange,
	placeholder,
	icon,
	margin,
	maxWidth,
	width,
	label,
	mask,
	type,
	onPressEnter,
	disabled,
	messageError,
	inputType = 'light',
}: Props) => {
	return (
		<Wrapper width={width} margin={margin} maxWidth={maxWidth}>
			<Input
				value={value}
				onChange={onChange}
				border={`1px solid ${applicationTheme.gray[300]}`}
				inputType={inputType}
				placeholder={placeholder}
				leftIcon={icon ? faSearch : undefined}
				leftIcon2={icon}
				leftIconColor={applicationTheme.gray[500]}
				label={label}
				labelColor={applicationTheme.gray[700]}
				mask={mask}
				type={type}
				onPressEnter={onPressEnter}
				disabled={disabled}
				messageError={messageError}
			/>
		</Wrapper>
	);
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import React, {useContext, useEffect, useState} from 'react';
import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import {mergeComponent, removeComponent} from 'src/helpers/methods/app-methods';
import {GridDatActionList} from 'src/helpers/select-data/gridDataActionList';
import {getListIcons} from 'src/helpers/select-data/iconList';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {Button, ModalTitle, SizedBox, Wrapper, Text, Switch} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {components} from 'react-select';
import {Accordion} from 'src/shared/components/UI/Accordion';
import {IOption} from 'src/shared/components/UI/Select';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

export type CreateDataGridColumnType = {
	id?: string;
	header?: string;
	field?: string;
	flex?: string;
	columnType?: number;
	actionType?: IOption;
	icon?: IOption;
	name?: string;
	isKey?: boolean;
};

type CreateDataGridType = {
	name?: string;
	columns?: CreateDataGridColumnType[];
};

interface CellType {
	row: CreateDataGridColumnType;
}

const GridSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [mSelectedWidget, setMSelectedWidget] = useState<any>();
	const [data, setData] = useState<CreateDataGridType>();
	const [column, setColumn] = useState<CreateDataGridColumnType>();
	const [action, setAction] = useState<CreateDataGridColumnType>();

	useEffect(() => {
		if (!selectedWidget) {
			let selectWidget = application.resources
				?.find(resource => resource.selected)
				?.widgets?.find(w => w.selected);

			if (selectWidget?.name == ComponentComplexEnum.DataGrid) selectedWidget = selectWidget;
			else {
				selectWidget = application.resources
					?.find(resource => resource.selected)
					?.widgets?.find(
						w =>
							w.parentRef == selectWidget?.ref &&
							w.name == ComponentComplexEnum.DataGrid,
					);

				selectedWidget = selectWidget;
			}
		}

		setMSelectedWidget(selectedWidget);
		if (selectedWidget && selectedWidget.name === ComponentComplexEnum.DataGrid) {
			setData({
				name: selectedWidget.properties.name,
				columns: selectedWidget.properties.dataColumns,
			});
		}
	}, [application]);

	const handleAdd = () => {
		if (
			column &&
			column.field &&
			column.header &&
			column.flex &&
			!data?.columns
				?.filter(x => x.columnType === 1)
				?.find(x => x.field?.toUpperCase() == column.field?.toUpperCase())
		) {
			const columns = [...(data?.columns ?? [])];
			columns.push({...column, id: crypto.randomUUID(), columnType: 1});
			setColumn(undefined);
			setData({...data, columns});
		}
	};

	const handleAddAction = () => {
		if (
			action &&
			action.actionType &&
			action.icon &&
			action.name &&
			!data?.columns
				?.filter(x => x.columnType === 2)
				?.find(x => x.actionType?.value == action.actionType?.value)
		) {
			const columns = [...(data?.columns ?? [])];
			columns.push({...action, id: crypto.randomUUID(), columnType: 2});
			setAction(undefined);
			setData({...data, columns});
		}
	};

	const close = () => {
		onClose();
		setData(undefined);
		setColumn(undefined);
		setAction(undefined);
		setMSelectedWidget(undefined);
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.widgets = resource.widgets?.map(w => {
						w.selected = false;
						return w;
					});
				}
				return resource;
			}),
		});
	};

	const removeItem = (row: CreateDataGridColumnType) => {
		setData({
			...data,
			columns: [...(data?.columns?.filter(x => x.id != row.id) ?? [])],
		});
	};

	const removeAction = (row: CreateDataGridColumnType) => {
		setData({
			...data,
			columns: [...(data?.columns?.filter(x => x.id != row.id) ?? [])],
		});
	};

	const isValid = () => {
		return !!data && !!data.name && !!data.columns && data.columns.length > 0;
	};

	const save = () => {
		const columns = data?.columns?.map(item => ({
			flex: Number(item.flex),
			type: 'string',
			filterable: true,
			headerAlign: 'left',
			align: 'left',
			sortable: true,
			field: item.field,
			headerName: item.header,
			renderCell: ({row}: any) => row[item.field!],
		}));

		const props = {
			name: data?.name,
			columns: columns,
			dataColumns: data?.columns,
			rowsData: [],
		};

		const newApplication = mergeComponent(
			application,
			mSelectedWidget,
			ComponentComplexEnum.DataGrid,
			props,
		);

		setApplication(newApplication);
		close();
	};

	const removeGrid = () => {
		setApplication(removeComponent(application, selectedWidget?.ref as any));
		close();
	};

	const {Option} = components;
	const IconOption = (props: any) => (
		<Option {...props}>
			<FeatherIcon icon={props.data.value} /> {props.data.label}
		</Option>
	);

	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button
					leftIcon2="x"
					onClick={close}
					fill="auto"
					textColor={applicationTheme.gray[700]}
					type="mininum"
				/>
			</div>
			<ModalTitle title="Configurar GRID" />

			<Wrapper margin="0 0 -10px 0">
				<SystemInput
					width="100%"
					margin="12px 0"
					value={data?.name}
					onChange={value => setData({...data, name: value})}
					label="Nome da Grid"
					mask="Sem espaço"
				/>
			</Wrapper>
			<Wrapper>
				<SystemInput
					width="100%"
					margin="12px 0"
					value={column?.header}
					onChange={value => setColumn({...column, header: value})}
					label="Cabeçalho"
				/>
				<SystemInput
					width="100%"
					margin="12px 0"
					value={column?.field}
					onChange={value => setColumn({...column, field: value})}
					label="Campo"
					placeholder="Nome da propriedade que deve ser buscada"
					mask="Sem espaço"
				/>
				<SystemInput
					width="100%"
					margin="12px 0"
					value={column?.flex}
					onChange={value => setColumn({...column, flex: value})}
					label="Flex"
					placeholder="Distribua valores que no total some 1"
					mask="Numero"
				/>
			</Wrapper>
			<Wrapper justifyContent="end">
				<Button
					text={'Adicionar'}
					leftIcon2="plus-square"
					onClick={handleAdd}
					fill="auto"
					background={applicationTheme.brand[50]}
					textColor={applicationTheme.brand[600]}
				/>
			</Wrapper>

			<SizedBox height="10px" />

			<SystemDataGrid
				rowsData={data?.columns?.filter(x => x.columnType === 1) ?? []}
				columns={[
					{
						flex: 0.1,
						minWidth: 90,
						type: 'actions',
						filterable: false,
						hideable: false,
						headerAlign: 'left',
						align: 'left',
						sortable: false,
						field: 'actions',
						headerName: '',
						renderCell: ({row}: CellType) => (
							<SystemRowOptions
								childrens={
									<>
										<MenuItem
											onClick={() => removeItem(row)}
											sx={{'& svg': {mr: 2}}}>
											<FeatherIcon icon="trash-2" size={16} />
											<Text
												text="Excluir"
												color={applicationTheme.error[900]}
											/>
										</MenuItem>
									</>
								}
							/>
						),
					},
					{
						flex: 0.5,
						type: 'string',
						minWidth: 150,
						filterable: true,
						headerAlign: 'left',
						align: 'left',
						sortable: true,
						field: 'header',
						headerName: `Cabeçalho`,
						renderCell: ({row}: CellType) => row.header,
					},
					{
						flex: 0.5,
						type: 'string',
						minWidth: 150,
						filterable: true,
						headerAlign: 'left',
						align: 'left',
						sortable: true,
						field: 'field',
						headerName: `Campo`,
						renderCell: ({row}: CellType) => row.field,
					},
					{
						flex: 0.5,
						type: 'string',
						minWidth: 150,
						filterable: true,
						headerAlign: 'left',
						align: 'left',
						sortable: true,
						field: 'flex',
						headerName: `Flex`,
						renderCell: ({row}: CellType) => row.flex,
					},
					{
						flex: 0.3,
						type: 'string',
						minWidth: 150,
						filterable: true,
						headerAlign: 'left',
						align: 'left',
						sortable: true,
						field: 'isKey',
						headerName: `É chave?`,
						renderCell: ({row}: CellType) => (
							<Switch
								isOn={row.isKey}
								handleToggle={() =>
									setData({
										...data,
										columns: [...(data?.columns ?? [])].map(x => {
											if (x.id === row.id) x.isKey = !x.isKey;
											return x;
										}),
									})
								}
							/>
						),
					},
				]}
			/>

			<SizedBox height="15px" />

			<Accordion
				title="Eventos"
				body={
					<>
						<Wrapper>
							<SystemSelect
								width="100%"
								margin="12px 0"
								options={GridDatActionList}
								value={action?.actionType}
								onChange={value => setAction({...action, actionType: value})}
								label="Tipo da ação"
							/>
							<SystemSelect
								width="100%"
								margin="12px 0"
								options={getListIcons()}
								value={action?.icon}
								onChange={value => setAction({...action, icon: value})}
								label="Icone"
								components={{Option: IconOption}}
							/>
							<SystemInput
								width="100%"
								margin="12px 0"
								value={action?.name}
								onChange={value => setAction({...action, name: value})}
								label="Nome"
								placeholder="Nome que será exibido"
							/>
						</Wrapper>
						<Wrapper justifyContent="end">
							<Button
								text={'Adicionar'}
								leftIcon2="plus-square"
								onClick={handleAddAction}
								fill="auto"
								background={applicationTheme.brand[50]}
								textColor={applicationTheme.brand[600]}
							/>
						</Wrapper>

						<SizedBox height="10px" />

						<SystemDataGrid
							rowsData={data?.columns?.filter(x => x.columnType === 2) ?? []}
							columns={[
								{
									flex: 0.1,
									minWidth: 90,
									type: 'actions',
									filterable: false,
									hideable: false,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									field: 'actions',
									headerName: '',
									renderCell: ({row}: CellType) => (
										<SystemRowOptions
											childrens={
												<>
													<MenuItem
														onClick={() => removeAction(row)}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="trash-2" size={16} />
														<Text
															text="Excluir"
															color={applicationTheme.error[900]}
														/>
													</MenuItem>
												</>
											}
										/>
									),
								},
								{
									flex: 0.5,
									type: 'string',
									minWidth: 150,
									filterable: true,
									headerAlign: 'left',
									align: 'left',
									sortable: true,
									field: 'type',
									headerName: `Tipo`,
									renderCell: ({row}: CellType) => row.actionType?.value,
								},
								{
									flex: 0.5,
									type: 'string',
									minWidth: 150,
									filterable: true,
									headerAlign: 'left',
									align: 'left',
									sortable: true,
									field: 'icon',
									headerName: `Icone`,
									renderCell: ({row}: CellType) =>
										row.icon ? <FeatherIcon icon={row.icon.value} /> : <></>,
								},
								{
									flex: 0.5,
									type: 'string',
									minWidth: 150,
									filterable: true,
									headerAlign: 'left',
									align: 'left',
									sortable: true,
									field: 'name',
									headerName: `Nome`,
									renderCell: ({row}: CellType) => row.name,
								},
							]}
						/>
					</>
				}
			/>

			<SizedBox height="20px" />

			<Wrapper>
				<Wrapper margin="20px 0 0 0" justifyContent="space-between">
					<div style={{display: 'flex', gap: '10px'}}>
						<Button
							text="Salvar"
							leftIcon2="save"
							onClick={save}
							fill="auto"
							background={applicationTheme.brand[600]}
							disabled={!isValid()}
						/>
						<Button
							text="Cancelar"
							leftIcon2="x"
							onClick={close}
							fill="auto"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
					</div>
					<div style={{marginTop: '20px'}}>
						{selectedWidget ? (
							<Button
								text="Excluir"
								leftIcon2="trash-2"
								fill="auto"
								leftIcon={faTrash}
								background={applicationTheme.error[600]}
								onClick={removeGrid}
							/>
						) : (
							<></>
						)}
					</div>
				</Wrapper>
			</Wrapper>
		</div>
	);
};

export default GridSpecify;

/* eslint-disable @typescript-eslint/no-explicit-any */
import {faClose} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as S from './styles';
import {Modal} from 'src/shared/components/Complex';
import {Button, ModalTitle, Wrapper} from 'src/shared/components/UI';
import {
	IApiEndpoint,
	IDatabase,
	IDatabaseConfig,
	ISequenceDiagram,
	ISequenceDiagramApiEndpoint,
	ISequenceDiagramParticipant,
	ISequenceDiagramParticipantGroup,
	ITable,
	IWorkFlowItem,
} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import mermaid from 'mermaid';
import {applicationTheme} from 'src/shared/theme';
import {
	ActionTypeEnum,
	ApiBodyType,
	ApiMethods,
	DatabaseType,
	WorkFlowTargetEnum,
	WorkFlowTypeEnum,
} from 'src/@types/enums';
import {formatScreenName} from 'src/helpers/methods/database-to-screen';
import {CrudOptions} from 'src/helpers/workflow/component/crudComp';

type Props = {
	isBackEnd: boolean;
	isOpen: boolean;
	onClose: () => void;
};

const AddSequenceDiagram = ({isBackEnd, isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const navigate = useNavigate();

	useEffect(() => {
		buildDiagram();
	});

	const close = () => {
		onClose();
	};

	const buildDiagram = () => {
		let diagramData = '';
		const actions = application.resources
			?.find(x => x.selected)
			?.widgets?.find(x => x.selected)?.actions;

		if (
			actions?.some(
				x =>
					x.actionType !== ActionTypeEnum.Clear &&
					x.actionType !== ActionTypeEnum.NextPage &&
					x.actionType !== ActionTypeEnum.OpenModal &&
					x.actionType !== ActionTypeEnum.SetValue &&
					x.actionType !== ActionTypeEnum.DataGrid,
			) === true
		) {
			diagramData = buildDiagramBackEnd();
		} else {
			diagramData = buildDiagramFrontEnd();
		}

		document.getElementById('dbDiagram')?.removeAttribute('data-processed');
		document.getElementById('dbDiagram')!.innerHTML = diagramData;

		mermaid.contentLoaded();
	};

	const buildDiagramFrontEnd = () => {
		const hasSelectComp = application.resources
			?.find(x => x.selected)
			?.widgets?.find(x => x.selected)
			? true
			: false;
		const sequences: Map<string, ISequenceDiagram> = new Map<string, ISequenceDiagram>();

		application.resources?.forEach(resource => {
			if (resource.selected) {
				if (hasSelectComp) {
					resource.widgets?.map(widget => {
						if (widget.selected) {
							let sequenceDiagram: ISequenceDiagram = {};
							const uiName =
								application?.projectConfiguration?.uiFramework?.toUpperCase() ?? '';
							sequenceDiagram.resourceName = resource.name;
							sequenceDiagram.groups = new Map<
								string,
								ISequenceDiagramParticipantGroup
							>();
							sequenceDiagram.participants = new Map<
								string,
								ISequenceDiagramParticipant
							>();
							sequenceDiagram.endpoints = new Map<
								string,
								ISequenceDiagramApiEndpoint
							>();
							sequenceDiagram.dependences = new Map<string, IWorkFlowItem>();

							sequenceDiagram.groups.set('Access', {
								label: 'Acesso externo',
								backgroundcolor: 'rgb(242, 244, 247)',
							});

							sequenceDiagram.groups.set('Interface', {
								label: `Interface ${uiName}`,
								backgroundcolor: 'rgb(217, 243, 241)',
							});

							sequenceDiagram.participants.set('User', {
								groupRef: 'Access',
								ref: 'User',
								label: undefined,
								isActor: true,
							});

							sequenceDiagram.participants.set('AppService', {
								groupRef: 'Interface',
								ref: 'AppService',
								label: `Página: ${resource.name}`,
								isActor: false,
							});

							widget.actions?.forEach(action => {
								if (
									action?.actionType === ActionTypeEnum.Clear ||
									action?.actionType === ActionTypeEnum.SetValue ||
									action?.actionType === ActionTypeEnum.DataGrid
								) {
									const gridname = resource.widgets?.find(
										x => x.ref === action.targetRef,
									)?.properties?.name;
									const actionName =
										action?.actionType === ActionTypeEnum.DataGrid
											? `${action?.actionName}: ${gridname}`
											: action?.actionName;

									const endpoint: IApiEndpoint = {
										ref: `${action?.ref}`,
										name: `${actionName}`,
										method: ApiMethods.GET,
										route: `${resource.name}`,
										bodyType: ApiBodyType.JSON,
										isAuth: true,
										databaseTableRef: sequenceDiagram?.table?.ref,
									};

									sequenceDiagram?.endpoints?.set(`${action?.ref}`, {
										api: endpoint,
									});
								} else if (
									action?.actionType === ActionTypeEnum.NextPage ||
									action?.actionType === ActionTypeEnum.OpenModal
								) {
									const nextPage = application.resources?.find(
										x => x.ref === action.targetRef,
									);

									sequenceDiagram?.participants?.set(`${nextPage?.ref}`, {
										groupRef: 'Interface',
										ref: `${nextPage?.ref}`,
										label: `Página: ${nextPage?.name}`,
										isActor: false,
									});

									const endpoint: IApiEndpoint = {
										ref: `${nextPage?.ref}`,
										name: `${action?.actionName}`,
										method: ApiMethods.GET,
										route: `${nextPage?.name}`,
										bodyType: ApiBodyType.JSON,
										isAuth: true,
										databaseTableRef: sequenceDiagram?.table?.ref,
									};

									sequenceDiagram?.endpoints?.set(`${nextPage?.ref}`, {
										api: endpoint,
										services: new Map<string, IApiEndpoint>().set(
											`${nextPage?.ref}`,
											endpoint,
										),
									});
								}
							});

							if (widget?.actions !== undefined && widget?.actions?.length > 0) {
								sequences.set('Interação', sequenceDiagram);
							}

							widget.inputActions?.forEach(inputAction => {
								inputAction?.workflow?.forEach(component => {
									if (
										component.type === WorkFlowTypeEnum.Amqp ||
										component.type === WorkFlowTypeEnum.Email ||
										component.type === WorkFlowTypeEnum.Ftp ||
										component.type === WorkFlowTypeEnum.Http
									) {
										const componentName = WorkFlowTypeEnum[component.type];
										const method =
											component.type == WorkFlowTypeEnum.Http
												? component?.values?.method
												: ApiMethods.POST;
										const route =
											component.type == WorkFlowTypeEnum.Http
												? component?.values?.url
												: `/${getResourceName(
														sequenceDiagram?.table?.name,
												  )}`;
										const endpoint: IApiEndpoint = {
											ref: `${componentName}`,
											name: `Requisição com ${
												ApiBodyType.JSON
											}<br/>(${method?.toUpperCase()} ${route})`,
											method: method,
											route: route,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
											services: new Map<string, IApiEndpoint>().set(
												`${component?.ref}`,
												endpoint,
											),
										});

										sequenceDiagram?.groups?.set('Business', {
											label: `Core Business Logic`,
											backgroundcolor: 'rgb(254, 240, 199)',
										});

										sequenceDiagram?.participants?.set(`${componentName}`, {
											groupRef: 'Business',
											ref: `${componentName}`,
											label: `${componentName}`,
											isActor: false,
										});
									} else if (component.type === WorkFlowTypeEnum.Alert) {
										const endpoint: IApiEndpoint = {
											ref: `${inputAction?.ref}`,
											name: `Alerta: ${component?.values?.title}`,
											method: ApiMethods.GET,
											route: `${resource.name}`,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
										});
									} else if (component.type === WorkFlowTypeEnum.AlertConfirm) {
										const endpoint: IApiEndpoint = {
											ref: `${inputAction?.ref}`,
											name: `Confirmação: ${component?.values?.title}`,
											method: ApiMethods.GET,
											route: `${resource.name}`,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
										});
									} else if (
										component.type === WorkFlowTypeEnum.EnableDisableFields
									) {
										const fields: string[] = [];
										component?.values?.enableDisableList?.forEach(
											(field: any) => {
												fields.push(field?.label);
											},
										);
										const endpoint: IApiEndpoint = {
											ref: `${inputAction?.ref}`,
											name: `Habilitar/Desabilitar:<br/>${fields.join(
												'<br/>',
											)}`,
											method: ApiMethods.GET,
											route: `${resource.name}`,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
										});
									} else if (component.type === WorkFlowTypeEnum.ShowHideFields) {
										const fields: string[] = [];
										component?.values?.showHideList?.forEach((field: any) => {
											fields.push(field?.label);
										});
										const endpoint: IApiEndpoint = {
											ref: `${inputAction?.ref}`,
											name: `Exibir/Ocultar:<br/>${fields.join('<br/>')}`,
											method: ApiMethods.GET,
											route: `${resource.name}`,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
										});
									} else if (
										component.type === WorkFlowTypeEnum.SetAutocompleteValue
									) {
										const endpoint: IApiEndpoint = {
											ref: `${inputAction?.ref}`,
											name: `Atribuir valor: ${component?.values?.field}`,
											method: ApiMethods.GET,
											route: `${resource.name}`,
											bodyType: ApiBodyType.JSON,
											isAuth: true,
											databaseTableRef: sequenceDiagram?.table?.ref,
										};

										sequenceDiagram?.endpoints?.set(`${component?.ref}`, {
											api: endpoint,
										});
									}
								});

								sequences.set(
									`Interação: ${inputAction.inputActionType}`,
									sequenceDiagram,
								);
								sequenceDiagram = {...sequenceDiagram};
								sequenceDiagram.endpoints = new Map<
									string,
									ISequenceDiagramApiEndpoint
								>();
							});
						}
					});
				}
			}
		});

		return getDiagramDataFrontEnd(sequences);
	};

	const buildDiagramBackEnd = () => {
		const sequenceDiagram: ISequenceDiagram = {};
		const hasSelectComp = application.resources
			?.find(x => x.selected)
			?.widgets?.find(x => x.selected)
			? true
			: false;

		application.resources?.forEach(resource => {
			if (resource.selected) {
				if (hasSelectComp) {
					resource.widgets?.map(widget => {
						if (widget.selected) {
							const dataTable = getDataTable(resource.databaseTableRef);
							const dataBase = application.database?.databases?.find(function (db) {
								return db.ref === dataTable?.databaseRef;
							});
							const participantDataBase =
								dataBase?.db != null
									? `Database ${DatabaseType[parseInt(dataBase.db)]}`
									: '';
							const endpointsWithAuth = application.apis?.find(x =>
								x.endpoints?.find(x => !!x.isAuth),
							);
							const useApi = widget.actions?.find(
								x => x.actionType == ActionTypeEnum.Api,
							);
							const useOData = widget.actions?.find(
								x => x.actionType == ActionTypeEnum.Search,
							);
							const interfaceType =
								useApi && useOData
									? 'REST + OData'
									: useApi && !useOData
									? 'REST'
									: !useApi && useOData
									? 'OData'
									: '';

							sequenceDiagram.database = dataBase;
							sequenceDiagram.table = dataTable;
							sequenceDiagram.resourceName = resource.name;
							sequenceDiagram.useRest = useApi != null ? true : false;
							sequenceDiagram.useOData = useOData != null ? true : false;
							sequenceDiagram.useAuthentication =
								endpointsWithAuth != null ? true : false;
							sequenceDiagram.groups = new Map<
								string,
								ISequenceDiagramParticipantGroup
							>();
							sequenceDiagram.participants = new Map<
								string,
								ISequenceDiagramParticipant
							>();
							sequenceDiagram.endpoints = new Map<
								string,
								ISequenceDiagramApiEndpoint
							>();
							sequenceDiagram.dependences = new Map<string, IWorkFlowItem>();

							sequenceDiagram.groups.set('Access', {
								label: 'Acesso externo',
								backgroundcolor: 'rgb(242, 244, 247)',
							});

							sequenceDiagram.groups.set('Interface', {
								label: `Interface ${interfaceType}`,
								backgroundcolor: 'rgb(217, 243, 241)',
							});

							sequenceDiagram.groups.set('Business', {
								label: `Core Business Logic`,
								backgroundcolor: 'rgb(254, 240, 199)',
							});

							sequenceDiagram.participants.set('User', {
								groupRef: 'Access',
								ref: 'User',
								label: undefined,
								isActor: true,
							});

							sequenceDiagram.participants.set('APIGateway', {
								groupRef: 'Interface',
								ref: 'APIGateway',
								label: 'API Gateway',
								isActor: false,
							});

							if (sequenceDiagram.useAuthentication) {
								sequenceDiagram.participants.set('AuthService', {
									groupRef: 'Interface',
									ref: 'AuthService',
									label: 'API Autenticação',
									isActor: false,
								});
							}

							widget.actions?.forEach(action => {
								if (
									action?.actionType == ActionTypeEnum.Api ||
									action?.actionType == ActionTypeEnum.Search
								) {
									const api = application.apis?.find(function (api) {
										return api.ref === action?.api?.targetApi;
									});
									const endpoint = api?.endpoints?.find(function (endpoint) {
										return endpoint.ref === action?.api?.targetEndpoint;
									});

									sequenceDiagram?.participants?.set(
										`${action?.api?.targetApi}`,
										{
											groupRef: 'Interface',
											ref: `${action?.api?.targetApi}`,
											label: `API ${api?.schema}`,
											isActor: false,
										},
									);

									if (action?.api?.targetApi != undefined && endpoint != null) {
										const servicekey = `${action.api.targetApi}_${endpoint.ref}`;

										if (sequenceDiagram?.endpoints?.has(action.api.targetApi)) {
											const service = sequenceDiagram?.endpoints?.get(
												action.api.targetApi,
											);
											if (!service?.services?.has(servicekey)) {
												service?.services?.set(servicekey, endpoint);
											}
										} else {
											sequenceDiagram?.endpoints?.set(action.api.targetApi, {
												api: endpoint,
												services: new Map<string, IApiEndpoint>().set(
													servicekey,
													endpoint,
												),
											});
										}

										const typeService =
											endpoint.method === ApiMethods.GET
												? 'Query'
												: 'Service';
										const dataTableService = getDataTable(
											endpoint.databaseTableRef,
										);
										const resourceName = getResourceName(
											dataTableService?.name,
										);

										if (
											!sequenceDiagram?.participants?.has(
												`${servicekey}_service`,
											)
										) {
											sequenceDiagram?.participants?.set(
												`${servicekey}_service`,
												{
													groupRef: 'Business',
													ref: `${servicekey}_service`,
													label: `${resourceName} ${typeService}`,
													isActor: false,
												},
											);
										}

										if (
											!sequenceDiagram?.participants?.has(
												`${servicekey}_repository`,
											)
										) {
											sequenceDiagram?.participants?.set(
												`${servicekey}_repository`,
												{
													groupRef: 'Business',
													ref: `${servicekey}_repository`,
													label: `${resourceName} Repository`,
													isActor: false,
												},
											);
										}
									}
								} else if (action?.actionType == ActionTypeEnum.WorkFlow) {
									action?.workflow?.components?.forEach(component => {
										if (
											component.type == WorkFlowTypeEnum.Amqp ||
											component.type == WorkFlowTypeEnum.Email ||
											component.type == WorkFlowTypeEnum.Ftp ||
											component.type == WorkFlowTypeEnum.Http
										) {
											const method =
												component.type == WorkFlowTypeEnum.Http
													? component?.values?.method
													: ApiMethods.POST;
											const route =
												component.type == WorkFlowTypeEnum.Http
													? component?.values?.url
													: `/${getResourceName(
															sequenceDiagram?.table?.name,
													  )}`;
											const crudApiEndpoint: IApiEndpoint = {
												ref: `${component?.ref}`,
												name: `${component.type}`,
												method: method,
												route: route,
												bodyType: ApiBodyType.JSON,
												isAuth: true,
												databaseTableRef: sequenceDiagram?.table?.ref,
											};

											const lastEndpointKey = Array.from(
												sequenceDiagram!.endpoints!.keys()!,
											)?.pop();

											if (lastEndpointKey !== undefined) {
												const service =
													sequenceDiagram?.endpoints?.get(
														lastEndpointKey,
													);
												if (!service?.services?.has(crudApiEndpoint.ref)) {
													service?.services?.set(
														crudApiEndpoint.ref,
														crudApiEndpoint,
													);
												}
											}
										} else if (component.type == WorkFlowTypeEnum.For) {
											const tableRef = action?.workflow?.components?.find(
												x => x.refItem === component.ref,
											);
											const dataTableWorkFlowItem = getDataTable(
												tableRef?.values?.tableRef,
											);
											const resourceName = getResourceName(
												dataTableWorkFlowItem?.name,
											);
											const crudApiEndpoint: IApiEndpoint = {
												ref: `${component?.ref}`,
												name: `${WorkFlowTypeEnum.For}`,
												method: ApiMethods.POST,
												route: `/${resourceName}`,
												bodyType: ApiBodyType.JSON,
												isAuth: true,
												databaseTableRef: dataTableWorkFlowItem?.ref,
											};

											const lastEndpointKey = Array.from(
												sequenceDiagram!.endpoints!.keys()!,
											)?.pop();

											if (lastEndpointKey !== undefined) {
												const service =
													sequenceDiagram?.endpoints?.get(
														lastEndpointKey,
													);
												if (!service?.services?.has(crudApiEndpoint.ref)) {
													service?.services?.set(
														crudApiEndpoint.ref,
														crudApiEndpoint,
													);

													sequenceDiagram?.dependences?.set(
														`${component?.ref}`,
														component,
													);
												}
											}
										} else if (
											component.type == WorkFlowTypeEnum.CRUD ||
											component.type == WorkFlowTypeEnum.List ||
											component.type == WorkFlowTypeEnum.Get
										) {
											const dataTableWorkFlowItem = getDataTable(
												component?.values?.tableRef,
											);
											const databaseWorkFlowItem = getDatabase(
												dataTableWorkFlowItem?.databaseRef,
											);
											const resourceName = getResourceName(
												dataTableWorkFlowItem?.name,
											);
											const crudApiEndpoint: IApiEndpoint = {
												ref: `${component?.ref}`,
												name: `${getMethodNameByWorkFlowTarget(
													action?.workflow?.taget,
												)}${resourceName}`,
												method: getMethodByCrud(component.values.operation),
												route: `/${resourceName}`,
												bodyType: ApiBodyType.JSON,
												isAuth: true,
												databaseTableRef: component.values.tableRef,
											};
											const servicekeyWorkFlowItem = `API__${
												dataTableWorkFlowItem?.schema
											}__${
												databaseWorkFlowItem?.name
											}_${getMethodNameByWorkFlowTarget(
												action?.workflow?.taget,
											)}_${dataTableWorkFlowItem?.schema}_${
												dataTableWorkFlowItem?.name
											}`;

											if (
												!sequenceDiagram?.participants?.has(
													`${servicekeyWorkFlowItem}_service`,
												)
											) {
												sequenceDiagram?.participants?.set(
													`${servicekeyWorkFlowItem}_service`,
													{
														groupRef: 'Business',
														ref: `${servicekeyWorkFlowItem}_service`,
														label: `${resourceName} Service`,
														isActor: false,
													},
												);
											}

											if (
												!sequenceDiagram?.participants?.has(
													`${servicekeyWorkFlowItem}_repository`,
												)
											) {
												sequenceDiagram?.participants?.set(
													`${servicekeyWorkFlowItem}_repository`,
													{
														groupRef: 'Business',
														ref: `${servicekeyWorkFlowItem}_repository`,
														label: `${resourceName} Repository`,
														isActor: false,
													},
												);
											}

											const lastEndpointKey = Array.from(
												sequenceDiagram!.endpoints!.keys()!,
											)?.pop();

											if (lastEndpointKey !== undefined) {
												const service =
													sequenceDiagram?.endpoints?.get(
														lastEndpointKey,
													);
												if (!service?.services?.has(crudApiEndpoint.ref)) {
													service?.services?.set(
														crudApiEndpoint.ref,
														crudApiEndpoint,
													);

													if (component?.refItem != undefined) {
														sequenceDiagram?.dependences?.set(
															`${component?.ref}`,
															component,
														);
													}
												}
											}
										}
									});
								}
							});

							if (participantDataBase !== '') {
								sequenceDiagram?.participants?.set(`Database`, {
									groupRef: 'Business',
									ref: `Database`,
									label: `${participantDataBase}`,
									isActor: false,
								});
							}

							if (isParticipant(WorkFlowTypeEnum.Amqp, sequenceDiagram?.endpoints)) {
								sequenceDiagram?.participants?.set(`Amqp`, {
									groupRef: 'Business',
									ref: `Amqp`,
									label: `Service Bus`,
									isActor: false,
								});
							}

							if (isParticipant(WorkFlowTypeEnum.Email, sequenceDiagram?.endpoints)) {
								sequenceDiagram?.participants?.set(`Smtp`, {
									groupRef: 'Business',
									ref: `Smtp`,
									label: `Mail Flow`,
									isActor: false,
								});
							}

							if (isParticipant(WorkFlowTypeEnum.Ftp, sequenceDiagram?.endpoints)) {
								sequenceDiagram?.participants?.set(`Ftp`, {
									groupRef: 'Business',
									ref: `Ftp`,
									label: `Ftp`,
									isActor: false,
								});
							}

							if (isParticipant(WorkFlowTypeEnum.Http, sequenceDiagram?.endpoints)) {
								sequenceDiagram?.participants?.set(`Http`, {
									groupRef: 'Business',
									ref: `Http`,
									label: `Http`,
									isActor: false,
								});
							}
						}
					});
				}
			}
		});

		return getDiagramDataBackEnd(sequenceDiagram);
	};

	const getDiagramDataFrontEnd = (sequences: Map<string, ISequenceDiagram>) => {
		let textData = 'sequenceDiagram\n';
		textData += 'autonumber\n';

		const groups: Map<string, ISequenceDiagramParticipantGroup> = new Map<
			string,
			ISequenceDiagramParticipantGroup
		>();
		const participants: Map<string, ISequenceDiagramParticipant> = new Map<
			string,
			ISequenceDiagramParticipant
		>();
		sequences.forEach((value, key) => {
			value.groups?.forEach((group, keygroup) => {
				if (!groups.has(keygroup)) {
					groups.set(keygroup, group);
				}
			});

			value.participants?.forEach((participant, keyparticipant) => {
				if (!participants.has(keyparticipant)) {
					participants.set(keyparticipant, participant);
				}
			});
		});

		textData += getDiagramDataParticipants(groups, participants);

		sequences.forEach((value, key) => {
			textData += `User->>AppService: ${key}\n`;

			textData += getDiagramDataActions(value?.endpoints, value?.dependences);
		});

		return textData;
	};

	const merge = (
		a: ISequenceDiagramParticipantGroup[],
		b: ISequenceDiagramParticipantGroup[],
		predicate = (a: ISequenceDiagramParticipantGroup, b: ISequenceDiagramParticipantGroup) =>
			a === b,
	) => {
		const c = [...a]; // copy to avoid side effects
		// add all items from B to copy C if they're not already present
		b.forEach(bItem =>
			c.some((cItem: ISequenceDiagramParticipantGroup) => predicate(bItem, cItem))
				? null
				: c.push(bItem),
		);
		return c;
	};

	const getDiagramDataBackEnd = (sequenceDiagram: ISequenceDiagram) => {
		let textData = 'sequenceDiagram\n';
		textData += 'autonumber\n';

		textData += getDiagramDataParticipants(
			sequenceDiagram?.groups,
			sequenceDiagram.participants,
		);

		textData += `User->>APIGateway: ${sequenceDiagram.resourceName}: requisição\n`;

		textData += `activate APIGateway\n`;

		if (sequenceDiagram.useAuthentication) {
			textData += `APIGateway-->>User: Validar autenticação\n`;
			textData += `alt Token JWT válido\n`;
			textData += `AuthService->>APIGateway: HTTP 200 OK\n`;
			textData += `else Token JWT inválido ou faltando\n`;
			textData += `AuthService-->>APIGateway: HTTP 401 Unauthorized\n`;
			textData += `end\n`;
		}

		textData += getDiagramDataEndpoints(
			sequenceDiagram?.table,
			sequenceDiagram?.resourceName,
			sequenceDiagram?.endpoints,
			sequenceDiagram?.dependences,
		);

		textData += `deactivate APIGateway\n`;

		return textData;
	};

	const getDiagramDataParticipants = (
		groups?: Map<string, ISequenceDiagramParticipantGroup>,
		participants?: Map<string, ISequenceDiagramParticipant>,
	) => {
		let textData = '';

		groups?.forEach((groupEntity, groupRef) => {
			textData += `box ${groupEntity.backgroundcolor} ${groupEntity.label}\n`;

			const participantList = Array.from(participants!.values())?.filter(
				(item: ISequenceDiagramParticipant) => item.groupRef === groupRef,
			);

			participantList?.forEach((participantEntity, participantRef) => {
				if (participantEntity?.isActor) {
					textData += `actor ${participantEntity?.ref}\n`;
				} else {
					if (participantEntity?.label === undefined) {
						textData += `participant ${participantEntity?.ref}\n`;
					} else {
						textData += `participant ${participantEntity?.ref} as ${participantEntity?.label}\n`;
					}
				}
			});

			textData += `end\n`;
		});

		return textData;
	};

	const getDiagramDataActions = (
		endpoints?: Map<string, ISequenceDiagramApiEndpoint>,
		dependences?: Map<string, IWorkFlowItem>,
	) => {
		let textData = '';

		endpoints?.forEach((endpointEntity, endpointRef) => {
			if (endpointEntity?.services === undefined || endpointEntity?.services?.size === 0) {
				textData += `AppService->>AppService: ${endpointEntity?.api?.name}\n`;
			} else {
				endpointEntity?.services?.forEach((serviceEntity, serviceRef) => {
					textData += `AppService->>${serviceEntity.ref}: ${endpointEntity?.api?.name}\n`;
				});
			}
		});

		return textData;
	};

	const getDiagramDataEndpoints = (
		table?: ITable,
		resourceName?: string,
		endpoints?: Map<string, ISequenceDiagramApiEndpoint>,
		dependences?: Map<string, IWorkFlowItem>,
	) => {
		let textData = '';

		endpoints?.forEach((endpointEntity, endpointRef) => {
			const endpointroute = `(${endpointEntity?.api?.method?.toUpperCase()} ${
				endpointEntity?.api?.route
			})\n`;

			textData += `APIGateway->>${endpointRef}: ${resourceName}: requisição com ${endpointEntity?.api?.bodyType}<br/>${endpointroute}\n`;

			textData += `activate ${endpointRef}\n`;

			if (endpointEntity?.api?.isAuth) {
				textData += `Note over APIGateway,${endpointRef}: Token JWT informado como 'Bearer Token' no header Authorization\n`;
			}

			if (endpointEntity.services!.size > 0) {
				textData += getDiagramDataServices(
					table,
					endpointRef,
					endpointEntity?.api,
					endpointEntity?.services,
					dependences,
				);
			}

			textData += `alt Registro efetuado\n`;
			textData += `${endpointRef}->>APIGateway: HTTP 200 OK\n`;
			textData += `else Falha ao registrar\n`;
			textData += `${endpointRef}-->>APIGateway: HTTP 400 Bad Request\n`;
			textData += `end\n`;
			textData += `opt Falha não prevista\n`;
			textData += `${endpointRef}->>APIGateway: HTTP 500 Internal Server Error\n`;
			textData += `end\n`;

			textData += `deactivate ${endpointRef}\n`;
		});

		return textData;
	};

	const getDiagramDataServices = (
		table?: ITable,
		endpointRef?: string,
		endpoint?: IApiEndpoint,
		services?: Map<string, IApiEndpoint>,
		dependences?: Map<string, IWorkFlowItem>,
	) => {
		let textData = '';
		let openLoopIntoWorkFlowItem = false;
		let actionsIntoWorkFlowItem: string[] = [];
		let firstWorkFlowItem: string | undefined = undefined;
		let lastWorkFlowItem: string | undefined = undefined;

		services?.forEach((serviceEntity, serviceRef) => {
			let isGUID = false;
			const method = getMethodName(serviceEntity.method);
			const table = getDataTable(serviceEntity.databaseTableRef);
			const resourceName = getResourceName(table?.name);
			let serviceUniqueIdentifier: string | undefined = serviceRef;

			if (isValidGUID(serviceUniqueIdentifier)) {
				const workflowservices: string[] = [];
				workflowservices.push(
					`API__${table?.schema}__${application.name?.toLowerCase()}_${getMethodName(
						ApiMethods.POST,
					)}_${table?.schema}_${table?.name}`,
				);
				workflowservices.push(
					`API__${table?.schema}__${application.name?.toLowerCase()}_${getMethodName(
						ApiMethods.PUT,
					)}_${table?.schema}_${table?.name}`,
				);
				workflowservices.push(
					`API__${table?.schema}__${application.name?.toLowerCase()}_${getMethodName(
						ApiMethods.DELETE,
					)}_${table?.schema}_${table?.name}`,
				);

				let workflowservicekey = '';

				serviceUniqueIdentifier = undefined;

				if (!isNaN(parseFloat(serviceEntity.name))) {
					workflowservices.forEach((item, index) => {
						if (services.has(item)) {
							workflowservicekey = item;
						}
					});

					if (services.has(workflowservicekey)) {
						if (parseFloat(serviceEntity.name) == WorkFlowTypeEnum.Ftp) {
							if (openLoopIntoWorkFlowItem) {
								actionsIntoWorkFlowItem.push(
									`${workflowservicekey}_service->>Ftp: ${resourceName}: envio de arquivo\n`,
								);
							} else {
								textData += `${workflowservicekey}_service->>Ftp: ${resourceName}: envio de arquivo\n`;
							}
						} else if (parseFloat(serviceEntity.name) == WorkFlowTypeEnum.Amqp) {
							if (openLoopIntoWorkFlowItem) {
								actionsIntoWorkFlowItem.push(
									`${workflowservicekey}_service->>Amqp: ${resourceName}: envio de mensagem\n`,
								);
							} else {
								textData += `${workflowservicekey}_service->>Amqp: ${resourceName}: envio de mensagem\n`;
							}
						} else if (parseFloat(serviceEntity.name) == WorkFlowTypeEnum.Email) {
							if (openLoopIntoWorkFlowItem) {
								actionsIntoWorkFlowItem.push(
									`${workflowservicekey}_service->>Smtp: ${resourceName}: envio de e-mail\n`,
								);
							} else {
								textData += `${workflowservicekey}_service->>Smtp: ${resourceName}: envio de e-mail\n`;
							}
						} else if (parseFloat(serviceEntity.name) == WorkFlowTypeEnum.Http) {
							if (openLoopIntoWorkFlowItem) {
								actionsIntoWorkFlowItem.push(
									`${workflowservicekey}_service->>Http: ${resourceName}: requisição HTTP (${serviceEntity?.method?.toUpperCase()} para ${serviceEntity?.route?.toLowerCase()})\n`,
								);
							} else {
								textData += `${workflowservicekey}_service->>Http: ${resourceName}: requisição HTTP (${serviceEntity?.method?.toUpperCase()} para ${serviceEntity?.route?.toLowerCase()})\n`;
							}
						}
					}
				} else if (dependences?.has(serviceEntity?.ref)) {
					const dependence = dependences?.get(serviceEntity?.ref);
					const dependenceOwner = services?.get(`${dependence?.refItem}`);
					const dependenceChildren = Array.from(dependences.values()).filter(
						value => value.refItem !== undefined,
					);

					if (dependenceOwner !== undefined) {
						if (!isNaN(parseFloat(dependenceOwner.name))) {
							if (parseFloat(dependenceOwner.name) == WorkFlowTypeEnum.For) {
								openLoopIntoWorkFlowItem = true;
								serviceUniqueIdentifier = workflowservicekey;

								firstWorkFlowItem = Array.from(dependenceChildren)[0]?.ref ?? '';
								lastWorkFlowItem =
									Array.from(dependenceChildren.values()).pop()?.ref ?? '';
							}
						}
					}
				} else {
					isGUID = true;
					serviceUniqueIdentifier = workflowservicekey;
				}
			}

			if (serviceUniqueIdentifier !== undefined) {
				textData += `${endpointRef}->>${serviceUniqueIdentifier}_service: ${resourceName}: requisição\n`;

				if (openLoopIntoWorkFlowItem && serviceEntity?.ref === firstWorkFlowItem) {
					textData += `loop Repetição contínua\n`;
				}

				textData += `activate ${serviceUniqueIdentifier}_service\n`;

				if (!openLoopIntoWorkFlowItem && !isGUID) {
					if (serviceEntity?.method !== ApiMethods.GET) {
						textData += `Note right of ${serviceUniqueIdentifier}_service: Business Logic\n`;

						textData += `${serviceUniqueIdentifier}_service->>${serviceUniqueIdentifier}_service: Mapeamento para Domain\n`;
						textData += `${serviceUniqueIdentifier}_service->>${serviceUniqueIdentifier}_service: Auditoria\n`;
					}
				}

				textData += `${serviceUniqueIdentifier}_service->>${serviceUniqueIdentifier}_repository: Enviar para o BD\n`;

				textData += `activate ${serviceUniqueIdentifier}_repository\n`;

				textData += `activate Database\n`;
				textData += `critical Estabilizando conexão com BD\n`;
				textData += `${serviceUniqueIdentifier}_repository->>Database: ${resourceName}: ${method}<br/>(${table?.schema}.${table?.name})\n`;
				textData += `end\n`;
				textData += `Database-->>${serviceUniqueIdentifier}_repository: ${resourceName}: resposta\n`;
				textData += `deactivate Database\n`;

				textData += `${serviceUniqueIdentifier}_repository-->>${serviceUniqueIdentifier}_service: ${resourceName}: resposta\n`;
				textData += `deactivate ${serviceUniqueIdentifier}_repository\n`;

				if (!isGUID) {
					if (serviceEntity?.method === ApiMethods.GET) {
						textData += `${serviceUniqueIdentifier}_service->>${serviceUniqueIdentifier}_service: Mapeamento para DTO\n`;
					}
				}

				textData += `${serviceUniqueIdentifier}_service-->>${endpointRef}: ${resourceName}: resposta\n`;

				textData += `deactivate ${serviceUniqueIdentifier}_service\n`;

				if (openLoopIntoWorkFlowItem && serviceEntity?.ref === lastWorkFlowItem) {
					textData += `end\n`;

					firstWorkFlowItem = undefined;
					lastWorkFlowItem = undefined;
					openLoopIntoWorkFlowItem = false;

					if (actionsIntoWorkFlowItem?.length > 0) {
						textData += actionsIntoWorkFlowItem.join('');

						actionsIntoWorkFlowItem = [];
					}
				}
			}
		});

		return textData;
	};

	let startX: any = 0;
	let startY: any = 0;
	let scrollTop: any = 0;
	let scrollLeft: any = 0;
	let baseZoom: any = 100;

	const getMethodName = (method: ApiMethods) => {
		switch (method) {
			case ApiMethods.GET:
				return 'Obter';
			case ApiMethods.POST:
				return 'Incluir';
			case ApiMethods.PUT:
				return 'Alterar';
			case ApiMethods.DELETE:
				return 'Remover';
		}
	};

	const getMethodByCrud = (crud: CrudOptions) => {
		switch (crud) {
			case CrudOptions.Get:
			case CrudOptions.List:
				return ApiMethods.GET;
			case CrudOptions.Insert:
				return ApiMethods.POST;
			case CrudOptions.Update:
				return ApiMethods.PUT;
			case CrudOptions.Delete:
				return ApiMethods.DELETE;
		}
	};

	const getMethodNameByWorkFlowTarget = (target: WorkFlowTargetEnum | undefined) => {
		if (target == undefined) {
			target = WorkFlowTargetEnum.Insert;
		}

		switch (target) {
			case WorkFlowTargetEnum.Insert:
				return 'Incluir';
			case WorkFlowTargetEnum.Update:
				return 'Alterar';
			case WorkFlowTargetEnum.Delete:
				return 'Remover';
		}
	};

	const getDatabase = (databaseRef: string | undefined): IDatabaseConfig | undefined => {
		const database = application.database?.databases?.find(function (database) {
			return database.ref === databaseRef;
		});

		return database;
	};

	const getDataTable = (databaseTableRef: string | undefined): ITable | undefined => {
		const dataTable = application.database?.tables?.find(function (table) {
			return table.ref === databaseTableRef;
		});

		return dataTable;
	};

	const getResourceName = (name: string | undefined): string | undefined => {
		return formatScreenName(name ?? '');
	};

	const isParticipant = (
		workFlowType?: WorkFlowTypeEnum,
		endpoints?: Map<string, ISequenceDiagramApiEndpoint>,
	) => {
		let exists = false;

		endpoints?.forEach((endpointEntity, endpointRef) => {
			const participantList = Array.from(endpointEntity.services!.values())?.find(
				(item: IApiEndpoint) => parseFloat(item?.name) === workFlowType,
			);

			if (!exists && participantList !== undefined) {
				exists = true;
			}
		});

		return exists;
	};

	const isValidGUID = (value: string): boolean => {
		if (value.length > 0) {
			return /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/.test(
				value,
			);
		}

		return false;
	};

	const onMouseDown = (e: any) => {
		startX = e.clientX;
		startY = e.clientY;
		scrollTop = (document.getElementById('contentDiagram') as any).scrollTop;
		scrollLeft = (document.getElementById('contentDiagram') as any).scrollLeft;
		document.onmousemove = onMouseMove;

		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;

		return false;
	};

	const onMouseMove = (e: any) => {
		(document.getElementById('contentDiagram') as any).scrollTo({
			left: scrollLeft + (startX - e.clientX),
			top: scrollTop + (startY - e.clientY),
		});

		if (e.stopPropagation) e.stopPropagation();
		if (e.preventDefault) e.preventDefault();
		e.cancelBubble = true;
		e.returnValue = false;
		return false;
	};

	const onMouseWhellScroll = () => {
		(document.getElementById('contentDiagram') as any).addEventListener(
			'wheel',
			(event: any) => {
				const deltaY = event.deltaY == 100 ? -100 : 100;
				const zoom = baseZoom + deltaY / 10;
				if (zoom < 100)
					(document.querySelectorAll('[id^="mermaid-"]')[0] as any).style.width = '100%';
				else {
					(
						document.querySelectorAll('[id^="mermaid-"]')[0] as any
					).style.width = `${zoom}%`;
					baseZoom = zoom;
				}
			},
		);

		window.addEventListener('mouseup', () => {
			document.onmousemove = null;
		});
	};

	return (
		<Modal isOpen={isOpen} onClose={close} width="50%" maxWidth="900px">
			<>
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button
						leftIcon2="x"
						onClick={close}
						fill="auto"
						textColor={applicationTheme.gray[700]}
						type="mininum"
					/>
				</div>

				<ModalTitle title="Diagrama de sequência" />

				<S.ContentDiagram
					id="contentDiagram"
					onMouseDown={onMouseDown}
					onMouseUp={() => (document.onmousemove = null)}>
					<div className="mermaid" id="dbDiagram"></div>
				</S.ContentDiagram>
			</>
		</Modal>
	);
};

export default AddSequenceDiagram;

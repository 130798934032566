import {applicationTheme} from 'src/shared/theme';
import styled from 'styled-components';

export const Content = styled.div`
	height: calc(100vh - 92px);
	overflow-y: auto;

	.icon-left {
		color: ${applicationTheme.gray[400]} !important;
	}

	.labelIcon {
		color: ${applicationTheme.brand[500]} !important;

		&:hover {
			color: ${applicationTheme.brand[700]} !important;
		}
	}

	.cont {
		display: flex;
		align-items: center;
		justify-content: center;
	}
`;

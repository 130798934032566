/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useState} from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import {Button, ModalTitle, SizedBox, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {Modal} from 'src/shared/components/Complex';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {IColumn, IOption, IUniqueIndex} from 'src/@types/app';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {RelationTypeList} from 'src/helpers/select-data/relationTypeList';
import {ApplicationContext} from 'src/context';
import {v4 as uuidV4} from 'uuid';
import {IndexTypeList} from 'src/helpers/select-data/indexTypeList';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

export const RightBarRender = () => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [isOpenRelations, setIsOpenRelations] = useState(false);
	const [isOpenIndex, setIsOpenIndex] = useState(false);
	const [column, setColumn] = useState<IColumn>();
	const [columnDestinyName, setColumnDestinyName] = useState('');
	const [uniqueIndex, setUniqueIndex] = useState<IUniqueIndex>({
		fieldRefs: [''],
	});
	const [isOpenDeleteRelation, setIsOpenDeleteRelation] = useState(false);
	const [isOpenDeleteIndex, setIsOpenDeleteIndex] = useState(false);

	const getColumnList = (): IOption[] => {
		const table = application.database?.tables?.find(table => table.selected);
		return [
			{
				value: '',
				label: 'Selecione...',
			},
			...(table?.columns?.map(item => ({
				value: item.ref ?? '',
				label: item.name ?? '',
			})) ?? []),
		];
	};

	const getTableList = (): IOption[] => {
		const table = application.database?.tables?.find(table => table.selected);
		return [
			{
				value: undefined,
				label: 'Selecione...',
			},
			...(application.database?.tables
				?.filter(x => x.databaseRef == table?.databaseRef)
				?.filter(x => x.ref != table?.ref)
				?.map(item => ({
					value: item.ref ?? '',
					label: item.name ?? '',
				})) ?? []),
		];
	};

	const handleSaveRelation = () => {
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;

		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(item => {
					if (item.ref === tableRef) {
						item.columns = item.columns?.map(itemC => {
							if (itemC.ref == column?.ref) {
								itemC.relationType = column?.relationType;
								itemC.relationName = column?.relationName;
								itemC.constraint = column?.constraint;
							}
							return itemC;
						});
					}

					return item;
				}),
			},
		});

		closeModal();
	};

	const hasRelationRelation = (): boolean => {
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;
		let has = false;

		application.database?.tables?.forEach(item => {
			if (item.ref === tableRef) {
				item.columns?.forEach(itemC => {
					if (itemC.ref == column?.ref && !!itemC.constraint) {
						has = true;
					}
				});
			}
		});

		return has;
	};

	const handleRemoveRelation = () => {
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;

		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(item => {
					if (item.ref === tableRef) {
						item.columns = item.columns?.map(itemC => {
							if (itemC.ref == column?.ref) {
								itemC.relationName = undefined;
								itemC.constraint = undefined;
							}
							return itemC;
						});
					}

					return item;
				}),
			},
		});

		setIsOpenDeleteRelation(true);
		closeModal();
	};

	const closeModal = () => {
		setIsOpenRelations(false);
		setColumn(undefined);
		setColumnDestinyName('');
	};

	const handleSaveIndex = () => {
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;

		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(item => {
					if (item.ref === tableRef) {
						let uniqueIndexs = [...(item.uniqueIndex ?? [])];
						if (uniqueIndex?.ref) {
							uniqueIndexs = uniqueIndexs.map(u => {
								if (u.ref == uniqueIndex?.ref) {
									u.fieldRefs = uniqueIndex.fieldRefs;
									u.type = uniqueIndex.type;
								}
								return u;
							});
						} else {
							uniqueIndexs.push({
								ref: uuidV4().toString(),
								fieldRefs: uniqueIndex?.fieldRefs,
								type: uniqueIndex?.type,
							});
						}

						item.uniqueIndex = uniqueIndexs;
					}

					return item;
				}),
			},
		});

		setIsOpenIndex(false);
		setUniqueIndex({
			fieldRefs: [''],
		});
	};

	const removeIndex = () => {
		if (!uniqueIndex.ref) return;
		const tableRef = application.database?.tables?.find(x => x.selected)?.ref;

		setApplication({
			...application,
			database: {
				...application.database,
				tables: [...(application.database?.tables ?? [])].map(item => {
					if (item.ref === tableRef) {
						const uniqueIndexs = [...(item.uniqueIndex ?? [])].filter(
							u => u.fieldRefs !== uniqueIndex.fieldRefs,
						);
						item.uniqueIndex = uniqueIndexs;
					}

					return item;
				}),
			},
		});

		setIsOpenDeleteIndex(true);
		setIsOpenIndex(false);
		setUniqueIndex({
			fieldRefs: [''],
		});
		closeModal();
	};

	const getRelations = (): IColumn[] => {
		const table = application.database?.tables?.find(x => x.selected);
		return table?.columns?.filter(c => !!c.constraint) ?? [];
	};

	const getRelationName = (c: IColumn) => {
		if (!c.relationName) {
			return application.database?.tables?.find(x => x.ref === c.constraint)?.name ?? '';
		}

		return c.relationName ?? '';
	};

	const getIndexs = (): IUniqueIndex[] => {
		const table = application.database?.tables?.find(x => x.selected);
		return table?.uniqueIndex ?? [];
	};

	const getIndexName = (refs: string[]): string => {
		const table = application.database?.tables?.find(x => x.selected);
		const columnNames = table?.columns
			?.filter(c => refs.includes(c.ref ?? ''))
			.map(x => x.name);
		return columnNames?.join('_') ?? '';
	};

	const closeIndex = () => {
		setIsOpenIndex(false);
		setUniqueIndex({
			fieldRefs: [''],
		});
	};

	return (
		<>
			<S.Content>
				<S.Title>
					<div className="icon">
						<FeatherIcon icon="settings" />
					</div>
					<div className="text">Configurações</div>
				</S.Title>

				<S.Line />

				<S.SubTitle>
					<div className="icon">
						<FeatherIcon icon="link" size={16} />
					</div>
					<div className="text">Relacionamentos</div>
				</S.SubTitle>

				{getRelations().map((item, idx) => (
					<S.Item key={idx}>
						<div className="text">{getRelationName(item)}</div>
						<div
							className="icon"
							onClick={() => {
								setIsOpenRelations(true);
								setColumn(item);
							}}>
							<FeatherIcon icon="edit" size={18} />
						</div>
					</S.Item>
				))}
				<SizedBox height="10px" />

				<Button
					text="Criar relacionamentos"
					leftIcon2="plus-square"
					onClick={() => setIsOpenRelations(true)}
					background={applicationTheme.brand[50]}
					textColor={applicationTheme.brand[600]}
					height="36px"
					borderRadius="3px"
				/>

				<S.Line />

				<S.SubTitle>
					<div className="icon">
						<FeatherIcon icon="list" size={16} />
					</div>
					<div className="text">Índices</div>
				</S.SubTitle>

				{getIndexs().map((item, idx) => (
					<S.Item key={idx}>
						<div className="text">{getIndexName(item.fieldRefs ?? [])}</div>
						<div
							className="icon"
							onClick={() => {
								console.log(item);
								setIsOpenIndex(true);
								setUniqueIndex(item);
							}}>
							<FeatherIcon icon="edit" size={18} />
						</div>
					</S.Item>
				))}
				<SizedBox height="10px" />

				<Button
					text="Criar índices"
					leftIcon2="plus-square"
					onClick={() => setIsOpenIndex(true)}
					background={applicationTheme.brand[50]}
					textColor={applicationTheme.brand[600]}
					height="36px"
					borderRadius="3px"
				/>

				<Modal
					isOpen={isOpenRelations}
					onClose={() => setIsOpenRelations(false)}
					maxWidth="1000px"
					width="50%">
					<div style={{position: 'relative'}}>
						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Button
								leftIcon2="x"
								onClick={closeModal}
								fill="auto"
								textColor={applicationTheme.gray[700]}
								type="mininum"
							/>
						</div>
						<ModalTitle title="Criar relacionamentos" />

						<SystemInput
							width="30%"
							value={column?.relationName}
							onChange={val => setColumn({...column, relationName: val})}
							label="Nome"
						/>

						<Wrapper margin="10px 0">
							<SystemSelect
								width="25%"
								value={RelationTypeList.find(x => x.value == column?.relationType)}
								options={RelationTypeList}
								onChange={val => setColumn({...column, relationType: val.value})}
								label="Tipo"
							/>
							<SystemSelect
								width="25%"
								value={getColumnList().find(x => x.value == column?.ref)}
								options={getColumnList()}
								onChange={val => setColumn({...column, ref: val.value})}
								label="Coluna de origem"
							/>
							<SystemSelect
								width="25%"
								value={getTableList().find(x => x.value == column?.constraint)}
								options={getTableList()}
								onChange={val => {
									setColumn({...column, constraint: val.value});
									const primaryColumn = application.database?.tables
										?.find(x => x.ref == val.value)
										?.columns?.find(x => x.isPrimaryKey);

									setColumnDestinyName(primaryColumn?.name ?? '');
								}}
								label="Tabela de destino"
							/>
							<SystemInput
								width="25%"
								value={columnDestinyName}
								onChange={val => setColumnDestinyName(val)}
								label="Coluna de destino"
								disabled={true}
							/>
						</Wrapper>
						<Wrapper margin="20px 0 0 0" justifyContent="space-between">
							<div style={{display: 'flex', gap: '10px'}}>
								<Button
									text={column?.name ? 'Editar' : 'Salvar'}
									leftIcon2={column?.name ? 'edit' : 'save'}
									onClick={handleSaveRelation}
									fill="auto"
									background={applicationTheme.brand[600]}
									disabled={
										!column?.relationName || !column?.constraint || !column?.ref
									}
								/>
								<Button
									text="Cancelar"
									leftIcon2="x"
									onClick={closeModal}
									fill="auto"
									background={applicationTheme.gray[300]}
									textColor={applicationTheme.gray[700]}
									type="ghost"
								/>
							</div>
							<div>
								{hasRelationRelation() ? (
									<Button
										text="Excluir"
										leftIcon2="trash-2"
										onClick={() => setIsOpenDeleteRelation(true)}
										fill="auto"
										background={applicationTheme.error[600]}
									/>
								) : (
									<></>
								)}
							</div>
						</Wrapper>
					</div>
				</Modal>

				<Modal isOpen={isOpenIndex} onClose={closeIndex} maxWidth="600px" width="50%">
					<div style={{position: 'relative'}}>
						<div style={{display: 'flex', justifyContent: 'flex-end'}}>
							<Button
								leftIcon2="x"
								onClick={closeIndex}
								fill="auto"
								textColor={applicationTheme.gray[700]}
								type="mininum"
							/>
						</div>
						<ModalTitle title="Criar índices" />
						<SystemSelect
							width="100%"
							value={IndexTypeList.find(x => x.value === uniqueIndex?.type)}
							options={IndexTypeList}
							onChange={value => setUniqueIndex({...uniqueIndex, type: value?.value})}
							label="Tipo"
						/>
						{uniqueIndex?.fieldRefs?.map((item, idx) => (
							<>
								<SystemSelect
									key={idx}
									width="100%"
									value={getColumnList().find(
										x => x.value == uniqueIndex.fieldRefs![idx],
									)}
									options={getColumnList()}
									onChange={val => {
										const fieldRefs = [...(uniqueIndex.fieldRefs ?? [])].map(
											(field, fieldIdx) => {
												if (idx === fieldIdx) return val.value;
												return field;
											},
										);

										setUniqueIndex({...uniqueIndex, fieldRefs});
									}}
									label="Campo(s)"
								/>
								<SizedBox height="10px" />
							</>
						))}

						<SizedBox height="10px" />

						<Button
							text="Adicionar campo ao índice"
							onClick={() => {
								const newUnique = [...(uniqueIndex?.fieldRefs ?? [])];
								newUnique.push('');
								setUniqueIndex({
									...uniqueIndex,
									fieldRefs: newUnique,
								});
							}}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
							leftIcon2="plus-square"
						/>

						<Wrapper margin="20px 0 0 0" justifyContent="space-between">
							<div style={{display: 'flex', gap: '10px'}}>
								<Button
									text={uniqueIndex.ref ? 'Editar' : 'Salvar'}
									leftIcon2={uniqueIndex.ref ? 'edit' : 'save'}
									onClick={handleSaveIndex}
									fill="auto"
									background={applicationTheme.brand[600]}
									disabled={
										!uniqueIndex?.fieldRefs || // Verifica se o campo Campo(s) está preenchido
										uniqueIndex.fieldRefs?.filter(x => !!x).length === 0 || // Verifica se tem ao menos um valor válido
										Array.from(new Set(uniqueIndex.fieldRefs)).length !==
											uniqueIndex.fieldRefs?.length || // Verifica se os campos são únicos
										!uniqueIndex?.type // Verifica se o campo Tipo está preenchido
									}
								/>
								<Button
									text="Cancelar"
									leftIcon2="x"
									onClick={closeIndex}
									fill="auto"
									background={applicationTheme.gray[300]}
									textColor={applicationTheme.gray[700]}
									type="ghost"
								/>
							</div>
							<div>
								{/* Mostrar botão excluir somente quando estiver em edição */}
								{uniqueIndex.ref && (
									<Button
										text="Excluir"
										leftIcon2="trash-2"
										onClick={() => setIsOpenDeleteIndex(true)}
										fill="auto"
										background={applicationTheme.error[600]}
									/>
								)}
							</div>
						</Wrapper>
					</div>
				</Modal>

				<Modal
					isOpen={isOpenDeleteRelation}
					onClose={() => setIsOpenDeleteRelation(false)}
					width="auto">
					<>
						<ModalTitle
							title="Deletar relacionamento?"
							icon={
								<IconWrapper>
									<FeatherIcon icon="alert-circle" size={20} />
								</IconWrapper>
							}
							centerTitle={true}
						/>
						<div
							style={{
								color: applicationTheme.gray[500],
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontSize: '14px',
								paddingBottom: '24px',
							}}>
							Tem certeza de que deseja excluir este item? <br />
							Essa ação não pode ser desfeita.
						</div>
						<Wrapper margin="20px 0 0 0" justifyContent="end">
							<Button
								text="Cancelar"
								onClick={() => setIsOpenDeleteRelation(false)}
								fill="full"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
							<Button
								text="Deletar"
								onClick={() => {
									handleRemoveRelation(); // Chama a função de remoção ao confirmar
									setIsOpenDeleteRelation(false); // Fecha a modal
								}}
								fill="full"
								background={applicationTheme.error[600]}
							/>
						</Wrapper>
					</>
				</Modal>

				<Modal
					isOpen={isOpenDeleteIndex}
					onClose={() => setIsOpenDeleteIndex(false)}
					width="auto">
					<>
						<ModalTitle
							title="Deletar índice?"
							icon={
								<IconWrapper>
									<FeatherIcon icon="alert-circle" size={20} />
								</IconWrapper>
							}
							centerTitle={true}
						/>
						<div
							style={{
								color: applicationTheme.gray[500],
								textAlign: 'center',
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								fontSize: '14px',
								paddingBottom: '24px',
							}}>
							Tem certeza de que deseja excluir este item? <br />
							Essa ação não pode ser desfeita.
						</div>
						<Wrapper margin="20px 0 0 0" justifyContent="end">
							<Button
								text="Cancelar"
								onClick={() => setIsOpenDeleteIndex(false)}
								fill="full"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
							<Button
								text="Deletar"
								onClick={() => {
									removeIndex(); // Chama a função de remoção ao confirmar
									setIsOpenDeleteIndex(false); // Fecha a modal
								}}
								fill="full"
								background={applicationTheme.error[600]}
							/>
						</Wrapper>
					</>
				</Modal>
			</S.Content>
		</>
	);
};

import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import {
	Button,
	CheckBox,
	SizedBox,
	Wrapper,
	Text,
	WidthBox,
	Line,
	ModalTitle,
} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {DatabaseList} from 'src/helpers/select-data/databasesList';
import {IDatabaseConfig, IDatabaseRule, IOption} from 'src/@types/app';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import Modal from 'src/shared/components/Complex/Modal';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

interface CellType {
	row: IDatabaseConfig;
}

interface CellTypeRule {
	row: IDatabaseRule;
}

export const RenderDataBase = () => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [data, setData] = useState<IDatabaseConfig>();
	const [rule, setRule] = useState<IDatabaseRule>();
	const [envs, setEnvs] = useState<IOption[]>([]);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [itemToDelete, setItemToDelete] = useState<IDatabaseConfig>();
	const [deleteAction, setDeleteAction] = useState<() => void>(() => {
		console.log('Delete.');
	});

	useEffect(() => {
		setEnvs([
			{value: undefined, label: 'Selecione...'},
			...(application.environments?.map(item => ({
				label: item.name as string,
				value: item.ref as string,
			})) ?? []),
		]);
	}, [application]);

	const handleSaveDb = () => {
		if (data?.ref) {
			setApplication({
				...application,
				database: {
					...application?.database,
					databases: [...(application?.database?.databases ?? [])].map(item => {
						if (item.ref === data.ref) {
							item.envRef = data.envRef;
							item.description = data.description;
							item.enableCache = data.enableCache;
							item.enableTransactionLogs = data.enableTransactionLogs;
							item.db = data.db;
							item.name = data.name;
							item.useSchema = data.useSchema;
						}
						return item;
					}),
				},
			});
		} else {
			const databases = [...(application?.database?.databases ?? [])];
			/*
			let databases =
				!!application?.database?.databases && application?.database?.databases?.length > 0
					? application?.database?.databases
					: [];*/

			databases.push({
				...data,
				ref: crypto.randomUUID(),
			});

			setApplication({...application, database: {...application?.database, databases}});
		}

		setData(undefined);
	};

	const removeDbItem = (item: IDatabaseConfig) => {
		setItemToDelete(item);
		setDeleteAction(() => () => {
			setApplication({
				...application,
				database: {
					...application.database,
					databases: [
						...(application.database?.databases ?? []).filter(x => x.ref != item.ref),
					],
				},
			});
		});
		setIsOpenDelete(true);
	};

	const handleSaveRule = () => {
		let newRules: IDatabaseRule[];

		if (rule?.ref) {
			newRules = [...(application?.databaseRules ?? [])].map(x => {
				if (x.ref === rule.ref) return {...rule};
				return x;
			});
		} else {
			newRules = [...(application?.databaseRules ?? [])];
			newRules.push({...rule, ref: crypto.randomUUID()});
		}

		setApplication({
			...application,
			databaseRules: newRules,
		});

		setRule(undefined);
	};

	const handleDelete = () => {
		deleteAction();
		setIsOpenDelete(false);
	};

	const removeRuleItem = (item: IDatabaseRule) => {
		setItemToDelete(item);
		setDeleteAction(() => () => {
			setApplication({
				...application,
				databaseRules: application?.databaseRules?.filter(x => x.ref != item.ref),
			});
		});
		setIsOpenDelete(true);
	};

	return (
		<>
			<S.Content>
				<S.Tag>
					<div className="text">Banco de dados</div>
				</S.Tag>
				<S.Card>
					<Wrapper margin="0 0 10px 0">
						<SystemSelect
							width="20%"
							value={envs.find(x => x.value == data?.envRef)}
							onChange={val => setData({...(data ?? {}), envRef: val.value})}
							label="Ambiente"
							options={envs}
						/>
						<SystemSelect
							width="40%"
							value={DatabaseList?.find(x => x.value === data?.db)}
							options={DatabaseList}
							onChange={value => setData({...(data ?? {}), db: value?.value})}
							label="Banco"
						/>
						<SystemInput
							width="40%"
							value={data?.name}
							onChange={val => setData({...(data ?? {}), name: val})}
							label="Nome do banco"
							mask="Sem espaço"
						/>
					</Wrapper>
					<SystemInput
						width="100%"
						value={data?.description}
						onChange={val => setData({...(data ?? {}), description: val})}
						label="Descrição"
					/>

					<SizedBox height="10px" />
					<Wrapper margin="0 0 10px 0">
						<CheckBox
							checked={data?.enableWindowsAuthentication ?? false}
							handleToggle={() =>
								setData({
									...(data ?? {}),
									enableWindowsAuthentication: !data?.enableWindowsAuthentication,
								})
							}
							label="Windows Authentication?"
							textColor="#75868f"
							fontWeight="400"
						/>
					</Wrapper>
					{!data?.enableWindowsAuthentication ? (
						<Wrapper margin="0 0 10px 0">
							<SystemInput
								value={data?.user}
								onChange={val => setData({...(data ?? {}), user: val})}
								label="Usuário"
								mask="Sem espaço"
								disabled={data?.enableWindowsAuthentication}
							/>
							<SystemInput
								value={data?.password}
								onChange={val => setData({...(data ?? {}), password: val})}
								label="Senha"
								mask="Sem espaço"
								disabled={data?.enableWindowsAuthentication}
								type="password"
							/>
							<SystemInput
								value={data?.port}
								onChange={val => setData({...(data ?? {}), port: val})}
								label="Porta"
								mask="Numero"
								disabled={data?.enableWindowsAuthentication}
							/>
						</Wrapper>
					) : (
						<></>
					)}

					<Wrapper margin="0 0 12px 0">
						<CheckBox
							checked={data?.enableTransactionLogs ?? false}
							handleToggle={() =>
								setData({
									...(data ?? {}),
									enableTransactionLogs: !data?.enableTransactionLogs,
								})
							}
							label="Habilitar log?"
							textColor="#75868f"
							fontWeight="400"
						/>
						<CheckBox
							checked={data?.useSchema ?? false}
							handleToggle={() =>
								setData({
									...(data ?? {}),
									useSchema: !data?.useSchema,
								})
							}
							label="Usar schema?"
							textColor="#75868f"
							fontWeight="400"
						/>
					</Wrapper>

					<Wrapper justifyContent="end">
						<Button
							text={data?.ref ? 'Editar' : 'Adicionar'}
							onClick={handleSaveDb}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />

					<SystemDataGrid
						rowsData={application.database?.databases ?? []}
						columns={[
							{
								flex: 0.05,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => setData(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => removeDbItem(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'env',
								headerName: `Ambiente`,
								renderCell: ({row}: CellType) =>
									application.environments?.find(x => x.ref == row.envRef)
										?.name ?? '',
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'db',
								headerName: `Banco`,
								renderCell: ({row}: CellType) =>
									DatabaseList.find(x => x.value == row.db)?.label ?? '',
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.name,
							},
						]}
					/>
				</S.Card>

				<SizedBox height="25px" />

				<S.Tag>
					<div className="text">
						Configuração dos campos das tabelas que serão desconsiderados no momento da
						criação das telas a partir da estrutura do banco de dados
					</div>
				</S.Tag>
				<S.Card>
					<Wrapper margin="0 0 12px 0">
						<WidthBox width="100%">
							<SystemInput
								value={rule?.findedName}
								onChange={val => setRule({...(rule ?? {}), findedName: val})}
								label="Nome da coluna"
							/>
						</WidthBox>
					</Wrapper>

					<Wrapper justifyContent="end">
						<Button
							text={data?.ref ? 'Editar' : 'Adicionar'}
							onClick={handleSaveRule}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>

					<SizedBox height="20px" />

					<SystemDataGrid
						rowsData={application.databaseRules ?? []}
						columns={[
							{
								flex: 0.05,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellTypeRule) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => setRule(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => removeRuleItem(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.1,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'findedName',
								headerName: `Nome`,
								renderCell: ({row}: CellTypeRule) => row.findedName,
							},
						]}
					/>
				</S.Card>

				<SizedBox height="20px" />
			</S.Content>

			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar banco de dados?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este banco de dados? <br />
						Essa ação não pode ser desfeita.
						<b>{itemToDelete?.name}</b>
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={handleDelete}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

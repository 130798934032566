import React, {useContext, useEffect, useState} from 'react';
import {IWorkFlow} from 'src/@types/app';
import {WorkFlowTypeEnum} from 'src/@types/enums';
import {Modal} from 'src/shared/components/Complex';
import WorkFlowFrontModal from 'src/shared/components/Global/WorkFlowFront';
import {
	Button,
	WidthBox,
	Wrapper,
	Text,
	SizedBox,
	Line,
	ModalTitle,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {v4 as uuidV4} from 'uuid';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
};

interface CellType {
	row: IWorkFlow;
}

export const AddWorkflow = ({isOpen, setIsOpen}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [name, setName] = useState('');
	const [workFlowFront, setWorkFlowFront] = useState<IWorkFlow[]>([]);
	const [editWorkflowItem, setEditWorkflowItem] = useState<IWorkFlow>();
	const [isOpenWorkFlow, setIsOpenWorkflow] = useState(false);
	const [isOpenDelete, showIsOpenDelete] = useState(false);

	useEffect(() => {
		setWorkFlowFront(application.resources?.find(x => x.selected)?.workflows ?? []);
	}, []);

	useEffect(() => {
		const resources = application.resources?.map(resource => {
			if (resource.selected) resource.workflows = workFlowFront;
			return resource;
		});

		setApplication({...application, resources});
	}, [workFlowFront]);

	const save = () => {
		let data = [...workFlowFront];

		if (!editWorkflowItem) {
			data.push({
				selected: false,
				ref: uuidV4().toString(),
				name,
				components: [
					{
						ref: uuidV4().toString(),
						text: 'Titulo',
						type: WorkFlowTypeEnum.Variable,
					},
				],
			});
		} else {
			data = data.map(item => {
				if (item.ref === editWorkflowItem.ref) {
					item.name = name;
				}
				return item;
			});
		}
		setWorkFlowFront(data);
		setName('');
		setEditWorkflowItem(undefined);
	};

	const editItem = (item: IWorkFlow) => {
		workFlowFront.forEach(x => {
			if (item.ref === x.ref) {
				setEditWorkflowItem(x);
				setName(x.name ?? '');
			}
		});
	};

	const removeItem = (item: IWorkFlow) => {
		setWorkFlowFront([...workFlowFront].filter(x => x.ref !== item.ref));
	};

	const configWorkflow = (item: IWorkFlow) => {
		setWorkFlowFront(
			workFlowFront.map(workItem => {
				if (workItem.ref === item.ref) workItem.selected = true;
				else workItem.selected = false;
				return workItem;
			}),
		);
		setIsOpen(false);
		setIsOpenWorkflow(true);
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)} width="50%" maxWidth="900px">
				<>
					<Wrapper>
						<SystemInput value={name} onChange={setName} label="Nome" />
						<WidthBox width="auto" margin="20px 0 0 0">
							<Button
								icon2={editWorkflowItem ? 'edit-3' : 'plus'}
								onClick={save}
								fill="auto"
								textColor={applicationTheme.brand[600]}
								background={applicationTheme.brand[100]}
								disabled={
									!name ||
									!!workFlowFront.find(
										x => x.name?.toUpperCase() == name.toUpperCase(),
									)
								}
								height="41px"
							/>
						</WidthBox>
					</Wrapper>

					<SizedBox height="12px" />
					<SystemDataGrid
						rowsData={workFlowFront}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => configWorkflow(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar" />
												</MenuItem>
												<MenuItem
													onClick={() => editItem(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="edit-3" size={16} />
													<Text text="Editar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => removeItem(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>

												{/* Modal de Exclusão */}
												<Modal
													isOpen={isOpenDelete}
													onClose={() => showIsOpenDelete(false)}
													width="auto">
													<>
														<ModalTitle
															title="Deletar Workflow?"
															icon={
																<IconWrapper>
																	<FeatherIcon
																		icon="alert-circle"
																		size={20}
																	/>
																</IconWrapper>
															}
															centerTitle={true}
														/>
														<div
															style={{
																color: applicationTheme.gray[500],
																textAlign: 'center',
																display: 'flex',
																flexDirection: 'column',
																alignItems: 'center',
																fontSize: '14px',
																paddingBottom: '24px',
															}}>
															Tem certeza de que deseja excluir este
															workflow? <br />
															Essa ação não pode ser desfeita.
														</div>
														<Wrapper
															margin="20px 0 0 0"
															justifyContent="end">
															<Button
																text="Cancelar"
																onClick={() =>
																	showIsOpenDelete(false)
																}
																fill="full"
																background={
																	applicationTheme.gray[300]
																}
																textColor={
																	applicationTheme.gray[700]
																}
																type="ghost"
															/>
															<Button
																text="Deletar"
																onClick={() => removeItem(row)}
																fill="full"
																background={
																	applicationTheme.error[600]
																}
															/>
														</Wrapper>
													</>
												</Modal>
											</>
										}
									/>
								),
							},
							{
								flex: 0.5,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.name,
							},
						]}
					/>

					<Wrapper margin="25px 0 0 0" justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px'}}>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={() => setIsOpen(false)}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>
			<WorkFlowFrontModal
				isOpen={isOpenWorkFlow}
				onClose={() => {
					setIsOpenWorkflow(false);
					setIsOpen(true);
				}}
				workFlowItem={workFlowFront.find(x => x.selected)?.components ?? []}
				setWorkFlowItem={val => {
					setWorkFlowFront(
						[...workFlowFront].map(x => {
							if (x.selected) x.components = val;
							return x;
						}),
					);
				}}
			/>
		</>
	);
};

/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useState} from 'react';
import {Modal} from 'src/shared/components/Complex';
import {Button, ModalTitle, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {IApplication, IProjConfig, IScreenCreator} from 'src/@types/app';
import {ApplicationContext, LoadingBarContext, LoadingContext} from 'src/context';
import {
	TransformToScreen,
	UpdateTransformToScreen,
	verifyExpandRelations,
} from 'src/helpers/methods/database-to-screen';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import {ResourceApi} from 'src/shared/repositories/resource-api';
import {extractWidgets} from 'src/helpers/methods/api-methods';
import {RenderConfigureGeneration} from './Render';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

export const GenerateScreen = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);
	const [, setLoadingBar] = useContext(LoadingBarContext);

	const [projConfig, setProjConfig] = useState<IProjConfig>();

	const applicationApi = new ApplicationApi();
	const resourceApi = new ResourceApi();

	const close = () => {
		onClose();
	};

	const refreshBar = (show: boolean, progress: number, total: number) => {
		setLoadingBar({
			show,
			total,
			progress,
		});
	};

	const removeAllResources = async (id: number) => {
		await resourceApi.deleteAll(id);
	};

	const save = async () => {
		try {
			setLoading(true);
			refreshBar(true, 0, 4);

			if (projConfig?.removeAllResources) await removeAllResources(application!.id!);
			refreshBar(true, 2, 4);
			if (projConfig?.createScreen) {
				const screen = TransformToScreen(application!);
				await saveFolders(application!, screen);
				refreshBar(true, 3, 4);
				await createResources(application!, screen);
				refreshBar(true, 4, 4);
			} else if (projConfig?.updateScreen && application) {
				const newApp = await UpdateTransformToScreen(application);
				refreshBar(true, 3, 4);
				let countIndex = 0;
				const resourcesToCreate = newApp.resources?.filter(x => !x.id);
				const resourcesToUpdate = newApp.resources?.filter(x => x.id);
				const total = (resourcesToCreate?.length ?? 0) + (resourcesToUpdate?.length ?? 0);

				for (const resourceItem of resourcesToCreate ?? []) {
					countIndex++;
					refreshBar(true, countIndex, total);
					await resourceApi.create({
						databaseTableRef: resourceItem.databaseTableRef ?? '',
						name: resourceItem.name!,
						isLogin: false,
						isRoot: false,
						menu: JSON.stringify(resourceItem.menu),
						path: resourceItem.path!,
						reference: resourceItem.ref!,
						type: resourceItem.type!,
						useDefaultLayout: resourceItem.useDefaultLayout!,
						width: '',
						applicationId: newApp.id!,
						widgets: extractWidgets(resourceItem.widgets ?? []),
					});
				}
				for (const resourceItem of resourcesToUpdate ?? []) {
					countIndex++;
					refreshBar(true, countIndex, total);
					await resourceApi.updateComplex(resourceItem);
				}

				refreshBar(true, 4, 4);
			} else if (projConfig?.checkRelations && application) {
				refreshBar(false, 0, 0);
				const resources = await resourceApi.getAll(application.id!);
				application.resources = resources ?? [];
				verifyExpandRelations(application);

				for (const resourceItem of application.resources ?? []) {
					await resourceApi.updateComplex(resourceItem);
				}
				return;
			}

			await saveProj();
		} finally {
			setLoading(false);
			refreshBar(false, 0, 0);
			const result = await applicationApi.getResources(application!, false);
			if (result) setApplication(result);
			close();
		}
	};

	const createResources = async (app: IApplication, screen: IScreenCreator) => {
		let index = 0;
		for (const resource of screen.resources) {
			index++;
			refreshBar(true, index, screen.resources.length);
			await resourceApi.create({
				databaseTableRef: resource.databaseTableRef ?? '',
				name: resource.name!,
				isLogin: false,
				isRoot: false,
				menu: JSON.stringify(resource.menu),
				path: resource.path!,
				reference: resource.ref!,
				type: resource.type!,
				useDefaultLayout: resource.useDefaultLayout!,
				width: '',
				applicationId: app.id!,
				widgets: extractWidgets(resource.widgets ?? []),
			});
		}
	};

	const saveProj = async () => {
		let newapplication = {...application};

		if (projConfig?.createScreen || projConfig?.updateScreen) {
			newapplication = {
				...application,
				database: {
					...application?.database,
					tables: [...(application?.database?.tables ?? [])].map(x => {
						x.created = true;
						x.columns = x.columns?.map(y => {
							y.created = true;
							return y;
						});
						return x;
					}),
				},
			};
		}

		await applicationApi.updateComplex({
			id: newapplication!.id!,
			database: newapplication?.database
				? JSON.stringify(newapplication.database)
				: undefined,
			apis: newapplication?.apis ? JSON.stringify(newapplication.apis) : undefined,
			databaseRules: newapplication?.databaseRules
				? JSON.stringify(newapplication.databaseRules)
				: undefined,
			contextEnvironment: newapplication.userConfiguration
				? JSON.stringify(newapplication.userConfiguration)
				: undefined,
			environments: newapplication.environments
				? JSON.stringify(newapplication.environments)
				: undefined,
			amqps: newapplication.amqps ? JSON.stringify(newapplication.amqps) : undefined,
			caches: newapplication.caches ? JSON.stringify(newapplication.caches) : undefined,
			emails: newapplication.emails ? JSON.stringify(newapplication.emails) : undefined,
			ftps: newapplication.ftps ? JSON.stringify(newapplication.ftps) : undefined,
		});
	};

	const saveFolders = async (app: IApplication, screen: IScreenCreator) => {
		if (projConfig?.removeAllResources) {
			await applicationApi.updateFolder({
				id: app.id!,
				folders: JSON.stringify(screen.folders),
			});
		} else {
			const newFolders = [...(app.folders ?? [])];
			screen.folders.forEach(item => newFolders.push(item));

			await applicationApi.updateFolder({
				id: app.id!,
				folders: JSON.stringify(newFolders),
			});
		}
	};

	return (
		<Modal isOpen={isOpen} onClose={() => onClose()} maxWidth="65%" width="1000px">
			<>
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button
						leftIcon2="x"
						onClick={close}
						fill="auto"
						textColor={applicationTheme.gray[700]}
						type="mininum"
					/>
				</div>
				<ModalTitle title="Gerar telas" />

				<RenderConfigureGeneration projConfig={projConfig} setProjConfig={setProjConfig} />

				<Wrapper justifyContent="space-between">
					<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
						<Button
							text="Gerar"
							leftIcon2="settings"
							onClick={save}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
						<Button
							text="Cancelar"
							leftIcon2="x"
							onClick={close}
							fill="auto"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
					</div>
				</Wrapper>
			</>
		</Modal>
	);
};

export enum ComponentTypeEnum {
	UI,
	Container,
	Complex,
}

export enum ComponentSimpleEnum {
	Text = 'Text',
	Input = 'Input',
	InputDate = 'InputDate',
	InputTag = 'InputTag',
	Select = 'Select',
	Autocomplete = 'Autocomplete',
	Checkbox = 'Checkbox',
	Switch = 'Switch',
	Button = 'Button',
	IconButton = 'IconButton',
	Line = 'Line',
	Box = 'Box',
	SizedBox = 'SizedBox',
	WidthBox = 'WidthBox',
	Wrapper = 'Wrapper',
	Collapse = 'Collapse',
	Address = 'Address',
	TextArea = 'TextArea',
	Timeline = 'Timeline',
	Section = 'Section',
	IconContent = 'IconContent',
	Default = '',
}

export enum ComponentComplexEnum {
	DataGrid = 'DataGrid',
	Tab = 'Tab',
	UploadFile = 'UploadFile',
	Timeline = 'Timeline',
	LabelValue = 'LabelValue',
	Chart = 'Chart',
	Modal = 'Modal',
}

export enum ResourceTypeEnum {
	Page,
	Modal,
	SubComponent,
}

export enum ActionTypeEnum {
	NextPage = 1,
	DataGrid = 2,
	OpenModal = 3,
	Api = 4,
	Search = 5,
	Clear = 7,
	SetValue = 8,
	WorkFlow = 9,
	Validation = 10,
	WorkFlowFront = 11,
}

export enum ValidationTypeEnum {
	Required = 'required',
	MinLength = 'minLength',
	MaxLength = 'maxLength',
	MinValue = 'minValue',
	MaxValue = 'maxValue',
	GreatThan = 'greatThan',
	LessThan = 'lessThan',
	Email = 'email',
	CnpjCpf = 'CnpjCpf',
	Between = 'Between',
	Url = 'url',
}

export enum ApiMethods {
	GET = 'get',
	POST = 'post',
	PUT = 'put',
	DELETE = 'delete',
}

export enum ApiBodyType {
	JSON = 'JSON',
	QUERY = 'QUERY',
	URL = 'URL',
}

export enum ApiMapType {
	REQUEST = 'request',
	RESPONSE = 'response',
}

export enum GridDataActionEnum {
	Remove = 'Remover',
	Edit = 'Editar',
}

export enum ApiParamTypeEnum {
	STRING = 'string',
	NUMBER = 'number',
	BOOLEAN = 'boolean',
	OBJECT = 'object',
	ARRAY = 'array',
	FILE = 'file',
	DATE = 'Date',
}

export enum DatabaseColumnEnum {
	String,
	Int16,
	Int32,
	Int64,
	Boolean,
	Date,
	DateTime,
	Decimal,
	Byte,
	DateOnly,
	TimeOnly,
}

export enum DatabaseMaskEnum {
	Numero = 'Numero',
	Cpf = 'CPF',
	Cnpj = 'CNPJ',
	CpfCnpj = 'CPF/CNPJ',
	Cep = 'CEP',
	Telefone = 'Telefone',
	Hora = 'Hora',
	Monetario = 'Monetário',
	Placa = 'Placa',
	SemEspaco = 'Sem espaço',
	Senha = 'Senha',
	Guid = 'Guid',
	URL = 'Url',
	Email = 'E-mail',
}

export enum WidgetPropertyTypes {
	Configuration = 'Configuração',
	Style = 'Estilo',
}

export enum DatabaseType {
	SqlServer,
	Postgres,
	MySql,
	Oracle,
	OracleDevart,
	SQLite,
}

export enum ExpressionFunctionEnum {
	Concat,
	UpperCase,
	LowerCase,
	ConvertFromDate,
	Arithmetic,
	Field,
	FreeText,
	Comparetor,
	DataProp,
}

export enum WorkFlowTypeEnum {
	Condition,
	Variable,
	AttrVariable,
	Expression,
	CRUD,
	Default,
	Get,
	List,
	Http,
	For,
	IfYes,
	IfNo,
	Amqp,
	Email,
	Ftp,
	Alert,
	AlertConfirm,
	ShowHideFields,
	EnableDisableFields,
	SetAutocompleteValue,
}

export enum WorkFlowTargetEnum {
	Insert = 'Insert',
	Update = 'Update',
	Delete = 'Delete',
}

export enum FtpEnum {
	FTP = 'FTP',
	SFTP = 'SFTP',
	WinSCP = 'WinSCP',
}

export enum AmqpEnum {
	Rabbit = 'Rabbit',
	Kafka = 'Kafka',
	AzureServiceBus = 'AzureServiceBus',
}

export enum IInputActionEnum {
	OnChange = 'Ao alterar o conteúdo (OnChange)',
	OnFocus = 'Ao receber o foco (OnFocus)',
	OnBlur = 'Ao perder o foco (OnBlur)',
	OnPressEnter = 'Ao pressionar a tecla ENTER (OnPressEnter)',
}

export enum WorkFlowDataTypeEnum {
	Data = 'Data',
	Front = 'Front',
	Input = 'Input',
}

export enum CacheEnum {
	Memory = 'Memory',
	Redis = 'Redis',
}

export enum IndexType {
	IndiceUnico = 'Chave única',
	IndiceComum = 'Índice',
}

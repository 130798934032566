/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {Button, Input, Select, SelectIcon, SizedBox, Switch} from 'src/shared/components/UI';
import * as S from './styles';
import {PhotoshopPicker} from 'react-color';
import {IComponent, IInputAction, IWorkFlowItem} from 'src/@types/app';
import {COMPONENT_VALIDATION} from 'src/shared/validation/component-validation';
import {ComponentSimpleEnum, WidgetPropertyTypes} from 'src/@types/enums';
import {getListIcons} from 'src/helpers/select-data/iconList';
import {getWidgetPropertyGroup} from 'src/helpers/methods/widget-property-groups';
import {ApplicationContext} from 'src/context';
import AddAction from '../AddAction';
import AddOption from '../AddOption';
import AddValidation from '../AddValidation';

import Expression from '../Expression';
import WorkFlowModal from 'src/shared/components/Global/WorkFlow';
import {AddInputActionsModal} from '../AddInputActionsModal';
import {Box, Tab, Tabs} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {applicationTheme} from 'src/shared/theme';
import AddSequenceDiagram from '../AddSequenceDiagram';

type Props = {
	widget: IComponent | undefined;
	setProperty: (props: any) => void;
	saveApplicationState: () => void;
};

const PropertyContent = ({widget, setProperty, saveApplicationState}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [controlState, setControlState] = useState<any>({});
	const [isOpenAddAction, setIsOpenAddAction] = useState(false);
	const [isOpenAddOption, setIsOpenAddOption] = useState(false);
	const [isOpenValidation, setIsOpenValidation] = useState(false);
	const [isOpenExpression, setIsOpenExpression] = useState(false);
	const [isOpenWorkFlow, setIsOpenWorkFlow] = useState(false);
	const [isOpenInputActions, setIsOpenInputActions] = useState(false);
	const [colorX, setColorX] = useState(0);
	const [colorY, setColorY] = useState(0);
	const [workFlowItem, setWorkFlowItem] = useState<IWorkFlowItem[]>([]);
	const [activeTab, setActiveTab] = useState(0);
	const [isBackEndSequenceDiagram, setIsBackEndSequenceDiagram] = useState(false);
	const [isOpenAddSequenceDiagram, setIsOpenAddSequenceDiagram] = useState(false);
	const [oldColor, setOldColor] = useState('');

	useEffect(() => {
		const data: any = {};
		const properties = Object.keys(widget?.properties ?? {});

		if (properties.includes('textColor'))
			data['textColorStateColor'] = widget?.properties?.textColor;
		if (properties.includes('background'))
			data['backgroundStateColor'] = widget?.properties?.background;

		setControlState(data);
	}, [widget]);

	const onSetProperty = (prop: string, value: any) => {
		const props = {...widget?.properties, [prop]: value};
		setProperty(props);
	};

	const onSetControlState = (prop: string, value: any) => {
		const state = {...controlState};
		state[prop] = value;
		setControlState(state);
		return state;
	};

	const changeViewFunctionBar = (hide: boolean) => {
		setApplication({...application, hideFuncionBar: hide});
	};

	const isSequenceDiagramEnabled = (isControl: boolean) => {
		if (isControl) {
			if (
				widget?.hasOwnProperty('inputActions') === true &&
				widget?.inputActions?.hasOwnProperty('length') === true
			) {
				const actions: IInputAction[] = widget?.inputActions;
				return actions?.some(
					x =>
						x?.hasOwnProperty('workflow') === true &&
						x?.workflow?.hasOwnProperty('length') === true &&
						x?.workflow?.length > 0,
				);
			} else {
				return false;
			}
		} else {
			return (
				widget?.hasOwnProperty('actions') === true &&
				widget?.actions?.hasOwnProperty('length') === true &&
				widget?.actions?.length > 0
			);
		}
	};

	const renderProps = (prop: string) => {
		if (widget?.options[prop] === 'string') {
			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<S.ValueInput>
						<Input
							value={widget.properties ? widget.properties[prop] : ''}
							onChange={(value: string) => onSetProperty(prop, value)}
							border="1px solid #ccc"
							background="transparent"
							textColor={applicationTheme.gray[800]}
							mask={prop === 'name' ? 'Sem espaço' : undefined}
						/>
					</S.ValueInput>
				</S.Item>
			);
		}

		if (widget?.options[prop] === 'boolean') {
			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<Switch
						isOn={widget.properties ? widget.properties[prop] : false}
						handleToggle={() =>
							onSetProperty(prop, widget.properties ? !widget.properties[prop] : true)
						}
					/>
				</S.Item>
			);
		}

		if (widget?.options[prop] === 'icon') {
			const selectedIcon = getListIcons().find(
				x => x.icon?.iconName == widget.properties[prop]?.iconName,
			);
			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<S.ValueInput>
						<SelectIcon
							value={selectedIcon}
							onChange={(value: any) => onSetProperty(prop, value.icon)}
							background="transparent"
							border="1px solid #ccc"
							textColor={applicationTheme.gray[800]}
						/>
					</S.ValueInput>
				</S.Item>
			);
		}

		if (widget?.options[prop].includes(',')) {
			const options = widget?.options[prop].split(',')?.map((item: string) => {
				return {
					value: item,
					label: item,
				};
			});

			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<S.ValueInput>
						<Select
							options={options}
							value={
								widget.properties
									? options.find((x: any) => x.value === widget.properties[prop])
									: {label: 'Selecione...', value: undefined}
							}
							onChange={(value: any) => onSetProperty(prop, value.value)}
							background="transparent"
							border="1px solid #ccc"
							textColor={applicationTheme.gray[800]}
						/>
					</S.ValueInput>
				</S.Item>
			);
		}

		if (widget?.options[prop] === 'color') {
			const propState = `${prop}StateColorPicker`;
			const stateColor = `${prop}StateColor`;

			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<S.ValueInput>
						<S.ColorButton
							background={controlState[stateColor]}
							onClick={e => {
								onSetControlState(propState, !controlState[propState]);
								setColorX(e.pageX - 500);
								setColorY(e.pageY);
								setOldColor(controlState[stateColor]);
							}}
						/>
						{controlState[propState] ? (
							<S.PickerContent top={colorY} left={colorX}>
								<PhotoshopPicker
									color={controlState[stateColor]}
									onChangeComplete={color => {
										onSetControlState(stateColor, color.hex);
										onSetProperty(prop, color.hex);
									}}
									onAccept={closeColorPicker}
									onCancel={() => {
										const resultState = onSetControlState(stateColor, oldColor);
										onSetProperty(prop, oldColor);
										closeColorPicker(resultState);
									}}
								/>
							</S.PickerContent>
						) : (
							<></>
						)}
					</S.ValueInput>
				</S.Item>
			);
		}

		if (widget?.options[prop] === 'action') {
			return (
				<>
					<Button
						text="Eventos"
						leftIcon2="plus-square"
						onClick={() => {
							setIsOpenAddAction(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
					/>
					<SizedBox height="5px" />
					<Button
						text="Diagrama"
						leftIcon2="plus-square"
						onClick={() => {
							setIsBackEndSequenceDiagram(true);
							setIsOpenAddSequenceDiagram(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
						disabled={!isSequenceDiagramEnabled(false)}
					/>
					<SizedBox height="5px" />
				</>
			);
		}

		if (widget?.options[prop] === 'options') {
			return (
				<>
					<Button
						text="Customizações"
						leftIcon2="plus-square"
						onClick={() => {
							setIsOpenAddOption(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
					/>
					<SizedBox height="5px" />
				</>
			);
		}

		if (widget?.options[prop] === 'number') {
			return (
				<S.Item>
					<S.Label>{prop}</S.Label>
					<S.ValueInput>
						<Input
							value={widget.properties ? widget.properties[prop] : ''}
							onChange={(value: string) => onSetProperty(prop, value)}
							border="1px solid #ccc"
							background="transparent"
							textColor={applicationTheme.gray[800]}
							mask="Numero"
						/>
					</S.ValueInput>
				</S.Item>
			);
		}
	};

	const renderValidation = () => {
		if (
			COMPONENT_VALIDATION.includes(
				(widget?.name as ComponentSimpleEnum) ?? ComponentSimpleEnum.Default,
			)
		) {
			return (
				<Button
					text="Validações"
					leftIcon2="plus-square"
					onClick={() => {
						setIsOpenValidation(true);
						saveApplicationState();
					}}
					background={applicationTheme.brand[50]}
					textColor={applicationTheme.brand[600]}
					height="32px"
					borderRadius="3px"
				/>
			);
		}

		return '';
	};

	const renderInputActions = () => {
		if (
			COMPONENT_VALIDATION.includes(
				(widget?.name as ComponentSimpleEnum) ?? ComponentSimpleEnum.Default,
			)
		) {
			return (
				<>
					<SizedBox height="10px" />
					<Button
						text="Eventos"
						leftIcon2="plus-square"
						onClick={() => {
							setIsOpenInputActions(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
					/>
					<SizedBox height="10px" />
					<Button
						text="Diagrama"
						leftIcon2="plus-square"
						onClick={() => {
							setIsBackEndSequenceDiagram(false);
							setIsOpenAddSequenceDiagram(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
						disabled={!isSequenceDiagramEnabled(true)}
					/>
				</>
			);
		}

		return '';
	};

	const renderExpression = () => {
		if (
			COMPONENT_VALIDATION.includes(
				(widget?.name as ComponentSimpleEnum) ?? ComponentSimpleEnum.Default,
			)
		) {
			return (
				<>
					<SizedBox height="10px" />
					<Button
						text="Expressões"
						leftIcon2="plus-square"
						onClick={() => {
							setIsOpenExpression(true);
							saveApplicationState();
						}}
						background={applicationTheme.brand[50]}
						textColor={applicationTheme.brand[600]}
						height="32px"
						borderRadius="3px"
					/>
				</>
			);
		}

		return '';
	};

	const closeColorPicker = (resultState?: any) => {
		const newState =
			!resultState || resultState.type === 'click' ? {...controlState} : {...resultState};
		const keys = Object.keys(newState);
		let changed = false;
		keys.forEach(key => {
			if (key.includes('StateColorPicker') && !!newState[key]) {
				newState[key] = false;
				changed = true;
			}
		});

		if (changed) setControlState(newState);
	};

	const render = (keys: string[]) => {
		const excludedProps = ['pressEnter'];
		const configuration = keys
			.filter(key => !excludedProps.includes(key))
			.filter(key => getWidgetPropertyGroup(key) == WidgetPropertyTypes.Configuration);
		const appearance = keys
			.filter(key => !excludedProps.includes(key))
			.filter(key => getWidgetPropertyGroup(key) == WidgetPropertyTypes.Style);

		return (
			<>
				<Box>
					<Tabs
						value={activeTab}
						onChange={(_, newValue) => setActiveTab(newValue)}
						TabIndicatorProps={{style: {padding: '0px'}}}>
						<Tab
							iconPosition="start"
							label={WidgetPropertyTypes.Configuration}
							icon={<FeatherIcon icon="settings" />}
						/>
						<Tab
							iconPosition="start"
							label={WidgetPropertyTypes.Style}
							icon={<FeatherIcon icon="pen-tool" />}
						/>
					</Tabs>
				</Box>
				<div
					role="tabpanel"
					hidden={activeTab !== 0}
					id={`full-width-tabpanel-${0}`}
					aria-labelledby={`full-width-tab-${0}`}>
					{activeTab === 0 && (
						<Box sx={{p: 3}}>
							{[
								...configuration.map(item => renderProps(item)),
								renderValidation(),
								renderExpression(),
								renderInputActions(),
							]}
						</Box>
					)}
				</div>
				<div
					role="tabpanel"
					hidden={activeTab !== 1}
					id={`full-width-tabpanel-${1}`}
					aria-labelledby={`full-width-tab-${1}`}>
					{activeTab === 1 && (
						<Box sx={{p: 3}}>{appearance.map(item => renderProps(item))}</Box>
					)}
				</div>
			</>
		);
	};

	return (
		<S.Container>
			<div>
				{(function () {
					if (!widget || !widget.options) return <></>;

					return <>{render(Object.keys(widget?.options))}</>;
				})()}
			</div>

			<AddAction
				isOpen={isOpenAddAction}
				onClose={() => setIsOpenAddAction(false)}
				setIsOpenWorkFlow={setIsOpenWorkFlow}
				workFlowItem={workFlowItem}
				setWorkFlowItem={setWorkFlowItem}
			/>
			<AddOption isOpen={isOpenAddOption} onClose={() => setIsOpenAddOption(false)} />
			<AddValidation
				isOpen={isOpenValidation}
				onClose={() => setIsOpenValidation(false)}
				selectedWidget={widget!}
			/>
			<Expression isOpen={isOpenExpression} onClose={() => setIsOpenExpression(false)} />
			<WorkFlowModal
				isOpen={isOpenWorkFlow}
				onClose={() => {
					setIsOpenWorkFlow(false);
					changeViewFunctionBar(false);
				}}
				workFlowItem={workFlowItem}
				setWorkFlowItem={setWorkFlowItem}
			/>
			<AddInputActionsModal
				isOpen={isOpenInputActions}
				onClose={() => setIsOpenInputActions(false)}
			/>
			<AddSequenceDiagram
				isBackEnd={isBackEndSequenceDiagram}
				isOpen={isOpenAddSequenceDiagram}
				onClose={() => setIsOpenAddSequenceDiagram(false)}
			/>
		</S.Container>
	);
};

export default PropertyContent;

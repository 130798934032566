/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import * as S from './styles';
import {Button, Wrapper, Text, WidthBox, Switch, SizedBox} from 'src/shared/components/UI';
import ApplicationContext from 'src/context/ApplicationContext';
import {IColumn, ICustomMapVars, IDatabaseRule, IOption, IUserMapping} from 'src/@types/app';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';

export const RenderVariables = () => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [mapping, setMapping] = useState<IUserMapping[]>([]);
	const [tables, setTables] = useState<IOption[]>([]);
	const [columns, setColumns] = useState<IOption[]>([]);
	const [customMap, setCustomMap] = useState<ICustomMapVars[]>([]);
	const [dataOptions, setDataOptions] = useState<IOption[]>([]);

	useEffect(() => {
		const table = application?.database?.tables?.find(x => x.isUser);
		if (!table) return;
		const data: IUserMapping[] = [];
		const allowColumns: IColumn[] = [];

		table.columns?.forEach(column => {
			let included = true;
			application?.databaseRules?.forEach(rule => {
				if (column.name?.toUpperCase() == rule.findedName?.toUpperCase()) {
					included = false;
				}
			});
			if (column.constraint) included = false;

			if (included) allowColumns.push(column);
		});

		allowColumns?.forEach(column => {
			const savedMapping = application?.userConfiguration?.mapping?.find(
				x => x.columnRef === column.ref,
			);
			const isEnv = savedMapping ? savedMapping?.isEnvironment : false;
			const isLogin = savedMapping ? savedMapping?.isLogin : false;
			const isPassword = savedMapping ? savedMapping?.isPassword : false;

			data.push({
				columnName: backFormatPropertyName(column.name ?? ''),
				columnRef: column.ref,
				isEnvironment: isEnv,
				isLogin: isLogin,
				isPassword: isPassword,
			});
		});

		setMapping(data);

		const userConstraintsRefs = table.columns
			?.filter(column => column.constraint)
			?.map(x => x.ref);
		application?.database?.tables?.find(x => userConstraintsRefs?.includes(x.ref));

		setCustomMap([
			{
				ref: crypto.randomUUID(),
			},
		]);
	}, []);

	useEffect(() => {
		setApplication({
			...application,
			userConfiguration: {
				...application?.userConfiguration,
				mapping,
			},
		});
	}, [mapping]);

	useEffect(() => {
		if (!customMap || customMap.length == 0 || !application) return;
		const lastItem = customMap[(customMap?.length ?? 1) - 1];
		const subLastItem =
			customMap.length > 1 ? customMap[(customMap?.length ?? 1) - 2] : undefined;

		if (subLastItem && subLastItem.column && subLastItem.column.custom?.ident) {
			const findedTable = application?.database?.tables?.find(
				table => table.ref === subLastItem.column?.custom?.ident,
			);
			setTables([
				{
					value: findedTable?.ref,
					label: findedTable?.name ?? '',
				},
			]);
		} else {
			const findedTable = application?.database?.tables?.find(table => table.isUser);
			const constraintColumns = findedTable?.columns?.filter(column => column.constraint);
			const directTables = constraintColumns?.map(column =>
				application?.database?.tables?.find(table => table.ref === column.constraint),
			);
			const otherTables = application.database?.tables?.filter(tab =>
				tab.columns?.find(col => col.constraint && col.constraint == findedTable?.ref),
			);
			const tables = [...(directTables ?? []), ...(otherTables ?? [])];
			setTables(
				tables?.map(table => ({
					value: table?.ref ?? '',
					label: table?.name ?? '',
				})) ?? [],
			);
		}

		if (lastItem.table) {
			const columns = application.database?.tables?.find(
				table => table.ref === lastItem.table?.value,
			)?.columns;

			setColumns(
				columns?.map(column => ({
					value: column.ref ?? '',
					label: column.name ?? '',
					custom: {
						ident: column.constraint,
					},
				})) ?? [],
			);
		}
	}, [customMap]);

	const addCustomData = () => {
		const data = [...(application?.userConfiguration?.mapCustom ?? [])];
		data.push({
			ref: crypto.randomUUID(),
			customMapVars: customMap,
		});
		setApplication({
			...application,
			userConfiguration: {...application?.userConfiguration, mapCustom: data},
		});
		setCustomMap([
			{
				ref: crypto.randomUUID(),
			},
		]);
	};

	const addMap = () => {
		const data = [...customMap];
		data.push({
			ref: crypto.randomUUID(),
		});
		setCustomMap(data);
	};

	const removeMap = (ref: string) => {
		const data = [...customMap].filter(x => x.ref != ref);
		setCustomMap(data);
	};

	const removeData = (item: any) => {
		setApplication({
			...application,
			userConfiguration: {
				...application?.userConfiguration,
				mapCustom:
					application?.userConfiguration?.mapCustom?.filter(x => x.ref != item.ref) ?? [],
			},
		});
	};

	const getLast = (data: ICustomMapVars[]): ICustomMapVars => {
		return data[data.length - 1];
	};

	const build = () => {
		return mapping?.map((current, index) => (
			<Wrapper key={index}>
				<WidthBox width="40%">
					<Text text={current.columnName} fontWeight="bold" fontSize="14px" />
				</WidthBox>
				<WidthBox width="20%">
					<Switch
						isOn={current.isLogin}
						handleToggle={() =>
							setMapping(
								[...mapping].map(x => {
									if (x.columnRef === current.columnRef) x.isLogin = !x.isLogin;
									return x;
								}),
							)
						}
						disabled={mapping.find(x => x.isLogin) && !current.isLogin}
					/>
				</WidthBox>
				<WidthBox width="20%">
					<Switch
						isOn={current.isPassword}
						handleToggle={() =>
							setMapping(
								[...mapping].map(x => {
									if (x.columnRef === current.columnRef)
										x.isPassword = !x.isPassword;
									return x;
								}),
							)
						}
						disabled={mapping.find(x => x.isPassword) && !current.isPassword}
					/>
				</WidthBox>
				<WidthBox width="20%">
					<Switch
						isOn={current.isEnvironment}
						handleToggle={() =>
							setMapping(
								[...mapping].map(x => {
									if (x.columnRef === current.columnRef)
										x.isEnvironment = !x.isEnvironment;
									return x;
								}),
							)
						}
					/>
				</WidthBox>
			</Wrapper>
		));
	};

	const buildCustomMap = () => {
		return customMap?.map((cm, key) => (
			<Wrapper key={key}>
				<WidthBox width="45%">
					<SystemSelect
						value={cm.table}
						options={tables}
						onChange={val => {
							const data = [...customMap].map(i => {
								if (i.ref === cm.ref) {
									i.table = val;
								}
								return i;
							});
							setCustomMap(data);
						}}
						label="Tabela"
					/>
				</WidthBox>
				<WidthBox width="45%">
					<SystemSelect
						value={cm.column}
						options={columns}
						onChange={val => {
							const data = [...customMap].map(i => {
								if (i.ref === cm.ref) {
									i.column = val;
								}
								return i;
							});
							setCustomMap(data);
						}}
						label="Campo"
					/>
				</WidthBox>
				<Wrapper width="auto" margin="21px 0 0 0" gap="5px">
					<Button
						icon2={'plus'}
						onClick={addMap}
						fill="auto"
						textColor={applicationTheme.brand[600]}
						background={applicationTheme.brand[100]}
						borderRadius="4px"
						disabled={
							key + 1 != customMap.length ||
							!cm.table ||
							!cm.column ||
							!cm.column.custom?.ident
						}
					/>
					<Button
						icon2={'x'}
						onClick={() => removeMap(cm.ref ?? '')}
						borderRadius="4px"
						fill="auto"
						textColor={applicationTheme.brand[600]}
						background={applicationTheme.brand[100]}
						disabled={key + 1 != customMap.length || key == 0}
					/>
				</Wrapper>
			</Wrapper>
		));
	};

	useEffect(() => {
		const data: IOption[] = [];
		data.push({
			label: 'Data Corrente',
			value: 'Date',
		});

		application?.userConfiguration?.mapping?.forEach(item => {
			data.push({
				label: item.columnName ?? '',
				value: item.columnRef,
			});
		});

		setDataOptions(data);
	}, []);

	const render = (databaseRule: IDatabaseRule) => {
		return (
			<Wrapper margin="0 0 10px 0" key={databaseRule.ref}>
				<WidthBox width="50%">
					<Text text={databaseRule.findedName} fontWeight="bold" fontSize="14px" />
				</WidthBox>
				<WidthBox width="50%">
					<SystemSelect
						value={dataOptions.find(
							x =>
								x.value ===
								application?.userConfiguration?.mapVars?.find(
									y => y.ruleRef === databaseRule.ref,
								)?.columnRef,
						)}
						options={dataOptions}
						onChange={value => {
							let mapVars = [...(application?.userConfiguration?.mapVars ?? [])];

							if (mapVars.find(x => x.ruleRef === databaseRule.ref)) {
								mapVars = mapVars.map(x => {
									if (x.ruleRef === databaseRule.ref) x.columnRef = value.value;
									return x;
								});
							} else {
								mapVars.push({
									ruleRef: databaseRule.ref,
									columnRef: value.value,
								});
							}

							setApplication({
								...application,
								userConfiguration: {
									...application?.userConfiguration,
									mapVars,
								},
							});
						}}
					/>
				</WidthBox>
			</Wrapper>
		);
	};

	return (
		<S.Content>
			<S.Tag>
				<div className="text">
					Configuração do usuário, variáveis de contexto e autenticação
				</div>
			</S.Tag>
			<S.Card>
				<>
					{!mapping || mapping.length == 0 ? (
						<Wrapper justifyContent="center">
							<S.NoDataInfo>
								<br />
								<span>
									Você deve selecionar uma tabela para corresponder ao usuário
									para poder ter acesso a esta etapa!
								</span>
								<br />
								<br />
							</S.NoDataInfo>
						</Wrapper>
					) : (
						<>
							<Wrapper>
								<WidthBox width="40%">
									<></>
								</WidthBox>
								<WidthBox width="20%">
									<Text text="Login" color={'#000'} />
								</WidthBox>
								<WidthBox width="20%">
									<Text text="Senha" color={'#000'} />
								</WidthBox>
								<WidthBox width="20%">
									<Text text="Variável de ambiente" color={'#000'} />
								</WidthBox>
							</Wrapper>
							{build()}
						</>
					)}

					<hr />
					<Wrapper alignItems="start" justifyContent="flex-start" margin="0 0 10px 0">
						<Text text="Variáveis customizadas" fontWeight="bold" />
					</Wrapper>
					{buildCustomMap()}
					{customMap && customMap.length > 0 ? (
						<Wrapper alignItems="end" justifyContent="end">
							<WidthBox margin="5px 0 0 0" width="auto">
								<Button
									icon2={'plus'}
									onClick={addCustomData}
									fill="auto"
									borderRadius="4px"
									textColor={applicationTheme.brand[600]}
									background={applicationTheme.brand[100]}
									disabled={!customMap || !customMap.find(x => x.column)}
								/>
							</WidthBox>
						</Wrapper>
					) : (
						<></>
					)}

					<SizedBox height="10px" />

					{application?.userConfiguration?.mapCustom &&
					application?.userConfiguration?.mapCustom?.length > 0 ? (
						<>
							<SystemDataGrid
								columns={[
									{
										flex: 0.1,
										minWidth: 90,
										type: 'actions',
										filterable: false,
										hideable: false,
										headerAlign: 'left',
										align: 'left',
										sortable: false,
										field: 'actions',
										headerName: 'Ações',
										renderCell: ({row}: any) => (
											<SystemRowOptions
												childrens={
													<>
														<MenuItem
															onClick={() => removeData(row)}
															sx={{'& svg': {mr: 2}}}>
															<FeatherIcon icon="trash" size={16} />
															<Text text="Excluir" />
														</MenuItem>
													</>
												}
											/>
										),
									},
									{
										flex: 0.5,
										type: 'string',
										minWidth: 150,
										filterable: true,
										headerAlign: 'left',
										align: 'left',
										sortable: true,
										field: 'name',
										headerName: `Propriedade`,
										renderCell: ({row}: any) => row.name,
									},
								]}
								rowsData={application?.userConfiguration?.mapCustom?.map(item => ({
									name: `${getLast(item.customMapVars).table?.label}.${
										getLast(item.customMapVars).column?.label
									}`,
									ref: item.ref,
								}))}
							/>
						</>
					) : (
						<></>
					)}
				</>
			</S.Card>

			<SizedBox height="25px" />

			<S.Tag>
				<div className="text">Configuração de mapeamento das variáveis</div>
			</S.Tag>
			<S.Card>
				{!application?.databaseRules || application?.databaseRules.length == 0 ? (
					<Wrapper justifyContent="center">
						<S.NoDataInfo>
							<br />
							<span>
								Deve conter mapeado variáveis de ambiente e regras de exclusão
							</span>
							<br />
							<br />
						</S.NoDataInfo>
					</Wrapper>
				) : (
					<>{application?.databaseRules.map(rule => render(rule))}</>
				)}
			</S.Card>
		</S.Content>
	);
};

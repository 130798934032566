/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {useContext, useEffect, useState} from 'react';
import {Modal} from 'src/shared/components/Complex';
import {Button, Wrapper, SizedBox} from 'src/shared/components/UI';
import * as S from './styles';
import {faArrowUp, faArrowDown, faClose, faHand} from '@fortawesome/free-solid-svg-icons';
import {IComponent, ITreeView} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import {ComponentSimpleEnum, ComponentTypeEnum} from 'src/@types/enums';
import {applicationTheme} from 'src/shared/theme';

enum MovePosition {
	TOP = 1,
	BOTTOM = 2,
}

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

const Ordination = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [treeviewState, setTreeviewState] = useState<ITreeView[]>([]);
	const [ordenationDic, setOrdenationDic] = useState<any>({});
	const [disabledTop, setDisabledTop] = useState(true);
	const [disabledBottom, setDisabledBottom] = useState(true);
	const [moveComp, setMoveComp] = useState(false);
	const [moveItem, setMoveItem] = useState<any>();

	useEffect(() => {
		renderComponents();
	}, [application]);

	useEffect(() => {
		checkEnableButton();
	}, [treeviewState, moveComp]);

	useEffect(() => {
		renderComponents(moveComp);
	}, [moveComp]);

	const close = () => {
		onClose();
	};

	// const getWidgetsData = (): IComponent[] =>
	// 	(application.resources?.find(x => x.selected)?.widgets ?? []).sort((a, b) => {
	// 		if (a.parentRef == b.parentRef) return 0;
	// 		if (!a.parentRef) return -1;
	// 		if (!b.parentRef) return 1;
	// 		return 0;
	// 	});

	const getWidgetsData = (): IComponent[] => {
		const widgets = application.resources?.find(x => x.selected)?.widgets ?? [];

		const data = widgets.map(widget => {
			if (!widget.parentRef) widget.parentLevel = 0;
			else widget.parentLevel = calcParentLevel(widgets, widget.parentRef ?? '');
			return widget;
		});

		return data.sort((a, b) => a.parentLevel! - b.parentLevel!);
	};

	const calcParentLevel = (widgets: IComponent[], parentRef: string, level = 1): number => {
		const parentWidget = widgets.find(x => x.ref === parentRef);
		if (!parentWidget?.parentRef) return level;
		level = level + 1;
		return calcParentLevel(widgets, parentWidget.parentRef, level);
	};

	const dataToArray = (dataArray: ITreeView[], nextData?: ITreeView[]): ITreeView[] => {
		(nextData ?? treeviewState).forEach(item => {
			dataArray.push({...item});
			if (item.children && item.children.length > 0) {
				dataArray = dataToArray(dataArray, item.children);
			}
		});

		dataArray.forEach(item => {
			item.children = [];
		});

		return dataArray;
	};

	const arrayToData = (data: ITreeView[]): ITreeView[] => {
		const newData: ITreeView[] = [];
		data.forEach(item => {
			buildDataToTreeview(item, newData);
		});

		return newData;
	};

	const buildTreeview = (
		widget: IComponent,
		data: ITreeView[],
		lastSelectItem?: ITreeView,
		setDisabled?: boolean,
	) => {
		const getData = (isChildrenSelected = false): ITreeView => ({
			widget: widget as IComponent,
			children: [],
			selected: widget.ref === lastSelectItem?.widget.ref ? lastSelectItem?.selected : false,
			disabled: setDisabled
				? (widget as IComponent).type != ComponentTypeEnum.Container || isChildrenSelected
				: false,
		});

		if (!widget.parentRef) data.push(getData());
		else {
			for (const item of data) {
				if (item.widget.ref === widget.parentRef)
					item.children.push(
						getData(item.widget.ref === lastSelectItem?.widget.ref && setDisabled),
					);
				else if (item.children.length > 0)
					buildTreeview(widget, item.children, lastSelectItem, setDisabled);
			}
		}
	};

	const buildDataToTreeview = (item: ITreeView, data: ITreeView[]) => {
		if (!item.widget.parentRef) data.push(item);
		else {
			for (const itemData of data) {
				if (itemData.widget.ref === item.widget.parentRef) itemData.children.push(item);
				else if (itemData.children.length > 0) buildDataToTreeview(item, itemData.children);
			}
		}
	};

	const isSelected = (ref: string): boolean => {
		let selected = false;
		dataToArray([]).forEach(x => {
			if (x.widget.ref === ref && x.selected) selected = true;
		});

		return selected;
	};

	const renderComponents = (setDisabled = false) => {
		const newTreeviewState: ITreeView[] = [];
		const newOrdernation: any = {};
		const selectItem = dataToArray([]).find(x => x.selected);

		getWidgetsData().forEach(widget => {
			buildTreeview(widget, newTreeviewState, selectItem, setDisabled);

			if (!widget.parentRef) newOrdernation[widget.ref!] = 0;
			else newOrdernation[widget.ref!] = newOrdernation[widget.parentRef!] + 1;
		});

		setOrdenationDic(newOrdernation);
		setTreeviewState(newTreeviewState);
	};

	const move = (position: MovePosition) => {
		const selectedItem = dataToArray([]).find(x => x.selected);
		const widgetsData = getWidgetsData();
		const listRefs = widgetsData
			.filter(x => x.parentRef === selectedItem?.widget.parentRef)
			.map(x => x.ref);
		const originIndex = widgetsData.findIndex(x => x.ref === selectedItem?.widget.ref);
		let targetIndex: number;

		if (position === MovePosition.BOTTOM) {
			const nextRef = listRefs[listRefs.indexOf(selectedItem?.widget.ref) + 1];
			targetIndex = widgetsData.findIndex(x => x.ref === nextRef);
		} else {
			const nextRef = listRefs[listRefs.indexOf(selectedItem?.widget.ref) - 1];
			targetIndex = widgetsData.findIndex(x => x.ref === nextRef);
		}

		const newWidgetData = [...widgetsData];
		const oldItem = newWidgetData.splice(targetIndex, 1, selectedItem!.widget);
		newWidgetData.splice(originIndex, 1, oldItem[0]);

		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) resource.widgets = newWidgetData;
				return resource;
			}),
		};

		setApplication(newApplication);
	};

	const getItemName = (widget: IComponent): string => {
		if (widget.name === ComponentSimpleEnum.Button) return ` - ${widget.properties.text}`;
		else if (widget.type === ComponentTypeEnum.UI) return ` - ${widget.properties.name}`;
		return '';
	};

	const componentRender = (data?: ITreeView[]) => {
		return (
			<div>
				{(data ?? treeviewState).map((item, index) => (
					<div key={index}>
						<S.Item
							marginLeft={ordenationDic[item.widget.ref!]}
							select={isSelected(item.widget.ref!)}
							onClick={item.disabled ? undefined : () => onSelect(item.widget.ref!)}
							disabled={item.disabled}>
							{item.widget.name} {getItemName(item.widget)}
						</S.Item>

						{(function () {
							if (item.children && item.children.length > 0) {
								return componentRender(item.children);
							}
						})()}
					</div>
				))}
			</div>
		);
	};

	const checkEnableButton = () => {
		const selectedItem = dataToArray([]).find(x => x.selected)?.widget;
		const listRefs = getWidgetsData()
			.filter(x => x.parentRef === selectedItem?.parentRef)
			.map(x => x.ref);
		const selectIndex = listRefs.indexOf(selectedItem?.ref);

		if (moveComp) {
			setDisabledTop(true);
			setDisabledBottom(true);
		} else if (listRefs.length === 1) {
			setDisabledTop(true);
			setDisabledBottom(true);
		} else if (selectIndex === 0) {
			setDisabledTop(true);
			setDisabledBottom(false);
		} else if (selectIndex === listRefs.length - 1) {
			setDisabledTop(false);
			setDisabledBottom(true);
		} else {
			setDisabledTop(false);
			setDisabledBottom(false);
		}
	};

	const onSelect = (ref: string | undefined) => {
		if (moveComp) {
			moveComponent(ref);
			return;
		}
		const data = dataToArray([]).map(x => {
			if (x.widget.ref === ref) {
				x.selected = !x.selected;
				setMoveItem(x.widget.ref);
			} else x.selected = false;
			return x;
		});
		setTreeviewState(arrayToData(data));
	};

	const moveComponent = (ref: string | undefined) => {
		const widgetsData = getWidgetsData();
		const newWidgetData = [...widgetsData].map(x => {
			if (x.ref === moveItem) x.parentRef = ref;
			return x;
		});

		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) resource.widgets = newWidgetData;
				return resource;
			}),
		};

		setApplication(newApplication);
		setMoveComp(false);
	};

	return (
		<Modal isOpen={isOpen} onClose={close} maxWidth="900px">
			<>
				{(function () {
					if (dataToArray([]).find(x => x.selected)) {
						return (
							<Wrapper gap="8px">
								<Button
									fill="auto"
									themeStyle="primary"
									icon2={'arrow-up'}
									onClick={() => move(MovePosition.TOP)}
									disabled={disabledTop}
									background={applicationTheme.brand[600]}
								/>
								<Button
									fill="auto"
									themeStyle="primary"
									icon2={'arrow-down'}
									onClick={() => move(MovePosition.BOTTOM)}
									disabled={disabledBottom}
									background={applicationTheme.brand[600]}
								/>
								<Button
									fill="auto"
									themeStyle="primary"
									icon2={'move'}
									onClick={() => setMoveComp(true)}
									disabled={moveComp === true}
									background={applicationTheme.brand[600]}
								/>
								{moveComp === true ? (
									<>
										{dataToArray([]).find(x => x.selected)?.widget.parentRef ? (
											<Button
												fill="auto"
												themeStyle="alert"
												text="Mover para fora"
												onClick={() => onSelect(undefined)}
												background={applicationTheme.brand[600]}
											/>
										) : (
											<></>
										)}

										<Button
											fill="auto"
											themeStyle="alert"
											icon2={'x'}
											onClick={() => setMoveComp(false)}
											background={applicationTheme.brand[600]}
										/>
									</>
								) : (
									<></>
								)}
							</Wrapper>
						);
					}
				})()}
				<SizedBox height="10px" />

				<S.ContentWidgets>{componentRender()}</S.ContentWidgets>
				<Wrapper margin="28px 0 0 0" justifyContent="end">
					<Button
						text="Cancelar"
						onClick={close}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
				</Wrapper>
			</>
		</Modal>
	);
};

export default Ordination;

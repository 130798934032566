/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IAction, IApiData, IWorkFlowItem} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import {ActionList, ActionListFront} from 'src/helpers/select-data/actionList';
import {
	getSelectedEffect,
	getSelectedWidget,
	getSubcomponentSelected,
	isEffect,
} from 'src/helpers/methods/app-methods';
import {complexTypeMapper} from 'src/helpers/methods/mapper';
import {v4 as uuidV4} from 'uuid';
import {ActionTypeEnum} from 'src/@types/enums';
import {getDataStateName} from 'src/helpers/methods/action-methods';
import {
	Line,
	WidthBox,
	Button,
	Wrapper,
	ModalTitle,
	SizedBox,
	Text,
} from 'src/shared/components/UI';
import {MenuItem} from '@mui/material';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import FeatherIcon from 'feather-icons-react';
import {Modal} from 'src/shared/components/Complex';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import * as S from './styles';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	openNext?: () => void;
	setIsOpenWorkFlow?: (value: boolean) => void;
	workFlowItem?: IWorkFlowItem[];
	setWorkFlowItem?: (value: IWorkFlowItem[]) => void;
	type?: 'back' | 'front';
};

interface CellType {
	row: IAction;
}

const AddAction = ({
	isOpen,
	onClose,
	openNext,
	setIsOpenWorkFlow,
	workFlowItem,
	setWorkFlowItem,
	type = 'back',
}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [actions, setActions] = useState<IAction[]>([]);
	const [btnAction, setBtnAction] = useState<IAction>();
	const [newAction, setNewAction] = useState(false);
	const [isOpenDelete, showIsOpenDelete] = useState(false);
	const [actionToDelete, setActionToDelete] = useState<IAction | null>(null);
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false); // Novo estado para modal de erro

	useEffect(() => {
		if (isEffect(application)) {
			setActions(getSelectedEffect(application)?.actions ?? []);
		} else {
			const selectedSubComponent = getSubcomponentSelected(application);
			setActions(getSelectedWidget(application, selectedSubComponent)?.actions ?? []);
		}
	}, [isOpen]);

	useEffect(() => {
		if (actions.length > 0) {
			save();
		}
	}, [actions]);

	const render = () => {
		return complexTypeMapper(btnAction?.actionType, {
			application,
			btnAction,
			setBtnAction,
			setIsOpenWorkFlow,
			workFlowItem,
			setWorkFlowItem,
		});
	};

	const handleDeleteClick = (item: IAction, event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setActionToDelete(item);
		showIsOpenDelete(true);
	};

	const close = () => {
		back();
		setBtnAction(undefined);
		setActions([]);
		onClose();
		if (isEffect(application) != null && openNext) openNext();
	};

	const saveAction = () => {
		if (!btnAction) return;
		let data = [...actions];
		const action = {...btnAction};

		// Define o nome da ação baseado na lista de tipos
		action.actionName = (type === 'back' ? ActionList : ActionListFront).find(
			x => x.value === action.actionType,
		)?.label;

		// Função para verificar duplicidade com base nos tipos específicos de ações
		const isDuplicateAction = () => {
			switch (action.actionType) {
				case ActionTypeEnum.NextPage:
					return data.some(
						x =>
							x.actionType === ActionTypeEnum.NextPage &&
							x.targetRef === action.targetRef &&
							x.ref !== action.ref,
					);
				case ActionTypeEnum.DataGrid:
					return data.some(
						x =>
							x.actionType === ActionTypeEnum.DataGrid &&
							x.targetRef === action.targetRef &&
							x.ref !== action.ref,
					);
				case ActionTypeEnum.Api:
					return data.some(
						x =>
							x.actionType === ActionTypeEnum.Api &&
							x.api?.targetEndpoint === action.api?.targetEndpoint &&
							x.ref !== action.ref,
					);
				case ActionTypeEnum.WorkFlow:
					return data.some(
						x =>
							x.actionType === ActionTypeEnum.WorkFlow &&
							x.workflow?.taget === action.workflow?.taget &&
							x.ref !== action.ref,
					);
				default:
					return false;
			}
		};

		// Verifica duplicidade para o tipo de ação atual
		if (isDuplicateAction()) {
			setIsOpenErrorModal(true);
			return; // Impede a adição ou edição
		}

		// Atualiza ou adiciona nova ação
		if (action.ref) {
			// Atualiza a ação existente
			data = data.map(x => {
				if (x.ref === action.ref) return action;
				return x;
			});
		} else {
			// Adiciona uma nova ação
			action.ref = uuidV4().toString();
			data.push(action);
		}

		// Atualiza o estado com as ações modificadas
		setActions(data);
		back();
	};

	const save = () => {
		const dataActions = actions.filter(
			x =>
				(x.actionType === ActionTypeEnum.Api && x.api?.isData) ||
				x.actionType === ActionTypeEnum.Search,
		);

		const hasSelectComp = application.resources
			?.find(x => x.selected)
			?.widgets?.find(x => x.selected)
			? true
			: false;

		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					if (hasSelectComp) {
						resource.widgets = resource.widgets?.map(widget => {
							if (widget.selected) widget.actions = actions;
							return widget;
						});
					}

					const actionRefs = resource.apiData?.map(x => x.actionRef);
					const notAdd: IApiData[] = dataActions
						.filter(x => !actionRefs?.includes(x.ref))
						.map(x => {
							return {
								ref: uuidV4().toString(),
								actionRef: x.ref,
								targetApi: x.api?.targetApi,
								targetEndpoint: x.api?.targetEndpoint,
								name: getDataStateName(x, application),
								action: x,
							};
						});
					const updated = resource.apiData?.map(data => {
						dataActions.forEach(dataAc => {
							if (data.actionRef === dataAc.ref) {
								data.targetApi = dataAc.api?.targetApi;
								data.targetEndpoint = dataAc.api?.targetEndpoint;
								data.name = getDataStateName(dataAc, application);
								data.action = dataAc;
							}
						});
						return data;
					});

					resource.apiData = [...(updated ?? []), ...(notAdd ?? [])];
					if (isEffect(application)) {
						resource.effects = resource?.effects?.map(effect => {
							if (effect.ref === getSelectedEffect(application)?.ref) {
								effect.actions = actions;
							}
							return effect;
						});
					}
				}
				return resource;
			}),
		};

		setApplication(newApplication);
		//close();
	};

	const removeItem = (item: IAction) => {
		const updatedActions = actions.filter(x => x.ref !== item.ref);
		setActions(updatedActions);

		// Atualiza o estado `application` para refletir a nova lista de ações
		const newApplication = {
			...application,
			resources: application.resources?.map(resource => {
				if (resource.selected) {
					const hasSelectedWidget = resource.widgets?.some(widget => widget.selected);
					if (hasSelectedWidget) {
						resource.widgets = resource.widgets?.map(widget => {
							if (widget.selected) {
								widget.actions = updatedActions; // Atualiza as ações do widget
							}
							return widget;
						});
					}
				}
				return resource;
			}),
		};

		setApplication(newApplication);
	};

	const editItem = (item: IAction) => {
		setNewAction(true);
		setBtnAction({...item});
	};

	const back = () => {
		setNewAction(false);
		setBtnAction(undefined);
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} width="50%" maxWidth="900px">
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={close}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Adicionar eventos" />

					<>
						<SystemDataGrid
							rowsData={actions}
							columns={[
								{
									flex: 0.1,
									minWidth: 90,
									type: 'actions',
									filterable: false,
									hideable: false,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									field: 'actions',
									headerName: '',
									renderCell: ({row}: CellType) => (
										<SystemRowOptions
											childrens={
												<>
													<MenuItem
														onClick={() => editItem(row)}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="edit-3" size={16} />
														<Text text="Editar" />
													</MenuItem>
													<Wrapper
														alignItems="center"
														justifyContent="center">
														<WidthBox width="90%">
															<Line
																height="0.5px"
																background={
																	applicationTheme.gray[300]
																}
															/>
														</WidthBox>
													</Wrapper>
													<MenuItem
														onClick={event =>
															handleDeleteClick(row, event)
														}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="trash-2" size={16} />
														<Text
															text="Excluir"
															color={applicationTheme.error[900]}
														/>
													</MenuItem>
												</>
											}
										/>
									),
								},
								{
									flex: 0.5,
									type: 'string',
									minWidth: 150,
									filterable: true,
									headerAlign: 'left',
									align: 'left',
									sortable: true,
									field: 'actionName',
									headerName: `Evento`,
									renderCell: ({row}: CellType) => row.actionName,
								},
							]}
						/>

						{newAction ? (
							<>
								<SizedBox height="10px" />
								<S.Content>
									<SystemSelect
										value={(type == 'back' ? ActionList : ActionListFront).find(
											x => x.value === btnAction?.actionType,
										)}
										options={type == 'back' ? ActionList : ActionListFront}
										onChange={value =>
											setBtnAction({
												...(btnAction ?? {}),
												actionType: value?.value,
											})
										}
										label="Tipo"
										labelColor="#fff"
									/>
								</S.Content>

								{render()}

								<Wrapper margin="20px 0 0 0" justifyContent="center">
									<Button
										onClick={saveAction}
										text="Adicionar"
										leftIcon2="plus-square"
										fill="auto"
										textColor={applicationTheme.brand[600]}
										background={applicationTheme.brand[100]}
										height="41px"
									/>
								</Wrapper>
							</>
						) : (
							<Wrapper justifyContent="center" margin="10px 0 0 0">
								<Button
									onClick={() => setNewAction(true)}
									text="Adicionar novo evento"
									leftIcon2="plus-square"
									fill="auto"
									textColor={applicationTheme.brand[600]}
									background={applicationTheme.brand[100]}
									height="41px"
								/>
							</Wrapper>
						)}

						<Wrapper margin="20px 0 0 0" justifyContent="space-between">
							<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
								<Button
									text="Cancelar"
									leftIcon2="x"
									onClick={close}
									fill="auto"
									background={applicationTheme.gray[300]}
									textColor={applicationTheme.gray[700]}
									type="ghost"
								/>
							</div>
						</Wrapper>
					</>
				</>
			</Modal>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar evento?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={() => {
								if (actionToDelete) {
									removeItem(actionToDelete);
									showIsOpenDelete(false);
								}
							}}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<Modal
				isOpen={isOpenErrorModal}
				onClose={() => setIsOpenErrorModal(false)}
				width="auto">
				<>
					<ModalTitle
						title="Registro duplicado"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-triangle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Já existe um evento com esse tipo de ação. <br />
						Escolha outro tipo de ação.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="center">
						<Button
							text="OK"
							onClick={() => setIsOpenErrorModal(false)} // Fechar modal de erro
							fill="full"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

export default AddAction;

/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useState} from 'react';
import * as S from './styles';
import FeatherIcon from 'feather-icons-react';
import FolderImage from 'src/assets/images/folder.svg';
import {IResourceFolder} from 'src/@types/app';
import {ApplicationContext, LoadingContext} from 'src/context';
import {ApplicationApi} from 'src/shared/repositories/application-api';
import AddFolder from '../../components/AddFolder';
import {Unstable_Popup as BasePopup} from '@mui/base/Unstable_Popup';
import {Button, ModalTitle, Wrapper, WidthBox, Line} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';
import {Modal} from 'src/shared/components/Complex';

type Props = {
	item: IResourceFolder;
	name: string;
	onClick?: () => void;
	dragDropConfig?: any;
};

export const FolderItem = ({item, name, onClick, dragDropConfig}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [, setLoading] = useContext(LoadingContext);

	const [anchor, setAnchor] = useState<null | HTMLElement>(null);
	const [showAddFolder, setShowAddFolder] = useState(false);
	const [itemSelected, setItemSelected] = useState<IResourceFolder>();
	const [isOpenDelete, showIsOpenDelete] = useState(false);

	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		setAnchor(anchor ? null : event.currentTarget);
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>) => {
		// Previne a propagação do evento para evitar que onClick no S.Content seja acionado
		event.stopPropagation();
		showIsOpenDelete(true);
	};

	const open = Boolean(anchor);
	const id = open ? 'simple-popper' : undefined;
	const applicationApi = new ApplicationApi();

	const onRemove = async (e: any) => {
		handleClick(e);
		try {
			//setLoading(true);
			const folder = application.folders?.find(x => x.ref === item.ref);

			if (folder) {
				await applicationApi.updateFolder({
					id: application.id!,
					folders: JSON.stringify(application.folders?.filter(x => x.ref != folder.ref!)),
				});
			}
		} finally {
			const app = await applicationApi.getResources(application);
			setApplication(app!);
			//setLoading(false);
		}
	};

	const onEdit = (e: any) => {
		handleClick(e);
		const folder = application.folders?.find(x => x.ref === item.ref);

		setItemSelected(item);
		if (folder) setShowAddFolder(true);
	};

	return (
		<>
			<S.Content onClick={onClick} {...dragDropConfig}>
				<S.Principal>
					<img className="img" src={FolderImage} height="30px" />
					<div className="name">{name}</div>
				</S.Principal>
				<S.Second onClick={handleClick}>
					<FeatherIcon icon="more-vertical" className="icon" size={22} />
				</S.Second>
				<BasePopup id={id} open={open} anchor={anchor} placement="right-start" offset={5}>
					<S.PopUpContent>
						<S.PopBtn onClick={onEdit}>
							<FeatherIcon icon="edit" className="icon" size={16} />
							Editar
						</S.PopBtn>
						<Wrapper alignItems="center" justifyContent="center">
							<WidthBox width="90%">
								<Line height="0.5px" background={applicationTheme.gray[300]} />
							</WidthBox>
						</Wrapper>
						<S.PopBtn onClick={handleDeleteClick} isRemove>
							<FeatherIcon icon="trash-2" className="icon" size={16} />
							Excluir
						</S.PopBtn>
						<S.PopBtn onClick={handleClick} aria-describedby={id}>
							<FeatherIcon icon="x" className="icon" size={16} />
							Fechar
						</S.PopBtn>
					</S.PopUpContent>
				</BasePopup>
			</S.Content>

			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar módulo?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este módulo? <br />
						Essa ação não pode ser desfeita.
						{/* <b>{application.database?.tables?.find(x => x.selected)?.name}</b>? */}
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={onRemove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<AddFolder
				isOpen={showAddFolder}
				onClose={() => setShowAddFolder(false)}
				itemSelected={application.folders?.find(x => x.ref === itemSelected?.ref)}
			/>
		</>
	);
};

import React, {useContext, useEffect, useState} from 'react';
import {IComponent, IOption, IValidation} from 'src/@types/app';
import {ValidationTypeEnum} from 'src/@types/enums';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	InputDate,
	Text,
	ModalTitle,
	SizedBox,
	WidthBox,
	Wrapper,
	Line,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {
	DATE_INPUT_TYPE,
	getValidationList,
	NUMBER_INPUT_TYPE,
} from 'src/shared/validation/component-validation';
import {v4 as uuidV4} from 'uuid';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	selectedWidget: IComponent;
	onClose: () => void;
};

interface CellType {
	row: IValidation;
}

const AddValidation = ({isOpen, onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [editRef, setEditRef] = useState('');
	const [optionsData, setOptionsData] = useState<IOption[]>([]);
	const [validationType, setValidationType] = useState<IOption>();
	const [inputValue, setInputValue] = useState<any>();
	const [inputValue2, setInputValue2] = useState<any>();
	const [validationData, setValidationData] = useState<IValidation[]>([]);
	const [isValid, setIsValid] = useState(false);
	const [message, setMessage] = useState('');
	const [isOpenDelete, showIsOpenDelete] = useState(false);
	const [rowToDelete, setRowToDelete] = useState<IValidation | null>(null);
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false); // Novo estado para modal de erro
	const [isDuplicate, setIsDuplicate] = useState(false);

	useEffect(() => {
		if (selectedWidget && selectedWidget.name) {
			setOptionsData(getValidationList(selectedWidget));
			setValidationData(selectedWidget.valition ?? []);
		}
	}, [selectedWidget, application]);

	useEffect(() => {
		const initialData: IValidation[] = [];
		setValidationData(initialData);
	}, []);

	useEffect(() => {
		let isValidValue = false;
		if (validationType && validationType.value) {
			if (
				[
					ValidationTypeEnum.Required,
					ValidationTypeEnum.CnpjCpf,
					ValidationTypeEnum.Email,
					ValidationTypeEnum.Url,
				].includes(validationType.value) &&
				message.trim() !== ''
			) {
				isValidValue = true;
			} else if (
				validationType.value !== ValidationTypeEnum.Required &&
				inputValue &&
				!Number.isNaN(Number(inputValue)) &&
				Number(inputValue) > 0 &&
				message.trim() !== ''
			) {
				isValidValue = true;
			}
		}

		setIsValid(isValidValue);
	}, [validationType, message, inputValue]);

	const renderSubProps = () => {
		if (NUMBER_INPUT_TYPE.includes(validationType?.value as ValidationTypeEnum)) {
			return (
				<Wrapper margin="12px 0 0 0" width="50%">
					<SystemInput
						label="Quantidade"
						mask="Numero"
						value={inputValue}
						onChange={(value: string) => {
							// Converte o valor para número
							const numericValue = Number(value);
							// Verifica se é um número válido e maior que zero antes de atualizar o estado
							if (!isNaN(numericValue) && numericValue > 0) {
								setInputValue(numericValue);
							} else {
								setInputValue(''); // Se não for válido, limpa o valor
							}
						}}
					/>
				</Wrapper>
			);
		} else if (DATE_INPUT_TYPE.includes(validationType?.value as ValidationTypeEnum)) {
			return (
				<Wrapper margin="12px 0 0 0" width="100%">
					<WidthBox width="50%">
						<InputDate
							label="Data"
							value={inputValue}
							onChange={setInputValue}
							border="1px solid #333"
							inputType="light"
							placeholder="dd/mm/aaaa"
						/>
					</WidthBox>
					{validationType?.value === ValidationTypeEnum.Between ? (
						<WidthBox width="50%">
							<InputDate
								label="Data 2"
								value={inputValue2}
								onChange={setInputValue2}
								border="1px solid #333"
								inputType="light"
								placeholder="dd/mm/aaaa"
							/>
						</WidthBox>
					) : (
						<></>
					)}
				</Wrapper>
			);
		}
	};

	// Função de verificação de duplicidade
	const checkDuplicate = () => {
		const duplicate = validationData.some(
			item =>
				(item.value === inputValue || (!inputValue && !item.value)) && // Trata undefined e '' como equivalentes
				item.type === validationType?.value &&
				(!editRef || item.ref !== editRef),
		);
		setIsDuplicate(duplicate);
		return duplicate; // Retorne o valor para uso imediato
	};

	const addLine = () => {
		// Verifica duplicidade antes de adicionar
		if (checkDuplicate()) {
			setIsOpenErrorModal(true);
			return; // Se for duplicado, interrompe a execução
		}
		let data = [...validationData];
		const currItem = {
			ref: editRef || uuidV4().toString(),
			type: validationType?.value as ValidationTypeEnum,
			value: inputValue,
			value2: inputValue2,
			label: validationType?.label,
			message,
		};
		// Atualiza a linha se `editRef` existir
		if (editRef) {
			data = data.map(item => (item.ref === editRef ? currItem : item));
		} else {
			data.push(currItem);
		}
		setValidationData(data); // Atualiza o estado
		save(data);
	};

	useEffect(() => {
		checkDuplicate();
	}, [inputValue, validationType, validationData]);

	const handleEdit = (item: IValidation) => {
		setEditRef(item.ref);
		setMessage(item.message ?? '');
		if ([ValidationTypeEnum.GreatThan, ValidationTypeEnum.LessThan].includes(item.type)) {
			const date = new Date(Date.parse(item.value));
			setInputValue(date);
		} else if (item.type === ValidationTypeEnum.Between) {
			const date = new Date(Date.parse(item.value));
			const date2 = new Date(Date.parse(item.value2));

			setInputValue(date);
			setInputValue2(date2);
		} else {
			setInputValue(item.value);
		}
		setValidationType(optionsData.find(x => x.value === item.type));
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, item: IValidation) => {
		event.stopPropagation();
		setRowToDelete(item);
		showIsOpenDelete(true);
	};

	const save = (data: IValidation[]) => {
		const newApplication = {
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.widgets = resource.widgets?.map(widget => {
						if (widget.ref === selectedWidget.ref) {
							widget.valition = data; // Atualiza a validação
						}
						return widget;
					});
				}
				return resource;
			}),
		};

		setApplication(newApplication);
		console.log('Aplicação atualizada com validações:', newApplication); // Debug: Verifique a atualização
	};

	// Remover linha
	const removeLine = (row: IValidation) => {
		const data = [...validationData].filter(x => x.ref !== row.ref);
		setValidationData(data);
		setEditRef('');
		showIsOpenDelete(false);
		save(data);
	};

	const close = () => {
		onClose();
		setInputValue('');
		setValidationType(undefined);
		//setValidationData([]);
		setEditRef('');
		setIsDuplicate(false); // Reset duplicidade ao fechar
	};

	if (!optionsData) return <></>;

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} maxWidth="800px">
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={close}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Criar validações" />
					<>
						<Wrapper>
							<WidthBox width="90%">
								<SystemSelect
									value={validationType ?? optionsData[0]}
									options={optionsData}
									onChange={(value: IOption) => {
										setValidationType(value);
										setMessage(value.custom?.value ?? '');
									}}
									label="Tipo"
								/>
							</WidthBox>
							<WidthBox width="auto" margin="22px 0 0 0">
								<Button
									icon2={editRef ? 'edit-3' : 'plus'}
									onClick={addLine}
									fill="auto"
									textColor={applicationTheme.brand[600]}
									background={applicationTheme.brand[100]}
									disabled={!isValid}
									height="41px"
								/>
							</WidthBox>
						</Wrapper>

						{validationType && validationType.value ? (
							<Wrapper margin="12px 0 0 0">
								<SystemInput
									label="Mensagem"
									value={message}
									onChange={setMessage}
								/>
							</Wrapper>
						) : (
							<></>
						)}

						{renderSubProps()}

						<SizedBox height="12px" />
						<SystemDataGrid
							rowsData={validationData}
							columns={[
								{
									flex: 0.1,
									minWidth: 90,
									type: 'actions',
									filterable: false,
									hideable: false,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									field: 'actions',
									headerName: '',
									renderCell: ({row}: CellType) => (
										<SystemRowOptions
											childrens={
												<>
													<MenuItem
														onClick={event => handleEdit(row)}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="edit-3" size={16} />
														<Text text="Editar" />
													</MenuItem>
													<Wrapper
														alignItems="center"
														justifyContent="center">
														<WidthBox width="90%">
															<Line
																height="0.5px"
																background={
																	applicationTheme.gray[300]
																}
															/>
														</WidthBox>
													</Wrapper>
													<MenuItem
														onClick={event =>
															handleDeleteClick(event, row)
														}
														sx={{'& svg': {mr: 2}}}>
														<FeatherIcon icon="trash-2" size={16} />
														<Text
															text="Excluir"
															color={applicationTheme.error[900]}
														/>
													</MenuItem>
												</>
											}
										/>
									),
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Tipo',
									field: 'label',
									minWidth: 180,
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Valor',
									field: 'value',
									minWidth: 180,
								},
								{
									flex: 0.4,
									headerAlign: 'left',
									align: 'left',
									sortable: false,
									headerName: 'Mensagem',
									field: 'message',
									minWidth: 180,
								},
							]}
						/>
						<Wrapper margin="20px 0 0 0" justifyContent="space-between">
							<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
								<Button
									text="Cancelar"
									leftIcon2="x"
									onClick={close}
									fill="auto"
									background={applicationTheme.gray[300]}
									textColor={applicationTheme.gray[700]}
									type="ghost"
								/>
							</div>
						</Wrapper>
					</>
				</>
			</Modal>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => showIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar validação?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => showIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={() => rowToDelete && removeLine(rowToDelete)}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<Modal
				isOpen={isOpenErrorModal}
				onClose={() => setIsOpenErrorModal(false)}
				width="auto">
				<>
					<ModalTitle
						title="Registro duplicado"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-triangle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Localizado um registro com o mesmo nome, verifique.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="center">
						<Button
							text="OK"
							onClick={() => setIsOpenErrorModal(false)} // Fechar modal de erro
							fill="full"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

export default AddValidation;

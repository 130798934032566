import FeatherIcon from 'feather-icons-react';
import React, {useContext, useEffect, useState} from 'react';
import {IColumn} from 'src/@types/app';
import {DatabaseColumnEnum} from 'src/@types/enums';
import {ApplicationContext} from 'src/context';
import {DatabaseMaskList} from 'src/helpers/select-data/databaseMaskList';
import {DatabaseTypeList} from 'src/helpers/select-data/databaseTypeList';
import {Modal} from 'src/shared/components/Complex';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {Button, CheckBox, ModalTitle, WidthBox, Wrapper} from 'src/shared/components/UI';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';
import {applicationTheme} from 'src/shared/theme';
import {v4 as uuidV4} from 'uuid';

type Props = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selectedItem?: IColumn;
};

export const AddField = ({isOpen, setIsOpen, selectedItem}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [column, setColumn] = useState<IColumn>();
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false); // Novo estado para modal de erro

	useEffect(() => {
		if (selectedItem) setColumn(selectedItem);
		else setColumn(undefined);
	}, [isOpen]);

	const closeAddField = () => {
		setColumn(undefined);
		setIsOpen(false);
	};

	const handleAddField = () => {
		// Verificar se já existe um campo com o mesmo nome na tabela selecionada
		const existingField = application.database?.tables
			?.find(table => table.selected)
			?.columns?.find(
				item =>
					item.name?.toLowerCase() === column?.name?.toLowerCase() &&
					item.ref !== selectedItem?.ref,
			);
		if (existingField) {
			// Exibir um aviso ou alertar o usuário que o campo com o nome já existe
			setIsOpenErrorModal(true);
			return; // Impedir a adição ou edição
		}
		// Se não existir, prosseguir com a lógica de adicionar ou editar o campo
		setApplication({
			...application,
			database: {
				...application.database,
				tables: application.database?.tables?.map(table => {
					if (table.selected) {
						let columns = [...(table.columns ?? [])];
						if (selectedItem) {
							columns = columns.map(item => {
								if (item.ref === selectedItem.ref) {
									item.type = column?.type;
									item.name = column?.name;
									item.suggestName = column?.suggestName;
									item.isPrimaryKey = column?.isPrimaryKey;
									item.mask = column?.mask;
									item.nullable = column?.nullable;
									item.precision = column?.precision;
									item.scale = column?.scale;
								}
								return item;
							});
						} else {
							columns.push({
								...column,
								ref: uuidV4().toString(),
							});
						}
						table.columns = columns;
					}

					return table;
				}),
			},
		});
		setIsOpen(false);
		setColumn(undefined);
	};

	const isFormValid = () => {
		switch (column?.type) {
			case DatabaseColumnEnum.String:
				// Se for do tipo Texto, nome, tamanho e rótulo são obrigatórios
				return column?.name && column?.maxLength && column?.suggestName;
			case DatabaseColumnEnum.Decimal:
				// Se for Decimal, nome, precisão, escala e rótulo são obrigatórios
				return column?.name && column?.precision && column?.scale && column?.suggestName;

			case DatabaseColumnEnum.Byte:
			case DatabaseColumnEnum.Int16:
			case DatabaseColumnEnum.Int32:
			case DatabaseColumnEnum.Int64:
			case DatabaseColumnEnum.Boolean:
			case DatabaseColumnEnum.Date:
			case DatabaseColumnEnum.DateTime:
			case DatabaseColumnEnum.DateOnly:
			case DatabaseColumnEnum.TimeOnly:
				// Para Números, Boolean, Data, Hora, nome e rótulo são obrigatórios
				return column?.name && column?.suggestName;

			default:
				return false;
		}
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={() => closeAddField()}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title={selectedItem ? 'Editar campo' : 'Adicionar campo'} />
					<Wrapper margin="0 0 10px 0">
						<SystemSelect
							width="50%"
							value={DatabaseTypeList.find(x => x.value === column?.type)}
							options={DatabaseTypeList}
							onChange={value =>
								setColumn({
									...(column ?? {}),
									type: value?.value,
								})
							}
							label="Tipo"
						/>
						<SystemInput
							width="50%"
							value={column?.name}
							onChange={val => setColumn({...column, name: val})}
							mask="Sem espaço"
							label="Nome"
						/>
					</Wrapper>

					{/* Verifica se o campo Tipo é String */}
					{column?.type === DatabaseColumnEnum.String && (
						<Wrapper margin="0 0 10px 0">
							<SystemSelect
								width="50%"
								value={DatabaseMaskList?.find(x => x.value === column?.mask)}
								options={DatabaseMaskList}
								onChange={value =>
									setColumn({...(column ?? {}), mask: value?.value})
								}
								label="Máscara"
								disabled={column?.type != DatabaseColumnEnum.String}
							/>
							<SystemInput
								width="50%"
								value={column?.maxLength}
								onChange={val => setColumn({...(column ?? {}), maxLength: val})}
								label="Tamanho"
								mask="Numero"
							/>
						</Wrapper>
					)}

					{/* Verifica se o campo Tipo é Decimal */}
					{column?.type === DatabaseColumnEnum.Decimal && (
						<Wrapper margin="0 0 10px 0">
							<SystemInput
								width="50%"
								value={column?.precision}
								onChange={val => {
									const precisionValue = Number(val);
									if (precisionValue > 0 || val === '') {
										setColumn({...(column ?? {}), precision: val});
									}
								}}
								label="Precisão"
								mask="Numero"
							/>
							<SystemInput
								width="50%"
								value={column?.scale}
								onChange={val => {
									const scaleValue = Number(val);
									if (scaleValue > 0 || val === '') {
										setColumn({...(column ?? {}), scale: val});
									}
								}}
								label="Escala"
								mask="Numero"
							/>
						</Wrapper>
					)}

					<Wrapper margin="0 0 10px 0">
						<WidthBox width="50%">
							<CheckBox
								checked={column?.nullable ?? false}
								handleToggle={() =>
									setColumn({...(column ?? {}), nullable: !column?.nullable})
								}
								label="Permite vazio?"
								textColor="#000"
								fontWeight="400"
							/>
						</WidthBox>
						<WidthBox width="50%">
							<CheckBox
								checked={column?.isPrimaryKey ?? false}
								handleToggle={() =>
									setColumn({
										...(column ?? {}),
										isPrimaryKey: !column?.isPrimaryKey,
									})
								}
								label="Chave primária?"
								textColor="#000"
								fontWeight="400"
							/>
						</WidthBox>
					</Wrapper>

					{/* Verifica tipos específicos para o Incremento automático */}
					{column?.type === DatabaseColumnEnum.Byte ||
					column?.type === DatabaseColumnEnum.Int16 ||
					column?.type === DatabaseColumnEnum.Int32 ||
					column?.type === DatabaseColumnEnum.Int64 ? (
						<Wrapper margin="0 0 10px 0">
							<CheckBox
								checked={column?.autoIncremente ?? false}
								handleToggle={() =>
									setColumn({
										...(column ?? {}),
										autoIncremente: !column?.autoIncremente,
									})
								}
								label="Incremento automático?"
								textColor="#000"
								fontWeight="400"
							/>
						</Wrapper>
					) : (
						<></>
					)}

					<SystemInput
						value={column?.suggestName}
						onChange={val => setColumn({...column, suggestName: val})}
						label="Rótulo"
					/>

					<Wrapper margin="20px 0 0 0" justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px'}}>
							<Button
								text={selectedItem ? 'Editar' : 'Salvar'}
								leftIcon2={selectedItem ? 'edit' : 'save'}
								onClick={handleAddField}
								fill="auto"
								background={applicationTheme.brand[600]}
								disabled={!isFormValid()} // Validação baseada na função
							/>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={() => closeAddField()}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>
			<Modal
				isOpen={isOpenErrorModal}
				onClose={() => setIsOpenErrorModal(false)}
				width="auto">
				<>
					<ModalTitle
						title="Registro duplicado"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-triangle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Localizado um registro com o mesmo nome, verifique.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="center">
						<Button
							text="OK"
							onClick={() => setIsOpenErrorModal(false)} // Fechar modal de erro
							fill="full"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

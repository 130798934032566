import {IOption} from 'src/@types/app';
import {ValidationTypeEnum} from 'src/@types/enums';

export const ValidationList: IOption[] = [
	{
		value: ValidationTypeEnum.Required,
		label: 'Conteúdo obrigatório',
		custom: {
			value: 'Esse campo é obrigatório',
		},
	},
	{
		value: ValidationTypeEnum.MinLength,
		label: 'Quantidade mínima de caracteres',
		custom: {
			value: 'Mínimo de {0} caracteres',
		},
	},
	{
		value: ValidationTypeEnum.MaxLength,
		label: 'Quantidade máxima de caracteres',
		custom: {
			value: 'Máximo de {0} caracteres',
		},
	},
	{
		value: ValidationTypeEnum.MinValue,
		label: 'Menor valor válido',
		custom: {
			value: 'O valor mínimo é {0}',
		},
	},
	{
		value: ValidationTypeEnum.MaxValue,
		label: 'Maior valor válido',
		custom: {
			value: 'O valor máximo é {0}',
		},
	},
	{
		value: ValidationTypeEnum.GreatThan,
		label: 'Valor maior que',
		custom: {
			value: 'Maior que {0}',
		},
	},
	{
		value: ValidationTypeEnum.LessThan,
		label: 'Valor menor que',
		custom: {
			value: 'Menor que {0}',
		},
	},
	{
		value: ValidationTypeEnum.Email,
		label: 'E-mail válido',
		custom: {
			value: 'E-mail inválido',
		},
	},
	{
		value: ValidationTypeEnum.CnpjCpf,
		label: 'CPF/CNPJ válido',
		custom: {
			value: 'CPF/CNPJ inválido',
		},
	},
	{
		value: ValidationTypeEnum.Between,
		label: 'Valor entre',
		custom: {
			value: 'A data deve estar entre {0} e {1}',
		},
	},
	{
		value: ValidationTypeEnum.Url,
		label: 'URL válida',
		custom: {
			value: 'URL inválida',
		},
	},
];

import React, {useContext, useState} from 'react';
import {IInputAction, IOption} from 'src/@types/app';
import {IInputActionEnum} from 'src/@types/enums';
import {Modal} from 'src/shared/components/Complex';
import {InputWorkFlowModal} from 'src/shared/components/Global/InputWorkFlow';
import {
	Button,
	ModalTitle,
	SizedBox,
	WidthBox,
	Wrapper,
	Text,
	Line,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {v4 as uuidV4} from 'uuid';
import {applicationTheme} from 'src/shared/theme';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	onClose: () => void;
};

interface CellType {
	row: IInputAction;
}

const inputOptions: IOption[] = [
	{label: IInputActionEnum.OnChange, value: IInputActionEnum.OnChange},
	{label: IInputActionEnum.OnPressEnter, value: IInputActionEnum.OnPressEnter},
	{label: IInputActionEnum.OnFocus, value: IInputActionEnum.OnFocus},
	{label: IInputActionEnum.OnBlur, value: IInputActionEnum.OnBlur},
];

export const AddInputActionsModal = ({isOpen, onClose}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [actionType, setActionType] = useState<IInputActionEnum>();
	const [isOpenWorkflow, setIsOpenWorflow] = useState(false);
	const [selectedAction, setSelectedAction] = useState('');
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [deleteRef, setDeleteRef] = useState<string | null>(null);
	const [isOpenErrorModal, setIsOpenErrorModal] = useState(false); // Novo estado para modal de erro

	const getSelectedWidget = () => {
		return application.resources?.find(x => x.selected)?.widgets?.find(x => x.selected);
	};

	const handleDeleteClick = (event: React.MouseEvent<HTMLElement>, ref: string) => {
		event.stopPropagation();
		setDeleteRef(ref);
		setIsOpenDelete(true);
	};

	const add = () => {
		if (!actionType) return;

		let isDuplicate = false;
		setApplication(prevApplication => ({
			...prevApplication,
			resources: (prevApplication.resources ?? []).map(resource => ({
				...resource,
				widgets: (resource.widgets ?? []).map(widget => {
					// Verifica se o widget é o selecionado
					if (widget.ref === getSelectedWidget()?.ref) {
						// Cria uma nova cópia de inputActions para evitar mutação
						const inputActions = [...(widget.inputActions ?? [])];
						// Verifica duplicação
						if (inputActions.some(action => action.inputActionType === actionType)) {
							isDuplicate = true;
						} else {
							inputActions.push({
								ref: uuidV4().toString(),
								inputActionType: actionType,
							});
						}
						// Retorna o widget atualizado com inputActions
						return {...widget, inputActions};
					}
					// Retorna o widget sem alterações se não for o selecionado
					return widget;
				}),
			})),
		}));
		// Abre o modal de erro se houver duplicação
		if (isDuplicate) {
			setIsOpenErrorModal(true);
		}
		setActionType(undefined);
	};

	const remove = () => {
		if (!deleteRef) return;
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				resource.widgets = [...(resource.widgets ?? [])].map(widget => {
					if (widget.ref === getSelectedWidget()?.ref) {
						widget.inputActions = [...(widget.inputActions ?? [])].filter(
							x => x.ref !== deleteRef,
						);
					}
					return widget;
				});
				return resource;
			}),
		});
		setIsOpenDelete(false);
		setDeleteRef(null);
	};

	const close = () => {
		setActionType(undefined);
		onClose();
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} width="90%" maxWidth="1000px">
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={close}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Criar eventos" />

					<Wrapper>
						<WidthBox width="95%">
							<SystemSelect
								value={inputOptions.find(x => x.value === actionType)}
								options={inputOptions}
								onChange={value => setActionType(value.value)}
								label="Tipo"
							/>
						</WidthBox>
						<WidthBox width="auto" margin="22px 0 0 0">
							<Button
								icon2={'plus'}
								onClick={add}
								fill="auto"
								textColor={applicationTheme.brand[600]}
								background={applicationTheme.brand[100]}
								height="41px"
								disabled={!actionType}
							/>
						</WidthBox>
					</Wrapper>

					<SizedBox height="12px" />
					<SystemDataGrid
						rowsData={getSelectedWidget()?.inputActions ?? []}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => {
														setSelectedAction(row.ref ?? '');
														setIsOpenWorflow(true);
													}}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={event =>
														handleDeleteClick(event, row.ref ?? '')
													}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.5,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'inputActionType',
								headerName: `Tipo`,
								renderCell: ({row}: CellType) => row.inputActionType,
							},
						]}
					/>

					<Wrapper margin="25px 0 0 0" justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={close}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>

			<Modal
				isOpen={isOpenErrorModal}
				onClose={() => setIsOpenErrorModal(false)}
				width="auto">
				<>
					<ModalTitle
						title="Registro duplicado"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-triangle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Localizado um registro com o mesmo nome, verifique.
					</div>

					<Wrapper margin="20px 0 0 0" justifyContent="center">
						<Button
							text="OK"
							onClick={() => setIsOpenErrorModal(false)} // Fechar modal de erro
							fill="full"
							background={applicationTheme.brand[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar evento?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir este item? <br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={remove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>

			<InputWorkFlowModal
				isOpen={isOpenWorkflow}
				onClose={() => setIsOpenWorflow(false)}
				actionRef={selectedAction}
			/>
		</>
	);
};

/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
	IApplication,
	IComponent,
	IResource,
	IResourceApi,
	ISubComponent,
	ISubGenerate,
} from 'src/@types/app';
import {
	BODY,
	DATA_API,
	FORM_DATA_ERROR_TYPE,
	FORM_DATA_TYPE,
	FORM_DATA_VALIDATION,
	GET_REF,
	IMPORT_AREA,
	INSTANCES_AREA,
	USE_CONTEXT,
	USE_EFFECT,
	USE_EFFECT_EXP,
	USE_EFFECT_WORKFLOW,
	USE_STATE,
} from './engine-enum';
import {repositoryGenerator} from './repository-generator';
import {routeGenerator} from './route-generator';
import {createTemplate, createTemplateSubcomponent} from './template-engine';
import {addWidget} from './utils/addWidget';
import {getImports} from './utils/getSetImports';
import prettier from 'prettier/standalone';
import {getLayoutTemplate} from './templateGenerator';
import {PrettierConfig} from './common/prettier-config';
import {clearEmptyContainer} from './utils/clearEmptyContainer';
import {getDataApi} from './utils/addDataApi';
import {getEffect} from './utils/addEffectStartPage';
import {removeNotUsedRefs} from './utils/removeRef';
import {upperFirstWord} from 'src/helpers/methods/text-methods';
import {removeNoUse} from './utils/removeNoUse';
import {generateReadme} from './generateReadme';
import {addValidation} from './utils/addValidation';
import {getWorkflowEffects} from './utils/getWorkflowEffects';
import {addTypes} from './utils/addTypes';
import {getIndexFile} from './index-file';
import {removeInvalidAttributions} from './utils/removeInvalidAttributions';

let widgetRefs: string[] = [];
let documentsState: string[] = [];
let widgetImports: string[] = [];
let iconImports: string[] = [];
let apiImports: string[] = [];
let fileInstances: string[] = [];
let instanceContext: string[] = [];
let importContext: string[] = [];
let importSubcomponents: string[] = [];
let anotherImports: string[] = [];
let documentType: string[] = [];
let documentErrorType: string[] = [];

export const engineCreate = (
	app: IApplication,
	resources: IResource[],
	envRef: string,
): IResourceApi[] => {
	const resourceApi: IResourceApi[] = [];
	const allSubcomponents = getSubComponents(resources);

	resources?.forEach(resource => {
		let page = createCodeResource(resource, app, allSubcomponents);
		page = clearRefs(page);

		//if (resource.name === 'Cliente') console.log(page);

		page = prettier.format(page, PrettierConfig);
		page = clearEmptyContainer(page);
		page = page.replace('useEffect(() => {}, [formData]);', '');
		page = prettier.format(page, PrettierConfig);

		// if (resource.name === 'Cliente') console.log(page);

		resourceApi.push({
			name: `${resource.name}.tsx`,
			path: `src/modules${resource.path ? `/${resource.path}` : ''}`,
			code: page,
		});
	});

	resources?.forEach(resource => {
		resource?.subComponents?.forEach(subComponent => {
			const subName = allSubcomponents.find(x => x.ref == subComponent.ref)?.name;
			let page = createCodeSubcomponents(subComponent, app, subName ?? '', allSubcomponents);
			page = clearRefs(page);

			page = prettier.format(page, PrettierConfig);
			page = clearEmptyContainer(page);
			page = prettier.format(page, PrettierConfig);

			resourceApi.push({
				name: `${subName}.tsx`,
				path: `src/modules${
					resource.path ? `/${resource.path}` : ''
				}/components/${subName}`,
				code: page,
			});
		});
	});

	routeGenerator(resources!).forEach(routerData => resourceApi.push(routerData));
	getLayoutTemplate(app).forEach(templateData => resourceApi.push(templateData));
	if (app.apis) repositoryGenerator(app, envRef).forEach(repoData => resourceApi.push(repoData));

	resourceApi.push(generateReadme());
	resourceApi.push(addTypes(app));
	resourceApi.push(getIndexFile(app));

	return resourceApi;
};

const getWidgetsData = (resource: IResource): IComponent[] => {
	const widgets = resource?.widgets ?? [];

	const data = widgets.map(widget => {
		if (!widget.parentRef) widget.parentLevel = 0;
		else widget.parentLevel = calcParentLevel(widgets, widget.parentRef ?? '');
		return widget;
	});

	return data.sort((a, b) => a.parentLevel! - b.parentLevel!);
};

const calcParentLevel = (widgets: IComponent[], parentRef: string, level = 1): number => {
	const parentWidget = widgets.find(x => x.ref === parentRef);
	if (!parentWidget?.parentRef) return level;
	level = level + 1;
	return calcParentLevel(widgets, parentWidget.parentRef, level);
};

const createCodeResource = (
	resource: IResource,
	app: IApplication,
	allSubcomponents: ISubGenerate[],
): string => {
	let codeString = createTemplate(resource.name, resource.useDefaultLayout);
	let bodyString = '';

	codeString = codeString.replace(USE_EFFECT_WORKFLOW, getWorkflowEffects(resource));
	const widgets = getWidgetsData(resource);

	widgets?.forEach(widget => {
		bodyString = addWidget(
			widget,
			bodyString,
			widgets!,
			widgetRefs,
			widgetImports,
			documentsState,
			iconImports,
			apiImports,
			app,
			fileInstances,
			instanceContext,
			importContext,
			allSubcomponents,
			importSubcomponents,
			documentType,
			documentErrorType,
			resource,
			anotherImports,
		);
	});

	resource.widgets?.forEach(widget => {
		bodyString = removeNotUsedRefs(widget, bodyString);
	});

	codeString = codeString.replace(BODY, bodyString);
	codeString = codeString.replace(
		USE_EFFECT,
		getEffect(
			resource,
			widgetImports,
			apiImports,
			app,
			fileInstances,
			instanceContext,
			importContext,
		),
	);
	codeString = codeString.replace(USE_STATE, documentsState.join(''));
	codeString = codeString.replace(
		IMPORT_AREA,
		getImports(
			widgetImports,
			iconImports,
			apiImports,
			importContext,
			importSubcomponents,
			anotherImports,
		),
	);
	codeString = codeString.replace(INSTANCES_AREA, fileInstances.join(''));
	codeString = codeString.replace(USE_CONTEXT, instanceContext.join(''));
	codeString = codeString.replace(DATA_API, getDataApi(resource));
	codeString = codeString.replace(FORM_DATA_TYPE, documentType.join(''));
	codeString = codeString.replace(FORM_DATA_ERROR_TYPE, documentErrorType.join(''));
	codeString = codeString.replace(FORM_DATA_VALIDATION, addValidation(resource));
	codeString = addExpressions(resource, codeString);
	codeString = removeNoUse(codeString);
	codeString = removeInvalidAttributions(codeString);

	return codeString;
};

const createCodeSubcomponents = (
	subComponent: ISubComponent,
	app: IApplication,
	subName: string,
	allSubcomponents: ISubGenerate[],
): string => {
	let codeString = createTemplateSubcomponent(subName);
	let bodyString = '';

	subComponent.widgets?.forEach(widget => {
		bodyString = addWidget(
			widget,
			bodyString,
			subComponent.widgets!,
			widgetRefs,
			widgetImports,
			documentsState,
			iconImports,
			apiImports,
			app,
			fileInstances,
			instanceContext,
			importContext,
			allSubcomponents,
			importSubcomponents,
			documentType,
			documentErrorType,
			{},
			anotherImports,
			true,
		);
	});

	subComponent.widgets?.forEach(widget => {
		bodyString = removeNotUsedRefs(widget, bodyString);
	});

	codeString = codeString.replace(BODY, bodyString);
	codeString = codeString.replace(USE_STATE, documentsState.join(''));
	codeString = codeString.replace(
		IMPORT_AREA,
		getImports(
			widgetImports,
			iconImports,
			apiImports,
			importContext,
			importSubcomponents,
			anotherImports,
		),
	);
	codeString = codeString.replace(INSTANCES_AREA, fileInstances.join(''));
	codeString = codeString.replace(USE_CONTEXT, instanceContext.join(''));

	return codeString;
};

const clearRefs = (bodyString: string): string => {
	bodyString = bodyString.replace(USE_STATE, '').replace(BODY, '');
	widgetRefs.forEach(curRef => {
		bodyString = bodyString.replace(GET_REF(curRef), '');
	});

	widgetRefs = [];
	documentsState = [];
	widgetImports = [];
	iconImports = [];
	apiImports = [];
	fileInstances = [];
	instanceContext = [];
	importContext = [];
	importSubcomponents = [];
	documentType = [];
	documentErrorType = [];
	anotherImports = [];

	return bodyString;
};

const getSubComponents = (resources: IResource[]): ISubGenerate[] => {
	const data: ISubGenerate[] = [];
	resources?.forEach(resource => {
		resource?.subComponents?.forEach(sub => {
			data.push({
				ref: sub.ref ?? '',
				name: upperFirstWord(sub.name ?? ''),
			});
		});
	});

	return data;
};

const addExpressions = (resource: IResource, page: string) => {
	let code = '';
	resource?.expressions?.forEach(expression => {
		code += expression.code;
	});

	page = page.replace(USE_EFFECT_EXP, code);

	return page;
};

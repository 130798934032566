import React, {useContext, useEffect, useState} from 'react';
import {ITable} from 'src/@types/app';
import {ApplicationContext} from 'src/context';
import {Modal} from 'src/shared/components/Complex';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {Button, CheckBox, ModalTitle, Wrapper} from 'src/shared/components/UI';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	isOpen: boolean;
	setIsOpen: (value: boolean) => void;
	selectedItem?: ITable;
};

export const AddTable = ({isOpen, setIsOpen, selectedItem}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);
	const [data, setData] = useState<ITable>();

	useEffect(() => {
		if (selectedItem) setData(selectedItem);
		else setData(undefined);
	}, [isOpen]);

	const handleClose = () => {
		setIsOpen(false);
		setData(undefined);
	};

	const handleSave = () => {
		const databaseRef = application.database?.databases?.find(
			database => database.selected,
		)?.ref;

		if (databaseRef) {
			if (data?.ref) {
				setApplication({
					...application,
					database: {
						...application.database,
						tables: [...(application.database?.tables ?? [])].map(table => {
							if (table.ref === data.ref) {
								table.name = data.name;
								table.schema = data.schema;
								table.isUser = data.isUser;
								table.createScreen = data.createScreen;
								table.description = data.description;
							}
							return table;
						}),
					},
				});
			} else {
				const tables = [...(application.database?.tables ?? [])];
				tables.push({
					ref: crypto.randomUUID(),
					name: data?.name,
					schema: data?.schema,
					isUser: data?.isUser,
					createScreen: data?.createScreen,
					description: data?.description,
					databaseRef: databaseRef ?? '',
				});

				setApplication({
					...application,
					database: {
						...application.database,
						tables,
					},
				});
			}
		}

		setIsOpen(false);
		setData(undefined);
	};

	const isSaveEnabled = () => {
		return data?.schema && data?.name && data?.description;
	};

	return (
		<Modal isOpen={isOpen} onClose={handleClose}>
			<>
				<div style={{display: 'flex', justifyContent: 'flex-end'}}>
					<Button
						leftIcon2="x"
						onClick={handleClose}
						fill="auto"
						textColor={applicationTheme.gray[700]}
						type="mininum"
					/>
				</div>
				<ModalTitle title="Adicionar tabela" />

				<Wrapper margin="0 0 10px 0">
					<SystemInput
						width="40%"
						value={data?.schema}
						onChange={val => setData({...(data ?? {}), schema: val})}
						label="Schema"
						mask="Sem espaço"
					/>
					<SystemInput
						width="60%"
						value={data?.name}
						onChange={val => setData({...(data ?? {}), name: val})}
						label="Nome da tabela"
						mask="Sem espaço"
					/>
				</Wrapper>
				<SystemInput
					width="100%"
					value={data?.description}
					onChange={val => setData({...(data ?? {}), description: val})}
					label="Descrição"
					mask="Sem espaço"
				/>
				<Wrapper margin="10px 0">
					<CheckBox
						checked={data?.createScreen ?? true}
						handleToggle={() =>
							setData({
								...(data ?? {}),
								createScreen: !data?.createScreen,
							})
						}
						label="Criar tela a partir desta tabela?"
						textColor="#75868f"
						fontWeight="400"
					/>
					<CheckBox
						checked={data?.isUser ?? false}
						handleToggle={() =>
							setData({
								...(data ?? {}),
								isUser: !data?.isUser,
							})
						}
						label="É tabela de usuário?"
						textColor="#75868f"
						fontWeight="400"
					/>
				</Wrapper>

				<Wrapper margin="25px 0 0 0" justifyContent="space-between">
					<div style={{display: 'flex', gap: '10px'}}>
						<Button
							text={selectedItem ? 'Editar' : 'Salvar'}
							leftIcon2={selectedItem ? 'edit' : 'save'}
							onClick={handleSave}
							fill="auto"
							background={applicationTheme.brand[600]}
							disabled={!isSaveEnabled()}
						/>
						<Button
							text="Cancelar"
							leftIcon2="x"
							onClick={handleClose}
							fill="auto"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
					</div>
				</Wrapper>
			</>
		</Modal>
	);
};

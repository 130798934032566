import {ActionTypeEnum} from 'src/@types/enums';

export const ActionList = [
	{value: undefined, label: 'Selecione...'},
	{value: ActionTypeEnum.Validation, label: 'Validar formulário'},
	{value: ActionTypeEnum.NextPage, label: 'Trocar página'},
	{value: ActionTypeEnum.DataGrid, label: 'Configurar GRID'},
	{value: ActionTypeEnum.OpenModal, label: 'Abrir modal'},
	{value: ActionTypeEnum.Api, label: 'Configurar API'},
	{value: ActionTypeEnum.Search, label: 'Configurar mecanismo de pesquisa'},
	{value: ActionTypeEnum.Clear, label: 'Limpar'},
	{value: ActionTypeEnum.SetValue, label: 'Atribuir valor'},
	{value: ActionTypeEnum.WorkFlow, label: 'Fluxo de dados'},
];

export const ActionListFront = [
	{value: undefined, label: 'Selecione...'},
	{value: ActionTypeEnum.Validation, label: 'Validar formulário'},
	{value: ActionTypeEnum.NextPage, label: 'Trocar página'},
	{value: ActionTypeEnum.DataGrid, label: 'Configurar GRID'},
	{value: ActionTypeEnum.OpenModal, label: 'Abrir modal'},
	{value: ActionTypeEnum.Api, label: 'Configurar API'},
	{value: ActionTypeEnum.Search, label: 'Configurar mecanismo de pesquisa'},
	{value: ActionTypeEnum.Clear, label: 'Limpar'},
	{value: ActionTypeEnum.SetValue, label: 'Atribuir valor'},
	//{value: ActionTypeEnum.WorkFlowFront, label: 'WorkFlowFront'},
];

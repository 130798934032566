/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IComponent, IOption} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import Tab, {ITabConfig} from 'src/shared/components/Complex/Tab';
import {Button, Collapse, ModalTitle, SizedBox, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {getWidgetsInput2, mergeComponent, removeComponent} from 'src/helpers/methods/app-methods';
import {TabsTypeList} from 'src/helpers/select-data/tabsTypeList';
import * as S from './styles';
import {v4 as uuidV4} from 'uuid';
import {faAdd, faTrash} from '@fortawesome/free-solid-svg-icons';

import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {SystemInputTag} from 'src/shared/components/System/SystemInputTag';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

const TabSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [name, setName] = useState('');
	const [inputTab, setInputTab] = useState<string[]>([]);
	const [configTabs, setConfigTabs] = useState<ITabConfig[]>([]);
	const [tabType, setTabType] = useState<IOption>();
	const [isValid, setIsValid] = useState(false);
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);
	const [tabSelected, setTabSelected] = useState<IOption>();
	const [fieldSelected, setFieldSelected] = useState<IOption>();
	const [tableMap, setTableMap] = useState<any[]>([]);

	useEffect(() => {
		if (name && name.trim() != '' && tabType && inputTab.length > 0) setIsValid(true);
		else setIsValid(false);
	}, [name, tabType, inputTab]);

	useEffect(() => {
		if (selectedWidget && selectedWidget.name === ComponentComplexEnum.Tab) {
			setName(selectedWidget.properties.name);
			setInputTab(selectedWidget.properties.tabs?.map((x: any) => x.name));
			setConfigTabs(selectedWidget.properties.tabs);
			setTabType(TabsTypeList.find(x => x.value == selectedWidget.properties.type));
			setTableMap(selectedWidget.properties.tableMap ?? []);
		} else {
			clear();
		}

		setFieldOptions([
			...getWidgetsInput2(application),
			{
				value: '***',
				label: '*Todos*',
			},
		]);
	}, [selectedWidget]);

	const addTab = (values: string[]) => {
		setInputTab(values);
		const data = [...(configTabs ?? [])];
		data.push({
			name: values.slice(-1)[0],
			active: data.length === 0,
			children: <></>,
			ref: uuidV4().toString(),
		});
		setConfigTabs(data);
	};

	const save = () => {
		const props = {
			name: name,
			tabs: configTabs,
			type: tabType?.value,
			tableMap: tableMap,
		};

		const newApplication = mergeComponent(
			application,
			selectedWidget,
			ComponentComplexEnum.Tab,
			props,
		);

		if (tableMap && tableMap.length > 0) {
			const allResourcesTab = tableMap.find(x => x.field.value === '***');
			const tabRef = newApplication.resources
				?.find(x => x.selected)
				?.widgets?.find(x => x.properties.name === name)?.ref;

			newApplication.resources = [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.widgets =
						resource.widgets?.map(widget => {
							if (widget.ref === tabRef) return widget;
							if (allResourcesTab) {
								if (!widget.parentRef) {
									widget.parentRef = tabRef;
									widget.tabRef = allResourcesTab.tab.value;
								}
							} else if (tableMap.find(x => x.field.value === widget.ref)) {
								const fieldConfig = tableMap.find(
									x => x.field.value === widget.ref,
								);
								widget.parentRef = tabRef;
								widget.tabRef = fieldConfig.tab.value;
							}
							return widget;
						}) ?? [];
				}

				return resource;
			});
		}

		setApplication(newApplication);
		close();
	};

	const removeTab = (ref: string) => {
		setApplication(removeComponent(application, ref));
		close();
	};

	const clear = () => {
		setName('');
		setInputTab([]);
		setConfigTabs([]);
		setTabType(TabsTypeList.find(x => x.value == undefined));
	};

	const close = () => {
		clear();
		onClose();
	};

	return (
		<div>
			<div style={{display: 'flex', justifyContent: 'flex-end'}}>
				<Button
					leftIcon2="x"
					onClick={close}
					fill="auto"
					textColor={applicationTheme.gray[700]}
					type="mininum"
				/>
			</div>
			<ModalTitle title="Configurar TAB" />

			<Wrapper>
				<SystemInput
					width="50%"
					margin="12px 0"
					value={name}
					onChange={setName}
					label="Nome do componente"
					mask="Sem espaço"
				/>

				<SystemSelect
					width="50%"
					value={tabType}
					options={TabsTypeList}
					onChange={setTabType}
					label="Tipo"
				/>
			</Wrapper>

			<SystemInputTag values={inputTab} onChange={addTab} label="Tabs" margin="12px 0" />

			<SizedBox height="16px" />
			<S.BuilderContent>
				<Tab tabs={configTabs} type={tabType?.value} />
			</S.BuilderContent>
			<SizedBox height="15px" />

			<Collapse text="Campos" border="1px solid #ccc" borderRadius="4px">
				<>
					<Wrapper>
						<WidthBox width="50%">
							<SystemSelect
								value={tabSelected}
								options={[
									{
										value: undefined,
										label: 'Selecione...',
									},
									...configTabs.map(x => ({
										value: x.ref,
										label: x.name,
									})),
								]}
								onChange={setTabSelected}
								label="Tabs"
							/>
						</WidthBox>
						<WidthBox width="50%">
							<SystemSelect
								value={fieldSelected}
								options={fieldOptions}
								onChange={setFieldSelected}
								label="Campo"
							/>
						</WidthBox>
					</Wrapper>
					<Wrapper margin="5px 0 0 0" justifyContent="end">
						<Button
							icon={faAdd}
							onClick={() => {
								const data: any[] = [...tableMap];
								if (
									data.find(
										(x: any) =>
											x.tab.value === tabSelected?.value &&
											x.field.value === fieldSelected?.value,
									)
								)
									return;

								data.push({
									ref: uuidV4().toString(),
									tab: tabSelected,
									field: fieldSelected,
								});

								setTableMap(data);
								setTabSelected(fieldOptions.find(x => x.value === undefined));
								setFieldSelected(fieldOptions.find(x => x.value === undefined));
							}}
							fill="auto"
							tooltip="Adicionar"
							disabled={!tabSelected?.value || !fieldSelected?.value}
						/>
					</Wrapper>

					{/* <Grid
						header={['Nome', 'Tipo']}
						template="2fr 2fr 1fr"
						data={tableMap.map((x: any) => ({
							...x,
							tabLabel: x.tab.label,
							fieldLabel: x.field.label,
						}))}
						headerTextColor={'#000'}
						properties={['tabLabel', 'fieldLabel']}
						bodyTextColor="#fff"
						bodyBackground={'#000'}
						dataActions={[
							{
								icon: faTrash,
								color: '#F44336',
								onClick: (_, i) => {
									setTableMap([...tableMap].filter((x, idx) => idx != i));
								},
							},
						]}
					/> */}
				</>
			</Collapse>

			<Wrapper margin="20px 0 0 0" justifyContent="space-between">
				<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
					<Button
						text="Salvar"
						leftIcon2="save"
						onClick={save}
						fill="auto"
						background={applicationTheme.brand[600]}
						disabled={!isValid}
					/>
					<Button
						text="Cancelar"
						leftIcon2="x"
						onClick={close}
						fill="auto"
						background={applicationTheme.gray[300]}
						textColor={applicationTheme.gray[700]}
						type="ghost"
					/>
				</div>
				<div style={{marginTop: '20px'}}>
					{selectedWidget ? (
						<Button
							text="Excluir"
							leftIcon2="trash-2"
							fill="auto"
							background={applicationTheme.error[600]}
							onClick={() => removeTab(selectedWidget.ref as string)}
						/>
					) : (
						<></>
					)}
				</div>
			</Wrapper>
		</div>
	);
};

export default TabSpecify;

/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useContext, useEffect, useState} from 'react';
import {IOption} from 'src/@types/app';
import {Modal} from 'src/shared/components/Complex';
import {
	Button,
	ModalTitle,
	WidthBox,
	Wrapper,
	Text,
	SizedBox,
	Line,
} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {getApiDataList} from 'src/helpers/methods/app-methods';
import {v4 as uuidv4} from 'uuid';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {applicationTheme} from 'src/shared/theme';
import {SystemDataGrid} from 'src/shared/components/System/SystemDataGrid';
import {SystemRowOptions} from 'src/shared/components/System/SystemRowOptions';
import {MenuItem} from '@mui/material';
import FeatherIcon from 'feather-icons-react';
import {IconWrapper} from 'src/shared/components/UI/ModalTitle/styles';

type Props = {
	isOpen: boolean;
	onClose: () => void;
	openNext: () => void;
};

interface CellType {
	row: any;
}

const AddEffects = ({isOpen, onClose, openNext}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [select, setSelect] = useState<IOption>();
	const [listData, setListData] = useState<IOption[]>([]);
	const [isOpenDelete, setIsOpenDelete] = useState(false);
	const [selectedRow, setSelectedRow] = useState<any>(null);

	useEffect(() => {
		setListData(getApiDataList(application, 'Ao iniciar o componente'));
	}, [application]);

	const close = () => {
		onClose();
		setSelect(undefined);
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.effects = [...(resource.effects ?? [])].map(x => {
						x.selected = false;
						return x;
					});
				}

				return resource;
			}),
		});
	};

	const getSelected = () => application?.resources?.find(x => x.selected);

	const getData = () =>
		getSelected()?.effects?.map(item => {
			const apiDataItem = getSelected()?.apiData?.find(x => x.actionRef === item.apiDataRef);
			return {
				Nome: item.apiDataRef ? apiDataItem?.name : 'Ao iniciar o componente',
				ref: item.ref,
			};
		});

	const handleRemoveClick = (row: any) => {
		setSelectedRow(row);
		setIsOpenDelete(true);
	};

	const remove = () => {
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.effects = [...(resource.effects ?? [])].filter(
						x => selectedRow.ref !== x.ref,
					);
				}
				return resource;
			}),
		});
		setIsOpenDelete(false);
		setSelectedRow(null); // Limpa a row selecionada
	};

	const handleConfig = (item: any) => {
		onClose();
		openNext();
		setApplication({
			...application,
			resources: [...(application.resources ?? [])].map(resource => {
				if (resource.selected) {
					resource.effects = [...(resource.effects ?? [])].map(x => {
						x.selected = x.ref === item.ref;
						return x;
					});
				}

				return resource;
			}),
		});
	};

	return (
		<>
			<Modal isOpen={isOpen} onClose={close} width="50%" maxWidth="900px">
				<>
					<div style={{display: 'flex', justifyContent: 'flex-end'}}>
						<Button
							leftIcon2="x"
							onClick={close}
							fill="auto"
							textColor={applicationTheme.gray[700]}
							type="mininum"
						/>
					</div>
					<ModalTitle title="Efeitos" />

					<Wrapper>
						<WidthBox>
							<SystemSelect
								value={select}
								options={listData}
								onChange={setSelect}
								label="Origem de dados"
							/>
						</WidthBox>
						<WidthBox width="auto" margin="20px 0 0 0">
							<Button
								icon2={'plus'}
								onClick={() => {
									setApplication({
										...application,
										resources: [...(application.resources ?? [])].map(
											resource => {
												if (resource.selected) {
													const data = [...(resource.effects ?? [])];
													data.push({
														ref: uuidv4().toString(),
														apiDataRef: select?.value?.ref,
														actions: [],
													});
													resource.effects = data;
												}
												return resource;
											},
										),
									});
								}}
								fill="auto"
								textColor={applicationTheme.brand[600]}
								background={applicationTheme.brand[100]}
								disabled={getSelected()
									?.effects?.map(x => x.apiDataRef)
									.includes(select?.value)}
								height="41px"
							/>
						</WidthBox>
					</Wrapper>
					<SizedBox height="12px" />
					<SystemDataGrid
						rowsData={getData() ?? []}
						columns={[
							{
								flex: 0.1,
								minWidth: 90,
								type: 'actions',
								filterable: false,
								hideable: false,
								headerAlign: 'left',
								align: 'left',
								sortable: false,
								field: 'actions',
								headerName: '',
								renderCell: ({row}: CellType) => (
									<SystemRowOptions
										childrens={
											<>
												<MenuItem
													onClick={() => handleConfig(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="settings" size={16} />
													<Text text="Configurar" />
												</MenuItem>
												<Wrapper
													alignItems="center"
													justifyContent="center">
													<WidthBox width="90%">
														<Line
															height="0.5px"
															background={applicationTheme.gray[300]}
														/>
													</WidthBox>
												</Wrapper>
												<MenuItem
													onClick={() => handleRemoveClick(row)}
													sx={{'& svg': {mr: 2}}}>
													<FeatherIcon icon="trash-2" size={16} />
													<Text
														text="Excluir"
														color={applicationTheme.error[900]}
													/>
												</MenuItem>
											</>
										}
									/>
								),
							},
							{
								flex: 0.5,
								type: 'string',
								minWidth: 150,
								filterable: true,
								headerAlign: 'left',
								align: 'left',
								sortable: true,
								field: 'name',
								headerName: `Nome`,
								renderCell: ({row}: CellType) => row.Nome,
							},
						]}
					/>
					<Wrapper margin="20px 0 0 0" justifyContent="space-between">
						<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
							<Button
								text="Cancelar"
								leftIcon2="x"
								onClick={close}
								fill="auto"
								background={applicationTheme.gray[300]}
								textColor={applicationTheme.gray[700]}
								type="ghost"
							/>
						</div>
					</Wrapper>
				</>
			</Modal>

			{/* Modal de Exclusão */}
			<Modal isOpen={isOpenDelete} onClose={() => setIsOpenDelete(false)} width="auto">
				<>
					<ModalTitle
						title="Deletar efeito?"
						icon={
							<IconWrapper>
								<FeatherIcon icon="alert-circle" size={20} />
							</IconWrapper>
						}
						centerTitle={true}
					/>
					<div
						style={{
							color: applicationTheme.gray[500],
							textAlign: 'center',
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							fontSize: '14px',
							paddingBottom: '24px',
						}}>
						Tem certeza de que deseja excluir o efeito &apos;{selectedRow?.Nome}&apos;?
						<br />
						Essa ação não pode ser desfeita.
					</div>
					<Wrapper margin="20px 0 0 0" justifyContent="end">
						<Button
							text="Cancelar"
							onClick={() => setIsOpenDelete(false)}
							fill="full"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
						<Button
							text="Deletar"
							onClick={remove}
							fill="full"
							background={applicationTheme.error[600]}
						/>
					</Wrapper>
				</>
			</Modal>
		</>
	);
};

export default AddEffects;

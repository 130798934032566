/* eslint-disable @typescript-eslint/no-explicit-any */
import {faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {IComponent} from 'src/@types/app';
import {ComponentComplexEnum} from 'src/@types/enums';
import {Button, Input, InputTag, SelectIcon, WidthBox, Wrapper} from 'src/shared/components/UI';
import {ApplicationContext} from 'src/context';
import {mergeComponent, removeComponent} from 'src/helpers/methods/app-methods';
import {applicationTheme} from 'src/shared/theme';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

const UploadFileSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [name, setName] = useState('');
	const [qtyFiles, setQtyFiles] = useState('');
	const [textAcceptTypes, setTextAcceptTypes] = useState('');
	const [fileTypes, setFileTypes] = useState<string[]>([]);
	const [border, setBorder] = useState('');
	const [height, setHeight] = useState('');
	const [menuIcon, setMenuIcon] = useState();
	const [validName, setValidName] = useState(false);
	const [isValid, setIsValid] = useState(false);

	useEffect(() => {
		if (selectedWidget) {
			setName(selectedWidget.properties.name);
			setQtyFiles(selectedWidget.properties.qtyFile);
			setTextAcceptTypes(selectedWidget.properties.textAcceptTypes);
			setFileTypes(selectedWidget.properties.fileTypes);
			setBorder(selectedWidget.properties.border);
			setHeight(selectedWidget.properties.height);
			setMenuIcon(selectedWidget.properties.icon);
			setValidName(true);
		} else {
			clear();
		}
	}, [selectedWidget]);

	useEffect(() => {
		const checkExistsName = () => {
			const names = application?.resources
				?.find(x => x.selected)
				?.widgets?.filter(x => x.ref != selectedWidget?.ref)
				.map(x => x.properties.name);

			return !names?.includes(name);
		};

		if (
			validName &&
			checkExistsName() &&
			qtyFiles &&
			qtyFiles.trim() != '' &&
			qtyFiles.length > 0 &&
			fileTypes.length > 0
		)
			setIsValid(true);
		else setIsValid(false);
	}, [name, qtyFiles, fileTypes]);

	const clear = () => {
		setName('');
		setQtyFiles('');
		setTextAcceptTypes('');
		setFileTypes([]);
		setBorder('');
		setHeight('');
		setMenuIcon(undefined);
		setValidName(false);
	};

	const close = () => {
		clear();
		onClose();
	};

	const save = () => {
		const props = {
			name: name,
			qtyFile: qtyFiles,
			fileTypes: fileTypes,
			textAcceptTypes: textAcceptTypes,
			border: border,
			height: height,
			icon: menuIcon,
			importFile: '',
		};

		const newApplication = mergeComponent(
			application,
			selectedWidget,
			ComponentComplexEnum.UploadFile,
			props,
		);
		setApplication(newApplication);
		close();
	};

	const remove = (ref: string) => {
		setApplication(removeComponent(application, ref));
		close();
	};

	const changeName = (name: string) => {
		setName(name);
		const names = application?.resources
			?.find(x => x.selected)
			?.widgets?.filter(x => x.ref != selectedWidget?.ref)
			?.map(x => x.properties.name);

		if (name && name != '' && !names?.includes(name)) setValidName(true);
		else setValidName(false);
	};

	return (
		<div>
			<Wrapper margin="0 0 12px 0">
				<WidthBox width="30%">
					<Input
						value={name}
						onChange={changeName}
						label="Nome do componente"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
						mask="Sem espaço"
					/>
				</WidthBox>
				<WidthBox width="20%">
					<Input
						value={qtyFiles}
						onChange={setQtyFiles}
						label="Qtd de arquivos"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
						mask="Numero"
					/>
				</WidthBox>
				<WidthBox width="50%">
					<Input
						value={textAcceptTypes}
						onChange={setTextAcceptTypes}
						label="Texto de arquivos permitidos"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
			</Wrapper>
			<Wrapper margin="0 0 12px 0">
				<WidthBox width="33%">
					<Input
						value={border}
						onChange={setBorder}
						label="Borda"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
				<WidthBox width="33%">
					<Input
						value={height}
						onChange={setHeight}
						label="Altura"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
						mask="Sem espaço"
					/>
				</WidthBox>
				<WidthBox width="33%" margin="20px 0 0 0">
					<SelectIcon
						value={menuIcon}
						onChange={(value: any) => setMenuIcon(value)}
						background="transparent"
						border="1px solid #333"
						textColor={'#000'}
					/>
				</WidthBox>
			</Wrapper>
			<Wrapper margin="0 0 12px 0">
				<WidthBox>
					<InputTag
						values={fileTypes}
						onChange={setFileTypes}
						label="Tipo de Arquivos"
						inputType="light"
						tagBorderRadius="20px"
						tagBackgroundColor="#008AD2"
						tagColor="#fff"
						border="1px solid #333"
						background="transparent"
						textColor={'#000'}
					/>
				</WidthBox>
			</Wrapper>

			<Wrapper margin="20px 0 0 0" justifyContent="end">
				<Button
					text="Fechar"
					fill="auto"
					themeStyle="primary"
					type="ghost"
					onClick={close}
				/>
				{selectedWidget ? (
					<Button
						text="Excluir"
						leftIcon2="trash-2"
						fill="auto"
						leftIcon={faTrash}
						background={applicationTheme.error[600]}
						onClick={() => remove(selectedWidget.ref as string)}
					/>
				) : (
					<></>
				)}
				<Button
					onClick={save}
					text={selectedWidget ? 'Alterar' : 'Criar'}
					fill="auto"
					themeStyle="primary"
					disabled={!isValid}
				/>
			</Wrapper>
		</div>
	);
};

export default UploadFileSpecify;

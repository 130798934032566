/* eslint-disable @typescript-eslint/no-explicit-any */
import {faClose, faPlus} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {IExpression, IExpressionItem, IOption, IWorkFlowItem} from 'src/@types/app';
import {ExpressionFunctionEnum, WorkFlowTypeEnum} from 'src/@types/enums';
import {Button, WidthBox, Text, Wrapper, IconButton} from 'src/shared/components/UI';
import {ApplicationContext, CustomModalContext, FloatMenuContext} from 'src/context';
import {v4 as uuidv4} from 'uuid';
import * as S from './styles';
import {getWidgetsInput} from 'src/helpers/methods/app-methods';

import {arithmeticList} from 'src/helpers/select-data/arithmeticList';
import {comparetorList} from 'src/helpers/select-data/comparetorList';
import {buildJsCode} from '../builder/buildJsCode';
import {backFormatPropertyName} from 'src/shared/engine-back/common/common-formatters';
import {applicationTheme} from 'src/shared/theme';
import {SystemInput} from 'src/shared/components/System/SystemInput';
import {SystemSelect} from 'src/shared/components/System/SystemSelect';
import {getEnvironmentOptionsList} from 'src/helpers/workflow/helper';

export enum ExpressionOriginEnum {
	JS = 'js',
	DOTNET = 'dotnet',
}

type Props = {
	currentItem: IExpression | undefined;
	save?: (data: IExpressionItem[], code: string) => void;
	onClose?: () => void;
	inputType?: 'default' | 'undeline' | 'light' | 'minimal';
	useWorkflow?: boolean;
	showComparetor?: boolean;
	showDataProp?: boolean;
	workFlowItem?: IWorkFlowItem[];
	originRef?: string;
	customSave?: (value: IExpression) => void;
	origin?: ExpressionOriginEnum;
};

const CODE_ERROR = 'CODE_ERROR';

const ExpressionItem = ({
	save,
	currentItem,
	onClose,
	inputType = 'light',
	useWorkflow = false,
	showComparetor = false,
	showDataProp = false,
	workFlowItem,
	originRef,
	customSave,
	origin = ExpressionOriginEnum.JS,
}: Props) => {
	const [, setFloatMenu] = useContext(FloatMenuContext);
	const [application] = useContext(ApplicationContext);
	const [, setCustomModal] = useContext(CustomModalContext);

	const [expressions, setExpressions] = useState<IExpressionItem[]>([]);
	const [isChange, setIsChange] = useState(false);
	const [fieldOptions, setFieldOptions] = useState<IOption[]>([]);
	const [dataPropOptions, setDataPropOptions] = useState<IOption[]>([]);
	const [showSubProp, setShowSubProp] = useState(false);
	const [subDataPropOptions, setSubDataPropOptions] = useState<IOption[]>([]);

	useEffect(() => {
		const options = getWidgetsInput(application);

		if (useWorkflow && workFlowItem) {
			workFlowItem.forEach(flowItem => {
				if (
					flowItem.type === WorkFlowTypeEnum.Variable &&
					flowItem.values &&
					flowItem.values.variableName
				) {
					options.push({
						value: flowItem.ref,
						label: `var - ${flowItem.values.variableName}`,
					});
				} else if (
					flowItem.type === WorkFlowTypeEnum.CRUD &&
					flowItem.values &&
					flowItem.values.variableName
				) {
					options.push({
						value: flowItem.ref,
						label: `var - ${flowItem.values.variableName}`,
						custom: {
							ident: 'Workflow de dados - referencia da tabela',
							value: flowItem.values.tableRef,
						},
					});
				}
			});
		}
		const envs = getEnvironmentOptionsList(application);
		options.push(...envs);
		setFieldOptions(options);
		setDataPropOptions(getWidgetsInput(application, originRef));
	}, [application, originRef]);

	useEffect(() => {
		setExpressions(currentItem?.items ?? []);
	}, [currentItem]);

	const setCustomSave = (data: IExpressionItem[]) => {
		if (customSave) {
			customSave({
				items: data,
				code: generateCode(origin),
			});
		}
	};

	const generateCode = (type: ExpressionOriginEnum) => {
		switch (type) {
			case ExpressionOriginEnum.JS:
				return buildJsCode(expressions, fieldOptions, currentItem);
		}
	};

	const renderFreeText = (ref?: string) => {
		return (
			<WidthBox width="150px">
				<SystemInput
					value={expressions.find(x => x.ref == ref)?.value}
					onChange={val => {
						const data = [...expressions].map(item => {
							if (item.ref === ref) {
								item.value = val;
							}
							return item;
						});

						setExpressions(data);
						setCustomSave(data);
					}}
					placeholder="Digite um texto"
				/>
			</WidthBox>
		);
	};

	const renderField = (ref?: string) => {
		return (
			<>
				<WidthBox width="180px" margin="0 0 0 5px">
					<SystemSelect
						value={fieldOptions.find(
							y => y.value == expressions.find(x => x.ref == ref)?.value,
						)}
						options={fieldOptions}
						onChange={(val: any) => {
							const data = [...expressions].map(item => {
								if (item.ref === ref) {
									item.value = val.value;
									item.propName = val.label;
								}
								return item;
							});

							if (val.custom?.value) {
								setShowSubProp(true);

								if (
									(workFlowItem as any)?.find((x: any) => x.ref === val.value)
										?.type === WorkFlowTypeEnum.CRUD
								) {
									const subOptions: IOption[] = [
										{value: undefined, label: 'Selecione...'},
									];
									application.database?.tables
										?.find(x => x.ref === val.custom.value)
										?.columns?.map(column => {
											subOptions.push({
												value: column.ref,
												label: backFormatPropertyName(column.name ?? ''),
											});
										});

									setSubDataPropOptions(subOptions);
								}
							} else setShowSubProp(false);

							setExpressions(data);
							setCustomSave(data);
						}}
						placeholder="Campo"
					/>
				</WidthBox>
				{showSubProp ? (
					<WidthBox width="180px" margin="0 0 0 5px">
						<SystemSelect
							value={subDataPropOptions.find(
								y => y.value == expressions.find(x => x.ref == ref)?.subValue,
							)}
							options={subDataPropOptions}
							onChange={val => {
								const data = [...expressions].map(item => {
									if (item.ref === ref) {
										item.subValue = val.value;
										item.subPropName = val.label;
									}
									return item;
								});

								setExpressions(data);
								setCustomSave(data);
							}}
							placeholder="Campo"
						/>
					</WidthBox>
				) : (
					<></>
				)}
			</>
		);
	};

	const renderArithmetic = (ref?: string) => {
		return (
			<>
				<WidthBox width="100px" margin="0 0 0 5px">
					<SystemSelect
						value={fieldOptions.find(
							y => y.value == expressions.find(x => x.ref == ref)?.value,
						)}
						options={arithmeticList}
						onChange={val => {
							const data = [...expressions].map(item => {
								if (item.ref === ref) {
									item.value = val.value;
								}
								return item;
							});

							setExpressions(data);
							setCustomSave(data);
						}}
						placeholder="Operador"
					/>
				</WidthBox>
			</>
		);
	};

	const renderText = (text: string) => {
		return <Text text={text} />;
	};

	const renderComparetor = (ref?: string) => {
		return (
			<>
				<WidthBox width="115px" margin="0 0 0 5px">
					<SystemSelect
						value={comparetorList.find(
							y => y.value == expressions.find(x => x.ref == ref)?.value,
						)}
						options={comparetorList}
						onChange={val => {
							const data = [...expressions].map(item => {
								if (item.ref === ref) {
									item.value = val.value;
								}
								return item;
							});

							setExpressions(data);
							setCustomSave(data);
						}}
						placeholder="Operador"
					/>
				</WidthBox>
			</>
		);
	};

	const renderDataProp = (ref?: string, preText?: string) => {
		return (
			<>
				{preText ? <Text text={`${preText}.`} color="black" /> : <></>}
				<WidthBox width="180px" margin="0 0 0 5px">
					<SystemSelect
						value={dataPropOptions.find(
							y => y.value == expressions.find(x => x.ref == ref)?.value,
						)}
						options={dataPropOptions}
						onChange={val => {
							const data = [...expressions].map(item => {
								if (item.ref === ref) {
									item.value = val.value;
									item.propName = val.label;
								}
								return item;
							});

							setExpressions(data);
							setCustomSave(data);
						}}
						placeholder="Campo"
					/>
				</WidthBox>
			</>
		);
	};

	const render = () => {
		const data: any[] = [];

		expressions?.forEach(item => {
			if (item.type === ExpressionFunctionEnum.FreeText) data.push(renderFreeText(item.ref));
			if (item.type === ExpressionFunctionEnum.Field) data.push(renderField(item.ref));
			if (item.type === ExpressionFunctionEnum.Arithmetic)
				data.push(renderArithmetic(item.ref));
			if (item.type === ExpressionFunctionEnum.Concat) data.push(renderText('[CONCAT]'));
			if (item.type === ExpressionFunctionEnum.UpperCase)
				data.push(renderText('.[MAIUSCULO]'));
			if (item.type === ExpressionFunctionEnum.LowerCase)
				data.push(renderText('.[MINUSCULO]'));
			if (item.type === ExpressionFunctionEnum.Comparetor)
				data.push(renderComparetor(item.ref));
			if (item.type === ExpressionFunctionEnum.DataProp)
				data.push(renderDataProp(item.ref, 'search'));
		});

		return data;
	};

	const setItem = (type: ExpressionFunctionEnum) => {
		const data = [...expressions];
		data.push({
			type: type,
			ref: uuidv4().toString(),
		});
		setExpressions(data);
		setCustomSave(data);
		setIsChange(
			type === ExpressionFunctionEnum.FreeText ||
				type === ExpressionFunctionEnum.Field ||
				type === ExpressionFunctionEnum.LowerCase ||
				type === ExpressionFunctionEnum.UpperCase,
		);
	};

	const btnAdd = () => {
		const onPress = (event: any) =>
			setFloatMenu({
				x: event.pageX,
				y: event.pageY,
				show: true,
				event: setItem,
				changeFields: isChange,
				showComparetor,
				showDataProp,
			});

		return (
			<>
				<WidthBox width="auto">
					{inputType === 'minimal' ? (
						<IconButton
							onClick={onPress}
							icon={faPlus}
							color={applicationTheme.brand[600]}
						/>
					) : (
						<Button
							onClick={onPress}
							icon2="plus"
							textColor={applicationTheme.brand[600]}
							background={applicationTheme.brand[100]}
							height="41px"
						/>
					)}
				</WidthBox>
			</>
		);
	};

	const onSave = () => {
		const code = generateCode(origin) ?? '';

		if (code !== CODE_ERROR) {
			if (save) save(expressions, code);
		} else {
			if (onClose) onClose();
			setCustomModal({
				isShow: true,
				title: 'Atenção',
				description: 'Expressão inválida! Pode conter erros ao gerar o código',
			});
		}

		setFloatMenu({
			show: false,
		});
	};

	return (
		<>
			<Wrapper>
				<S.Content>
					{[...render()].map((item, index) => (
						<S.Exclude key={index}>
							<IconButton
								onClick={() => {
									const data = [...expressions].filter((_, idx) => idx != index);
									setExpressions(data);
									setCustomSave(data);
								}}
								icon={faClose}
								color="red"
							/>
							{item}
						</S.Exclude>
					))}
					{btnAdd()}
				</S.Content>
			</Wrapper>
			{onClose ? (
				<Wrapper margin="20px 0 0 0" justifyContent="space-between">
					<div style={{display: 'flex', gap: '10px', marginTop: '20px'}}>
						<Button
							text="Salvar"
							leftIcon2="save"
							onClick={onSave}
							fill="auto"
							background={applicationTheme.brand[600]}
						/>
						<Button
							text="Cancelar"
							leftIcon2="x"
							onClick={onClose}
							fill="auto"
							background={applicationTheme.gray[300]}
							textColor={applicationTheme.gray[700]}
							type="ghost"
						/>
					</div>
				</Wrapper>
			) : (
				<></>
			)}
		</>
	);
};

export default ExpressionItem;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {faAdd, faRemove, faTrash} from '@fortawesome/free-solid-svg-icons';
import React, {useContext, useEffect, useState} from 'react';
import {PhotoshopPicker} from 'react-color';
import {IComponent} from 'src/@types/app';
import {ApiParamTypeEnum, ComponentComplexEnum} from 'src/@types/enums';
import {ChartOptions, ChartProps, ChartsEnum} from 'src/shared/components/Complex/Charts';
import {Button, Input, Select, WidthBox, Wrapper} from 'src/shared/components/UI';
import LocalDataSelect from 'src/shared/components/UI/LocalDataSelect';
import {ApplicationContext} from 'src/context';
import {mergeComponent, removeComponent} from 'src/helpers/methods/app-methods';
import {ChartList} from 'src/helpers/select-data/chartList';
import AreaChartSpecify from './components/AreaChart';
import PieChartSpecify from './components/PieChart';

type Props = {
	onClose: () => void;
	selectedWidget: IComponent | undefined;
};

const ChartSpecify = ({onClose, selectedWidget}: Props) => {
	const [application, setApplication] = useContext(ApplicationContext);

	const [chart, setChart] = useState<ChartProps>();

	useEffect(() => {
		setChart(selectedWidget?.properties);
	}, [selectedWidget]);

	const save = () => {
		let dataSimulator: any;

		if (chart?.type === ChartsEnum.PieChart) dataSimulator = createSimulateData();
		else dataSimulator = createAreaChartSimulate();

		const newApplication = mergeComponent(
			application,
			selectedWidget,
			ComponentComplexEnum.Chart,
			{
				...chart,
				data: dataSimulator,
			},
		);

		setApplication(newApplication);
		close();
	};

	const removeChart = (ref: string) => {
		setApplication(removeComponent(application, ref));
		close();
	};

	const createAreaChartSimulate = (): any[] => {
		if (chart?.useOriginData) {
			return [
				createSimulateData(1, 10),
				createSimulateData(2, 16),
				createSimulateData(3, 22),
			];
		}

		return [
			...createSimulateData(1, 10),
			...createSimulateData(2, 16),
			...createSimulateData(3, 22),
		];

		return [];
	};

	const createSimulateData = (count = 0, multiFator = 0) => {
		if (chart?.type != ChartsEnum.PieChart) {
			if (chart?.useOriginData) {
				const addData: any = {
					[chart?.axisLabel && chart?.axisLabel != ''
						? chart?.axisLabel
						: 'name']: `Page ${count}`,
				};

				chart?.data?.forEach((item: any, index: number) => {
					addData[item.dataKey] = count * (multiFator * (index + 1));
				});

				return addData;
			}

			const listData: any[] = [];

			chart?.dataOptions?.forEach((item: ChartOptions, index: number) => {
				listData.push({
					[chart?.axisLabel ?? 'name']: count,
					[chart?.datasetProp ?? '']: item.dataKey,
					[chart?.valueProp ?? '']: count * multiFator * (index + 1),
				});
			});

			return listData;
		}
		if (chart?.type === ChartsEnum.PieChart) {
			if (chart?.useOriginData) {
				return chart?.dataOptions?.map((item, index) => {
					return {
						name: item.dataKey,
						value: 100 * (index + 1),
						color: item.fillColor,
					};
				});
			}

			const data: any = {};
			chart?.dataOptions?.forEach((item, index) => {
				data[item.dataKey ?? ''] = 100 * (index + 1);
			});

			return data;
		}
	};

	const close = () => {
		setChart(undefined);
		onClose();
	};

	const getEnableTypes = () => {
		switch (chart?.type) {
			case ChartsEnum.PieChart:
				return [ApiParamTypeEnum.OBJECT];
			default:
				return [ApiParamTypeEnum.ARRAY];
		}
	};

	const render = () => {
		if (chart?.type === ChartsEnum.PieChart)
			return <PieChartSpecify chart={chart} setChart={setChart} />;
		else if (chart?.type) return <AreaChartSpecify chart={chart} setChart={setChart} />;
		return <></>;
	};

	const isDisabled = () => {
		let disabled = false;

		if (!chart?.type) disabled = true;
		if (chart?.type != ChartsEnum.PieChart && (!chart?.data || chart?.data?.length === 0))
			disabled = true;
		if (
			chart?.type === ChartsEnum.PieChart &&
			!chart?.useOriginData &&
			(!chart?.dataOptions || chart?.dataOptions?.length === 0)
		)
			disabled = true;

		return disabled;
	};

	return (
		<div>
			<Wrapper margin="0 0 12px 0">
				<WidthBox width="30%">
					<Select
						value={ChartList?.find(x => x.value === chart?.type)}
						options={ChartList}
						onChange={value => setChart({...chart, type: value.value})}
						label="Tipo"
						inputType="light"
						background="transparent"
						border="1px solid #333"
						textColor={'#000'}
					/>
				</WidthBox>
				<WidthBox width="23%">
					<Input
						value={chart?.height?.toString()}
						onChange={val =>
							setChart({...chart, height: val && val != '' ? Number(val) : undefined})
						}
						label="Altura"
						inputType="light"
						border="1px solid #333"
						background="transparent"
						mask="Numero"
						textColor={'#000'}
					/>
				</WidthBox>
			</Wrapper>

			{chart?.type ? (
				<LocalDataSelect
					localData={{
						data: chart?.dataRef as any,
						prop: chart?.propRef,
					}}
					setLocalData={val =>
						setChart({...chart, dataRef: val?.data, propRef: val?.prop})
					}
					acceptTypes={getEnableTypes()}
				/>
			) : (
				<></>
			)}

			{render()}

			<Wrapper>
				<Wrapper margin="20px 0 0 0" justifyContent="end">
					<Button
						text="Fechar"
						fill="auto"
						themeStyle="primary"
						type="ghost"
						onClick={close}
					/>
					{selectedWidget ? (
						<Button
							text="Excluir"
							fill="auto"
							leftIcon={faTrash}
							themeStyle="primary"
							type="ghost"
							onClick={() => removeChart(selectedWidget.ref as string)}
						/>
					) : (
						<></>
					)}
					<Button
						onClick={save}
						text={selectedWidget ? 'Alterar' : 'Criar'}
						fill="auto"
						themeStyle="primary"
						disabled={isDisabled()}
					/>
				</Wrapper>
			</Wrapper>
		</div>
	);
};

export default ChartSpecify;
